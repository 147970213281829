import React, { Component } from "react";
import "./Style/FirstPage.scss";
import "../../Common/LinkClick/LinkClick.scss";
import backimg from "../../_asset/images/mainback/bg-01@3x.png";
import uiximg from "../../_asset/images/mainback/uix.png";
class FirstPage extends Component {
  constructor(props) {
    super(props);
    this.state = { resizeDevice: window.innerWidth };
  }
  componentDidMount() {
    window.onresize = () => {
      this.setState({
        resizeDevice: window.innerWidth,
      });
    };
  }

  render() {
    return (
      <div
        className="FirstPage"
        style={{ width: "100%", height: "100%", color: "#FFF" }}
      >
        {/* <div className="U">U</div>
				<div className="I">I</div>
				<div className="X">X</div>
				<div className="uix-always-thinks">
					<span className="uix">UIX</span>
					<br />
					<span className="always">always</span>
					<br />
					<span className="thinks">thinks</span>
				</div> */}

        {this.state.resizeDevice < 480 ? (
          <div className="contents">
            <div className="text">
              <img src={uiximg} alt="uix-image" />
            </div>
            <div className="desc">
              <span>UIX</span>
              <span>always</span>
              <span>thinks</span>
            </div>
          </div>
        ) : (
          <div className="BackImg">
            <img src={backimg} alt="Background-image" />
          </div>
        )}
      </div>
    );
  }
}

export default FirstPage;
