import React, { useState, useEffect } from "react";
import "./style/GconPremium.scss";
import styled from "styled-components";
import device from "../../../../_asset/images/g9/img_pc_device.png";
import bgimg from "../../../../_asset/images/g9/img_pc_device_bg01.png";

const DeviceImg = styled.div`
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 36px;
  box-shadow: 100px 100px 150px 0 rgba(0, 0, 0, 0.1);
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 60px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 1s linear;
  > img {
    width: 100%;
  }
`;

const ImgBg = styled.div`
  position: relative;
  z-index: -1;
  margin: 0 0 0 15px;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.02),
    60px 60px 50px 0 rgba(0, 0, 0, 0.05);
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 60px, 0px)"};
  transition: opacity 1.4s linear;
  transition: transform 1.4s linear;
  > img {
    width: 100%;
  }
`;

const GconPremium = ({ pageY }) => {
  const [trigger, setTrigger] = useState(false);
  useEffect(() => {
    if (pageY >= 22000) {
      setTrigger(true);
    }
  });
  return (
    <div className="con_premium_container">
      <div className="container">
        <div className="con_premium">
          <DeviceImg trigger={trigger}>
            <img src={device} alt="device_premium" />
          </DeviceImg>
          <ImgBg trigger={trigger}>
            <img src={bgimg} alt="premium_bg" />
          </ImgBg>
          <div className="brand_film"></div>
        </div>
      </div>
    </div>
  );
};

export default GconPremium;
