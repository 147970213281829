import React, { Component } from "react";
import "./Control.scss";
import styled from "styled-components";
import upicon from "../../_asset/images/mainback/white-wheel-icon-top.svg";
import downicon from "../../_asset/images/mainback/white-wheel-icon-bottom.svg";
import FlipNumbers from 'react-flip-numbers';

const Button = styled.button`
	border-radius: 50px;
    padding: 5px;
    min-width: 120px;
    color: white;
    font-weight: 600;
    -webkit-appearance: none;
    cursor: pointer;
    &:active,
    &:focus {
      outline: none;
    }
    background-color: ${props => (props.danger ? 'red' : 'purple')}
`;

class Control extends Component {
	render() {
        const FlipNumbersColor = this.props.isLogoBlack ? "#000" : "#fff";
		const color = FlipNumbersColor
		return (
			<div className="PageControllerContainer">
				<div className="PageControllerMain">
					<button className="PageControllerUp" onClick={this.props.toPrevPage}>
						<svg className={`arrowIcon ${this.props.isLogoBlack?"black":""}`} xmlns="http://www.w3.org/2000/svg" width="17" height="17">
							<path fillRule="evenodd" d="M8.594 16.216c-4.364 0-7.913-3.552-7.913-7.919C.681 3.931 4.23.379 8.594.379c4.362 0 7.912 3.552 7.912 7.918 0 4.367-3.55 7.919-7.912 7.919zm0-14.397c-3.57 0-6.475 2.905-6.475 6.478s2.905 6.479 6.475 6.479c3.569 0 6.474-2.906 6.474-6.479s-2.905-6.478-6.474-6.478zM8.56 7.155l-3.053 3.088-1.022-1.012L8.548 5.12l4.148 4.105-1.012 1.024L8.56 7.155z"/>
						</svg>

					</button>
					
					<div className={`PageControllerNumber ${this.props.isLogoBlack?"black":""}`}>
					<FlipNumbers
						play
						/* background="#333333" */
						width={9}
						height={14}
						numbers={this.props.number}
					/>
					</div>
					<button className="PageControllerDown" onClick={this.props.toNextPage}>
						<svg className={`arrowIcon ${this.props.isLogoBlack?"black":""}`} xmlns="http://www.w3.org/2000/svg" width="17" height="17">
							<path fillRule="evenodd" d="M8.594 16.769c-4.364 0-7.913-3.552-7.913-7.918S4.23.932 8.594.932c4.362 0 7.912 3.553 7.912 7.919s-3.55 7.918-7.912 7.918zm0-14.397c-3.57 0-6.475 2.906-6.475 6.479 0 3.572 2.905 6.478 6.475 6.478 3.569 0 6.474-2.906 6.474-6.478 0-3.573-2.905-6.479-6.474-6.479zM4.485 7.917l1.022-1.012L8.56 9.992l3.124-3.093 1.012 1.024-4.148 4.105-4.063-4.111z"/>
						</svg>
					</button>
				</div>
			</div>
		);
	}
}

export default Control;
