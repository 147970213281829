import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "./style/mission.scss";

const Title = styled.div`
  font-size: 55px;
  letter-spacing: 25px;
  font-weight: 700;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 1s linear;
`;
const Subtitle = styled.div`
  font-size: 16px;
  margin-top: 50px;
  line-height: 2;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 1s linear;
`;

const Mission = ({ pageY }) => {
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    if (pageY >= 2300) {
      setTrigger(true);
    }
  }, [pageY]);

  return (
    <div className="Missioncontainer">
      <div className="mission_top">
        <Title trigger={trigger}>
          PROJECT
          <br /> MISSION
        </Title>
        <Subtitle trigger={trigger}>
          급변하는 시장 속 치열한 경쟁 한가운데에 있는 29CM의 현재를 진단하고
          앞으로 <br />
          나아갈 방향을 점검하여, 집객과 생산성을 확보할 방안에 대해 함께
          고민하고 필요한 기능을 모색합니다.
        </Subtitle>
      </div>
      <ul className="mission_ul">
        <li className="mission_01">
          <div className="Top_number">1</div>
          <div className="Image_Title">Customer Attraction</div>
          <div className="Bottom_text">집객성</div>
        </li>
        <li className="mission_02">
          <div className="Top_number">2</div>
          <div className="Image_Title">Fucntionality</div>
          <div className="Bottom_text">기능성</div>
        </li>
        <li className="mission_03">
          <div className="Top_number">3</div>
          <div className="Image_Title">Profitability</div>
          <div className="Bottom_text">채산성</div>
        </li>
      </ul>
    </div>
  );
};

export default Mission;
