import React from "react";
import "./style/Picons.scss";
import iconsimg from "../../../../_asset/images/Prorenata/img_prn23.png";
import iconsimg01 from "../../../../_asset/images/Prorenata/img_prn24.png";

const Picons = () => {
  return (
    <div className="Picons_container">
      <div className="Picons_cont_1">
        <div className="Picons_txt_bx">
          <div className="Picons_tit">Iconography</div>
          <div className="Picons_txt">
            프로레나타의 아이콘은 화장품의 효능과 효과 등의 정보를 정확하게
            전달하기 위해 최대한 장식적인 요소를 배제하고<br></br>
            스트로크 베이스로 간결하게 디자인하여 프로레나타의 전문적이고
            미니멀한 브랜드 이미지를 표현합니다.
          </div>
        </div>
      </div>
      <div className="Picons_cont_2">
        <div className="Picons_image_Wrap">
          <img src={iconsimg} alt="icons_image" />
        </div>
      </div>
      <div className="Picons_cont_3">
        <img src={iconsimg01} alt="icons_image" />
      </div>
    </div>
  );
};

export default Picons;
