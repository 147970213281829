import React, { useState, useEffect } from "react";
import "./style/Emusic.scss";
import styled from "styled-components";
import Emusictit from "../../../../_asset/images/emart/tit_brand_music.png";
import Emusicimg01 from "../../../../_asset/images/emart/img_music1.png";
import Emusicimg02 from "../../../../_asset/images/emart/img_music2.png";
import Emusicimg03 from "../../../../_asset/images/emart/img_music3.png";

const Title = styled.div`
  overflow: hidden;
  text-align: center;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 1s linear;
`;

const Screen = styled.div`
  position: absolute;
  display: flex;
  top: 170px;
  left: 50%;
  width: 1274px;
  margin-left: -637px;
  font-size: 0;
  line-height: 0;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1.3s linear;
  transition: transform 1.3s linear;
`;

const Emusic = ({ pageY }) => {
  const [trigger, setTrigger] = useState(false);
  useEffect(() => {
    if (pageY >= 24500) {
      setTrigger(true);
    }
  });
  return (
    <div className="Emusiccontainer">
      <Title trigger={trigger}>
        <img src={Emusictit} alt="Emusic_title_image" />
      </Title>
      <div className="background">
        <Screen trigger={trigger}>
          <div className="screen_list01">
            <img src={Emusicimg01} alt="Emusic_image01" />
          </div>
          <div className="screen_list02">
            <img src={Emusicimg02} alt="Emusic_image02" />
          </div>
          <div className="screen_list03">
            <img src={Emusicimg03} alt="Emusic_image03" />
          </div>
        </Screen>
      </div>
    </div>
  );
};

export default Emusic;
