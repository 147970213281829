import React from "react";
import "./style/cover.scss";
import logoimg from "../../../../_asset/images/emart/img_logo.png";
import Ecover from "../../../../_asset/images/emart/img_screen.png";

const Cover = () => {
  return (
    <div className="Emartcover">
      <div className="con_bx">
        <div className="tit_bx">
          <div className="logo_box">
            <img src={logoimg} alt="Emart_logo" />
            <div className="Ecover_text">
              <div className="Ecover_title">
                Emart <br />
                Mobile App&Web <br />
                Renewal Project
              </div>
              <div className="Ecover_subtitle">
                UI/UX Design Project
                <br />
                Plus X Creative partner
              </div>
            </div>
          </div>
        </div>
        <div className="Ecover_Img">
          <div className="Ecover_Wrap">
            <img src={Ecover} alt="bg_cover" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cover;
