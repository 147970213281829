import React, { Component } from 'react';
import "./Style/EnterProjectPage.scss"
import "./../Reauest.scss"
import { Grid } from '@material-ui/core';
import swal from 'sweetalert2'
import CustomSelect from '../../../Components/CustomSelect/CustomSelect.js';
class SharePage extends Component {
    LIMIT_FILE_SIZE = 1024 * 1000 * 1000;
    state = {
        agreeState: false,
        ComBtn: false,
        WebBtn: false,
        BrandingBtn: false,
        values: {
            projectName: "",
            referenceSiteURL: "",
            openSchedule: "",
            fileAttachment: "",
            detail: "",
            budget: "",
        },
        checkedList: []
    }
    inputFileRef = React.createRef();
    openScheduleOptions = [{
        id: 1,
        value: "Open as soon as possible",
        text: "Open as soon as possible",
    }, {
        id: 2,
        value: "2-3 months later open",
        text: "2-3 months later open"
    }, {
        id: 3,
        value: "4-6 months later open",
        text: "4-6 months later open",
    }, {
        id: 4,
        value: "Direct input",
        text: "Direct input",
    }]
    budgetOptions = [{
        id: 1,
        value: "30 million - less than 50 million",
        text: "30 million - less than 50 million",
    }, {
        id: 2,
        value: "50 million - less than 100 million",
        text: "50 million - less than 100 million"
    }, {
        id: 3,
        value: "100 million to less than 200 million",
        text: "100 million to less than 200 million",
    }, {
        id: 4,
        value: "More than 200 million",
        text: "More than 200 million",
    },]
    setFileAttachment = (file) => {

    }
    handleUpload = (e) => {
        const file = e.target.files[0];
        console.log(file);
        if (file.size < this.LIMIT_FILE_SIZE && file.type === "application/x-zip-compressed") {
            const formData = new FormData();
            formData.append('file', file, file.name);
            this.setState({
                values: {
                    ...this.state.values,
                    fileAttachment: file.name,
                }
            })
            // Please call api upload in here

            swal.fire({
                title: 'Success !',
                text: 'Upload file successfully',
                icon: 'success',
                confirmButtonText: 'Ok'
            })
        } else {
            swal.fire({
                title: 'Error!',
                text: 'Please upload as a zip file of 100MB or less',
                icon: 'error',
                confirmButtonText: 'Ok'
            })
        }
    }
    handleAgreeCheck = () => {
        if (this.state.agreeState) {
            this.setState({ agreeState: false })
        } else {
            this.setState({ agreeState: true })
        }
    }
    handleCheckedItem = (value) => {

        if (
            !this.state.checkedList.find((item) => item === value)
        ) {
            let checkedList = this.state.checkedList;
            checkedList.push(value);
            this.setState({ checkedList });
        } else {
            let checkedList = this.state.checkedList;
            checkedList = checkedList.filter((item) => item !== value);
            this.setState({ checkedList });
        }
    };
    handleChange = (e) => {
        const { name, value } = e.target;
        console.log({
            [name]: value
        });
        this.setState({
            values: {
                ...this.state.values,
                [name]: value
            }
        })
    }
    handleClickFileButton = () => {
        this.inputFileRef.current.click();
    }
    render() {
        console.log("sa", this.state.values);
        return (
            <div>
                <div className='EnterProjectAll'>
                    <hr class="secondhrcss"></hr>
                    <div className='EnterProjectTitle'>
                        EnterProject<br />
                            Information
                        </div>
                    <div className='InformationFillinBox'>
                        <Grid container >
                            <Grid className="leftWrapper" item xs={12} md={6}>
                                <div className='LeftInformationBox'>
                                    <div className='ProjectNameBox'>
                                        <div className='RequiredElements'>
                                            <div className='NameTitle'>
                                                PROJECT NAME
                                        </div>
                                            <div className='Emphasis'>
                                                *
                                        </div>
                                        </div>
                                        <div className='SelectTitle'>
                                            <input type="text" name="projectName" onChange={this.handleChange} placeholder="Project Name" />
                                        </div>
                                    </div>

                                    <div className='ProjectNameBox'>
                                        <div className='RequiredElements'>
                                            <div className='NameTitle'>
                                                OPEN SCHEDULE
                                        </div>
                                            <div className='Emphasis'>
                                                *
                                        </div>
                                        </div>
                                        <div className="selectWrapper">
                                            <CustomSelect handleChange={this.handleChange} name="openSchedule" id="openScheduleSelect" options={this.openScheduleOptions} placeholder="Open schedule" />
                                        </div>
                                    </div>



                                    <div className='ProjectNameBox'>
                                        <div className='RequiredElements'>
                                            <div className='NameTitle'>
                                                FORMULATION BUDGET
                                        </div>
                                            <div className='Emphasis'>
                                                *
                                        </div>
                                        </div>
                                        <div className="selectWrapper">
                                            <CustomSelect handleChange={this.handleChange} name="budget" id="budgetSelect" options={this.budgetOptions} placeholder="Formulation budget" />
                                        </div>
                                        {/* <div className='DownSelectTitle'>
                                            <div className='ButtonSelectTitle'>
                                                <Accordion>
                                                    <AccordionSummary
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                    >
                                                        <Typography>Formulation budget</Typography>
                                                        <div className='CheckArrow'>
                                                            <img className="DownArrow" src={DownArrow} alt='DownArrow' />
                                                        </div>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        30 million - less than 50 million<br />
                                                        50 million - less than 100 million<br />
                                                        100 million to less than 200 million<br />
                                                        More than 200 million<br />
                                                        Direct input<br />
                                                    </AccordionDetails>
                                                </Accordion>
                                            </div>
                                          
                                        </div> */}
                                    </div>

                                </div>
                            </Grid>
                            <Grid item xs={12} md={6} className="rightWrapper">
                                <div className='RightInformationBox'>
                                    <div className='ProjectNameBox'>
                                        <div className='RequiredElements'>
                                            <div className='NameTitle'>
                                                REFERENCE SITE URL
                                        </div>
                                            {/* <div className='Emphasis'>
                                            *
                                        </div> */}
                                        </div>
                                        <div className='SelectTitle'>
                                            <input name="referenceSiteURL" type="text" onChange={this.handleChange} placeholder="Reference Site URL" />
                                        </div>
                                    </div>

                                    <div className='ProjectNameBox'>
                                        <div className='OtherRequiredElements'>
                                            <div className='NameTitle'>
                                                FILE ATTACHMENT
                                        </div>
                                            <div className='ComentEmphasis'>
                                                Upload as a zip file of 100MB or less.
                                        </div>
                                        </div>
                                        <div className="fileAttachmentWrapper">
                                            <div className="inputFilePlaceholder">
                                                {this.state.values.fileAttachment}
                                            </div>
                                            <input className="inputFile" ref={this.inputFileRef} onChange={this.handleUpload} type="file" />
                                            <button className="inputFileButton" onClick={this.handleClickFileButton}>
                                                Upload
                                            </button>
                                        </div>
                                    </div>


                                    <div className='ProjectNameBox'>
                                        <div className='RequiredElements'>
                                            <div className='NameTitle'>
                                                DETAILS OF THE INQURY
                                        </div>
                                            <div className='Emphasis'>
                                                *
                                        </div>
                                        </div>
                                        <div className='SelectTitle'>
                                            <input type="text" name="detail" onChange={this.handleChange} placeholder="Details of the inquiry" />
                                        </div>
                                    </div>
                                </div>

                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        );
    }
}

export default SharePage;