import React, { Component } from "react";
import First from "../Experience/Contents/29TV/Tv";
import Second from "../Home/PageList/SecondPage";
import Third from "../Home/PageList/ThirdPage";
import Fifth from "../Home/PageList/FifthPage";
import ReactFullpage from "@fullpage/react-fullpage";
import Control from "../Common/Control/Control";

class test extends Component {
	state = {
		currentPage: 1,
	};

	anchors = ["firstpage", "secondpage", "thirdpage", "fourpage", "fivepage"];

	fullpageapi = null;

	componentDidMount = () => {
       
		// const history = this.props.history.location.hash;
		this.setState({ currentPage: this.getPageNum("#firstpage") });
	};

	getPageNum = (history) => {
		if (history === "#firstpage") {
			return 1;
        }
         else if (history === "#secondpage") {
			return 2;
		} else if (history === "#thirdpage") {
			return 3;
		} else if (history === "#fourpage") {
			return 4;
		} else if (history === "#fivepage") {
			return 5;
		}
	};

	toNextPage = () => {
		if (this.state.currentPage < 5) {
			this.setState({ currentPage: this.state.currentPage + 1 }, () =>
				this.fullpageapi.moveTo(this.renderPagePath(this.state.currentPage))
			);
		}
	};

	toPrevPage = () => {
		if (this.state.currentPage > 1) {
			this.setState({ currentPage: this.state.currentPage - 1 }, () =>
				this.fullpageapi.moveTo(this.renderPagePath(this.state.currentPage))
			);
		}
	};

	renderPagePath = (num) => {
		if (num === 1) {
			return "firstpage";
		} else if (num === 2) {
			return "secondpage";
		} else if (num === 3) {
			return "thirdpage";
		} else if (num === 4) {
			return "fourpage";
		} else if (num === 5) {
			return "fivepage";
		}
	};

	render() {
        if(this.props.selectItem === "a"){
            return <div></div>
        }
        else{
            
        }
		return (<First />
			// <>
			// 	<ReactFullpage
			// 		//fullpage options
			// 		licenseKey={"YOUR_KEY_HERE"}
			// 		scrollingSpeed={1000} /* Options here */
			// 		anchors={this.anchors}
			// 		onLeave={(e, E) => {
			// 			this.setState({ currentPage: E.index + 1 });
			// 		}}
			// 		render={({ state, fullpageApi }) => {
			// 			this.fullpageapi = fullpageApi;
			// 			return (
			// 				<ReactFullpage.Wrapper>
			// 					<div
			// 						className="firstpage section"
			// 						style={{
			// 							// position: "fixed",
			// 							width: "100%",
			// 							height: "100%",
			// 							color: "#FFF",
			// 						}}>
			// 						<First />
			// 					</div>
			// 					{/* <div
			// 						className="secondpage section"
			// 						style={{
			// 							// position: "fixed",
			// 							width: "100%",
			// 							height: "100%",
			// 							color: "#FFF",
			// 						}}>
			// 						<Second />
			// 					</div>
			// 					<div
			// 						className="thirdpage section"
			// 						style={{
			// 							// position: "fixed",
			// 							width: "100%",
			// 							height: "100%",
			// 							color: "#FFF",
			// 						}}>
			// 						<Third />
			// 					</div>
			// 					<div
			// 						className="fourpage section"
			// 						style={{
			// 							// position: "fixed",
			// 							width: "100%",
			// 							height: "100%",
			// 							color: "#000",
			// 						}}>
			// 						<MainBack4 />
			// 					</div>
			// 					<div
			// 						className="fivepage section"
			// 						style={{
			// 							// position: "fixed",
			// 							width: "100%",
			// 							height: "100%",
			// 							color: "#FFF",
			// 						}}>
			// 						<Fifth />
			// 					</div> */}
			// 				</ReactFullpage.Wrapper>
			// 			);
			// 		}}
			// 	/>
			// 	<Control
			// 		number={`0${this.state.currentPage}`}
			// 		toNextPage={() => this.toNextPage()}
			// 		toPrevPage={() => this.toPrevPage()}></Control>
			// </>
		);
	}
}

export default test;

// animation-delay: 0ms
// animation-delay: 80ms
// animation-delay: 240ms;
// animation-delay: 320ms;
