import React from "react";
import "./style/movie.scss";
import promotion from "../../../../_asset/images/29TV/video_promotion.mp4";

const Movie = () => {
  return (
    <div className="moviecontainer">
      <div className="movie_Wrap">
        <video id="movie_promotion" loop autoPlay muted>
          <source src={promotion} type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

export default Movie;
