import React from "react";
import "./style/session4.scss";
import img from "../../../../_asset/images/severkit/04-img-all.png";

export default function Session4() {
    return (
        <div className="serverkit_session4">
            <div className="sk_main">
                <div className="sk_left">
                    서비스 배포에 많은 <br></br>시간이 소모되고 있지<br></br>
                    않은 가요?
                </div>
                <div className="sk_right">
                    <div className="sk_top">
                        <img src={img} alt="" />
                    </div>
                    <div className="sk_bottom">
                        간단한 수정사항이라도<br></br> 반영하는데
                        <span>시간이 너무 오래</span> 걸립니다.
                    </div>
                </div>
            </div>
        </div>
    );
}
