import img1 from "../../../../_asset/images/topzone/1_re.png";
import img5 from "../../../../_asset/images/topzone/2_re.png";
import img9 from "../../../../_asset/images/topzone/6_re.png";
import img10 from "../../../../_asset/images/topzone/HYUNDAI.png";
import img11 from "../../../../_asset/images/topzone/section7_11.png";
import img2 from "../../../../_asset/images/topzone/section7_2.png";
import img3 from "../../../../_asset/images/topzone/section7_3.png";
import img4 from "../../../../_asset/images/topzone/section7_4.png";
import img6 from "../../../../_asset/images/topzone/section7_6.png";
import img7 from "../../../../_asset/images/topzone/section7_7.png";
import img8 from "../../../../_asset/images/topzone/section7_8.png";
import img12 from "../../../../_asset/images/topzone/videomonster.png";
import img13 from "../../../../_asset/images/topzone/section7_13.png";
import img14 from "../../../../_asset/images/topzone/section7_14.png";
import img15 from "../../../../_asset/images/topzone/section7_15.png";
import img16 from "../../../../_asset/images/topzone/section7_16.png";
import img17 from "../../../../_asset/images/topzone/section7_17.png";
import img18 from "../../../../_asset/images/topzone/section7_18.png";

export const data = [
  {
    img: img1,
  },
  {
    img: img2,
  },
  {
    img: img3,
  },
  {
    img: img4,
  },
  {
    img: img5,
  },
  {
    img: img6,
  },
  {
    img: img7,
  },
  {
    img: img8,
  },
  {
    img: img9,
  },
  {
    img: img10,
  },
  {
    img: img11,
  },
  {
    img: img12,
  },
  {
    img: img13,
  },
  {
    img: img14,
  },
  {
    img: img15,
  },
  {
    img: img16,
  },
  {
    img: img17,
  },
  {
    img: img18,
  },
];
export default data;
