import React, { Component } from "react";
import Severkit from "../../Experience/Contents/Severkit/Severkit";

class SeverkitRouter extends Component {
  render() {
    return (
      <div>
        <Severkit></Severkit>
      </div>
    );
  }
}

export default SeverkitRouter;
