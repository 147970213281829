import React, { Component } from 'react';
import './Style/ForthPage.scss'
import arrow from "../../_asset/images/mainback/Arrow.png";
import { Link } from "react-router-dom"

class ForthPage extends Component {
    contents = [{
        title: "EXPERIENCE",
        text: ["grow through experience.", "01 / First Value"],
        to: "EXPERIENCE",
    },
    {
        title: "REASON",
        text: ["Evert design has its reasons.", "02 / Second Value"],
        to: "REASON",
    }, {
        title: "STORY",
        text: ["Every design must have a story.", "03 / Third Value"],
        to: "STORY",
    }, {
        title: "CREATIVITY",
        text: ["Is growing through.", "01 / Fourth Value"],
        to: "CREATIVITY",
    }]
    renderContent = (contents) => contents.map(({ title, text, to }) => (
        <div className='RightContentsBox'>
            <Link to={`/about/#${to}`}>
                <button className='RightTopBox'>
                    <div className='RightTitle'>{title}</div>
                    <img className="arrow" src={arrow} alt="화살표" />
                </button>
            </Link>
            <div className='RightBottomText'>
                {text.map((item) => <>{item} <br /> </>)}
            </div>
        </div>
    ))

    render() {
        return (
            <div className='ForthPageContainer'>
                {/* <a href='/Works'> */}
                <div className='ForthPageMain'>
                    <div className='ForthPageLeftBox'>
                        <div className='LeftText'>
                            Check
                        </div>
                        <div className='LeftArrow'>
                            &gt;
                        </div>
                    </div>
                    <div className='ForthPageRightBox'>
                        {this.renderContent(this.contents)}
                    </div>
                </div>
                {/* </a> */}
            </div>
        );
    }
}

export default ForthPage;