import React, { useState, useEffect } from "react";
import "./style/Esplash.scss";
import styled from "styled-components";
import splash01 from "../../../../_asset/images/emart/img_splash1.png";
import splash02 from "../../../../_asset/images/emart/img_splash2.png";
import splash03 from "../../../../_asset/images/emart/img_splash3.png";

const Titlebox = styled.div`
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 1s linear;
`;
const ListWrapOne = styled.div`
  display: inline-block;
  width: 350px;
  margin-right: 75px;
  vertical-align: top;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1.2s linear;
  transition: transform 1.2s linear;
  > img {
    box-shadow: 30px 10px 37px rgba(34, 34, 34, 0.4);
    border-radius: 40px;
  }
`;
const ListWrapTwo = styled.div`
  margin-top: 245px;
  display: inline-block;
  width: 350px;
  margin-right: 75px;
  vertical-align: top;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1.5s linear;
  transition: transform 1.5s linear;
  > img {
    box-shadow: 30px 10px 37px rgba(34, 34, 34, 0.4);
    border-radius: 40px;
  }
`;
const ListWrapThree = styled.div`
  margin: 490px 0 0 0;
  display: inline-block;
  width: 350px;
  vertical-align: top;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1.9s linear;
  transition: transform 1.9s linear;
  > img {
    box-shadow: 30px 10px 37px rgba(34, 34, 34, 0.4);
    border-radius: 40px;
  }
`;

const Esplash = ({ pageY }) => {
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    if (pageY >= 14500) {
      setTrigger(true);
    }
  });
  return (
    <div className="Esplashcontainer">
      <div className="con_bx">
        <Titlebox trigger={trigger}>
          <div className="con_title">Splash</div>
          <div className="con_text">
            스플래시 화면을 프로모션 영역으로 활용하여 고객이<br></br>
            이마트의 상품이나 이벤트를 은연중에 소비할 수 있습니다.
          </div>
        </Titlebox>
        <ul className="screen">
          <ListWrapOne trigger={trigger}>
            <img src={splash01} alt="splash_image01" />
          </ListWrapOne>
          <ListWrapTwo trigger={trigger}>
            <img src={splash02} alt="splash_image02" />
          </ListWrapTwo>
          <ListWrapThree trigger={trigger}>
            <img src={splash03} alt="splash_image03" />
          </ListWrapThree>
        </ul>
      </div>
    </div>
  );
};

export default Esplash;
