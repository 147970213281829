import { Dialog } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React, { Component } from "react";
import "./ContentDialog.scss";
import ProjectData from '../../../../Common/Id/NoticeId/index'

class ContentDialog extends Component {
	componentDidMount = () => {};

	render() {
		const { onClose, open, selectedContent } = this.props;
		console.log(selectedContent);
		console.log(ProjectData);
		return (
			<>
				<Dialog fullScreen={true} open={open} onClose={onClose}>
					<div className="content-dialog-container">
						<div className="closeBtn" onClick={onClose}>
							<div className="closeBar1" />
							<div className="closeBar2" />
						</div>
						<span><selectedContent.contents/></span>
					</div>
				</Dialog>
			</>
		);
	}
}

export default ContentDialog;
