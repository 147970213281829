import React, { Component } from 'react';
import './HeaderWhite.scss';
import HeaderLogo from '../HeaderLogo/HeaderLogo';
import HamburgerMenuWhite from '../HamburgerMenu/HamburgerMenuWhite';


class Header extends Component {
    render() {
        return (
            <div className="HeaderWhiteContainer">
                <header className="HeaderWhite">
                    <HeaderLogo></HeaderLogo>
                    <HamburgerMenuWhite></HamburgerMenuWhite>
                </header>
            </div>
        );
    }
}

export default Header;