import main from "./RouterMain/MainRouter";
import Works from "./RouterWorks/RouterWorks";
import Reauest from "./RouterReauest/RouterReauest";
import Tv29 from "./Router29TV/TvRouter";
import Emart from "./RouterEmart/Emartrouter";
import G9 from "./RouterG9/G9Router";
import Prorenata from "./RouterProrenata/ProrenataRouter";
import Kaola from "./RouterKaola/KaolaRouter";
import About from "./RouterAbout/RouterAbout";
import Contact from "./RouterContact/RouterContact";
import Hiring from "./RouterHiring/RouterHiring";
import Detail from "./RouterHiringDetail/RouterHiringDetail";
import Notice from "./RouterNotice/RouterNotice";
import News from "./RouterNews/RouterNews";
import SeverkitRouter from "./RouterSeverkit/SeverkitRouter";
import ToastCanvas from "./RouterToastCanvas/RouterToastCanvas.js";
import Topzone from "../Home/Topzone/TopZone";

var routes = [
	{
		exact: true,
		path: "/",
		component: main,
		menuName: "main",
	},
	{
		path: "/works",
		component: Works,
		menuName: "Works",
	},
	{
		path: "/about",
		component: About,
		menuName: "About",
	},
	{
		path: "/contact",
		component: Contact,
		menuName: "Contact",
	},
	{
		path: "/notice",
		component: Notice,
		menuName: "Notice",
	},
	{
		path: "/hiring",
		component: Hiring,
		menuName: "Hiring",
	},
	{
		path: "/detail",
		component: Detail,
		menuName: "Detail",
	},
	{
		path: "/experienceemart",
		component: Emart,
		menuName: "Emart",
	},
	{
		path: "/experience29tv",
		component: Tv29,
		menuName: "Tv29",
	},
	{
		path: "/G9",
		component: G9,
		menuName: "G9",
	},
	{
		path: "/Prorenata",
		component: Prorenata,
		menuName: "Prorenata",
	},
	{
		path: "/Kaola",
		component: Kaola,
		menuName: "Kaola",
	},
	{
		path:"/Reauest",
		component: Reauest,
		menuName: "Reauest",
	},
	{
		path: "/News/:id",
		component: News,
		menuName: "News",
	},
	{
		path: "/serverkit",
		component: SeverkitRouter,
		menuName: "Severkit"
	},
	{
		path: "/toastCanvas",
		component: ToastCanvas,
		menuName: "ToastCanvas",
	},
	{
		path: "/topzone",
		component: Topzone,
		menuName: "Topzone",
	},
];

var pages = (role) => {
	return routes.filter((r) => {
		return !r.role || r.role === role;
	});
};

export default pages;
