import React, { useEffect } from "react";
import Session1 from "./function/Session1";
import Session2 from "./function/Session2";
import Session3 from "./function/Session3";
import Session4 from "./function/Session4";
import Session5 from "./function/Session5";
import Session6 from "./function/Session6";
import Session7 from "./function/Session7";
import Session8 from "./function/Session8";
import Session9 from "./function/Session9";
import "./styles.scss"


const Severkit= () => {
  useEffect(() => {
    const body = document.querySelector("body");
    body.style.fontFamily = "Pretendard";
  });
  return (
    <div className="Severkit_container">
      <Session1/>
      <Session2/>
      <Session3/>
      <Session4/>
      <Session5/>
      <Session6/>
      <Session7/>
      <Session8/>
      <Session9/>
    </div>
  );
};

export default Severkit;
