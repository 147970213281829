
import React, { Component } from 'react';
import Reauest from '../../Home/Reauest/Reauest';
import FooterMain from '../../Common/FooterMain/FooterMain';
import HeaderMain from '../../Common/HeaderMain/HeaderMain';



class RouterReauest extends Component {
    render() {
        return (
            <div>
                <HeaderMain />
                <Reauest />
                <FooterMain />
            </div>
        );
    }
}

export default RouterReauest;