import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "./style/strategy.scss";

let list = [
  {
    number: "1.",
    title: "Gather\n Who?",
    em: "Generation Z-M",
    text:
      "소비와 트렌드를 주도하는 ZM세대를 불러모아야 합니다. 브랜드를 다루는 29CM과 선호하는 브랜드가\n 본인을 표현한다고 여기는 ZM세대와 29CM는 ‘BRAND’라는 공통된 관심사가 있습니다.",
  },
  {
    number: "2.",
    title: "What\n Functions?",
    em: "Creative Tools",
    text:
      "누구나 쉽게 효과를 적용하고 제작할 수 있는 창의적인 도구를 끊임없이 제공해야 합니다.\n 자유로운 ZM세대는 창의적인 오리지널리티를 만들기 위한 도구를 빌려와서 끊임없이 재창조하고,\n 이를 공유합니다.",
  },
  {
    number: "3.",
    title: "How to get effeciency?\n Why in 29CM",
    em: "Narcissism & Fun\n Not Benefits",
    text:
      "크리에이터의 나르시시즘을 자극하고 재미를 주어 콘텐츠 제작 동기를 형성하고자 했습니다. 유머와\n 엔터테인먼트는 ZM세대가 콘텐트를 만들고 소비하는 최우선 동기입니다. 그리고 그들은\n 자신을 표현하기 위해, 자랑하고자, 기록을 남겨 추억하기 위해, 타인으로부터 인정받고 공감받기 바라는\n 마음 등에서 나르시시즘을 갈망합니다.",
  },
];
const Title = styled.div`
  font-size: 55px;
  text-align: right;
  letter-spacing: 25px;
  line-height: 1.7;
  margin-bottom: 50px;
  font-weight: 700;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 1s linear;
  color: #fff;
`;

const ContentsList = styled.div`
  padding-bottom: 100px;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1.3s linear;
  transition: transform 1.3s linear;
`;
const Strategy = ({ pageY }) => {
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    if (pageY >= 8400) {
      setTrigger(true);
    }
  }, [pageY]);

  return (
    <div className="strategycontainer">
      <div className="strategy_top">
        <Title trigger={trigger}>
          UIUX
          <br />
          CONSULTING
          <br />
          STRATEGY
        </Title>
      </div>
      <div className="List_map">
        {list.map((item, index) => {
          return (
            <ContentsList trigger={trigger} key={index}>
              <div className="list_top">
                <div className="number">{item.number}</div>
                <div className="title">
                  {item.title.split("\n").map((e) => (
                    <span>
                      {e}
                      <br />
                    </span>
                  ))}
                </div>
              </div>
              <div className="list_bottom">
                <div className="em">
                  {item.em.split("\n").map((e) => (
                    <span
                      style={{
                        color:
                          index === 0
                            ? "#9617da"
                            : index === 1
                            ? "#322eff"
                            : "#8831ff",
                      }}
                    >
                      {e}
                      <br />
                    </span>
                  ))}
                </div>
                <div className="text">
                  {item.text.split("\n").map((e) => (
                    <span>
                      {e}
                      <br />
                    </span>
                  ))}
                </div>
              </div>
            </ContentsList>
          );
        })}
      </div>
    </div>
  );
};

export default Strategy;
