import React from "react";
import "./style/Pidentify.scss";

const Pidentify = () => {
  return (
    <div className="Pidentify_container">
      <div className="Pidentify_cont">
        <div className="Pidentify_txt_bx">
          <div className="Pidentify_tit">Brand Identify</div>
          <div className="Pidentify_txt">
            프로레나타의 로고타입은 Sans Serif와 Serif의 중간 스타일인 Humanist
            Sans계열의 스타일로서, 전반적으로<br></br>
            모던하고 고급스러운 브랜드 이미지를 전달합니다. 또한, 브랜드
            이니셜을 모노그램과 스템프로 활용하여 'PRN'으로도<br></br>
            커뮤니케이션하는 동시에 고객들에게 바이오슈티컬 스킨케어
            브랜드로서의 신뢰감을 부여합니다.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pidentify;
