import React from "react";
import "./style/session1.scss";

import logo from "../../../../_asset/images/severkit/logo.png";
import rightImage from "../../../../_asset/images/severkit/m-object.png";
import img25 from '../../../../_asset/images/severkit/open-icon.png'

export default function Session1() {
    return (
        <div className="serverkit_session1">
            <div className="serverkit_header">
                <div className="sk_logo">
                    <img src={logo} alt="logo" />
                </div>

                <div className="header__btn">
                    <div className="header__language">
                        Language
                        <img src={img25} alt="" />
                    </div>
                    <div className="header__login">가이드 다운로드</div>
                </div>
            </div>
            <div className="sk_main">
                <div className="sk_text">
                    <div className="sk_top">
                        <span>서버 개발을 누구나</span>
                        <span>쉽고 간단하게</span>
                    </div>
                    <div className="sk_bottom">
                        서버키트를 통해 <span>어떠한 유형의 개발이라도</span>
                        <br />
                        서버 개발을 간단하게 해낼 수 있습니다.
                    </div>
                </div>
                <div className="sk_image">
                    <img src={rightImage} alt="rightimage" />
                </div>
            </div>
        </div>
    );
}
