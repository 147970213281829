import React from "react";
import "./style/Pcard.scss";

const Pcard = () => {
  return (
    <div className="Pcard_container">
      <div className="Pcard_cont_1">
        <div className="Pcard_bx_1"></div>
      </div>
      <div className="Pcard_cont_2">
        <div className="Pcard_bx_2"></div>
      </div>
      <div className="Pcard_tit">
        <div className="tit">Bussiness Card / Letterhead / Envelope</div>
      </div>
    </div>
  );
};

export default Pcard;
