import React from "react";
import "./style/session7.scss";
import img from "../../../../_asset/images/severkit/07-img.png";

export default function Session7() {
    return (
        <div className="serverkit_session7">
            <div className="sk_main">
                <div className="sk_title">
                눈에 보이는 백엔드<br></br>
                <span>서버키트</span>
                </div>
               
                <div className="sk_image">
                    <img src={img} alt="" />
                </div>
            </div>
        </div>
    );
}
