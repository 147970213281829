import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "./style/marcket.scss";
import marcketvideo from "../../../../_asset/images/29TV/video_market.mp4";
import marcketlogo from "../../../../_asset/images/29TV/img_market_logo.png";

const Title = styled.div`
  font-size: 55px;
  letter-spacing: 25px;
  font-weight: 700;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 1s linear;
`;
const Subtitle = styled.div`
  font-size: 16px;
  margin-top: 50px;
  line-height: 2;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 1s linear;
`;
const Marcket = ({ pageY }) => {
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    if (pageY >= 4200) {
      setTrigger(true);
    }
  }, [pageY]);

  return (
    <div className="marcketcontainer">
      <div className="marcket_top">
        <Title trigger={trigger}>
          MARCKET
          <br />
          RESEARCH
        </Title>
        <Subtitle trigger={trigger}>
          업태를 특정하기 어려운 요즘, 29CM는 전통적인 커머스 시장에서 벌어지고
          있는 물류 및 가격정책에 대한
          <br />
          치킨게임에 참여하거나, 인플루언서의 상거래적 성공을 발판으로
          <br />
          커머스로 영역을 확장하고 있는 소셜 미디어와 경쟁해야 하는 선택의
          기로에 서있습니다.
        </Subtitle>
      </div>
      <div className="desc_area">
        <div className="desc_Wrap">
          <video id="marcket_video" loop autoPlay>
            <source src={marcketvideo} type="video/mp4" />
          </video>
          <div className="logo">
            <img src={marcketlogo} alt="marcket_logo"></img>
          </div>
          <dl className="top">
            <dt className="block_01">Commerce</dt>
            <dt className="block_02">Price & Distribution</dt>
          </dl>
          <dl className="bottom">
            <dt className="block_03">Commerce</dt>
            <dt className="block_04">Price & Distribution</dt>
          </dl>
        </div>
      </div>
    </div>
  );
};

export default Marcket;
