import React, { Component } from 'react';
import "./Style/BasicPage.scss"
import "./../Reauest.scss"
import ElementCheckButton from '../../../../src/_asset/images/mainback/selected-line-circle-icon.svg'
import GrayCircle from '../../../../src/_asset/images/mainback/gray-line-circle-icon.svg'
import { Grid } from '@material-ui/core';
import CheckboxButton from '../../../Components/CheckboxButton/CheckboxButton';

class SharePage extends Component {
    state = {
        agreeState: false,
        ComBtn: false,
        WebBtn: false,
        BrandingBtn: false,
        fitstList: [
            {
                id: 1,
                title: "Annual Operation",
            },
            {
                id: 2,
                title: " Reactive Web",
            },
            {
                id: 3,
                title: " Photography",
            },
            {
                id: 4,
                title: "Search Engin Optimization (SEO)",
            },



        ],
        secondList: [
            {
                id: 5,
                title: "Character Design",
            },
            {
                id: 6,
                title: "Illustration Design",
            },
            {
                id: 7,
                title: "Search Engine Optimization (SEO)",
            },
            {
                id: 8,
                title: "Character Design",
            },
        ],
        thirdList: [
            {
                id: 9,
                title: "Development of WordPress Custom Theme",
            },
            {
                id: 10,
                title: "Maintenance",
            },
            {
                id: 11,
                title: "Shopping mall",
            },
            {
                id: 12,
                title: "Other",
                subtitle: "*Please write down the details in detail",
            },
        ],

        checkedList: []
    }
    // classes = useStyles();
    handleAgreeCheck = () => {
        if (this.state.agreeState) {
            this.setState({ agreeState: false })
        } else {
            this.setState({ agreeState: true })
        }
    }

    handleCheckedItem = (value) => {

        if (
            !this.state.checkedList.find((item) => item === value)
        ) {
            let checkedList = this.state.checkedList;
            checkedList.push(value);
            this.setState({ checkedList });
        } else {
            let checkedList = this.state.checkedList;
            checkedList = checkedList.filter((item) => item !== value);
            this.setState({ checkedList });
        }
    };
    render() {
        return (
            <div>
                <div className="AllBasicPage">
                    <hr class="secondhrcss"></hr>
                    <div className='EnterProjectTitle'>
                        Basic<br />
                        Information
                    </div>
                    <div className='InformationFillinBox'>
                        <Grid container spacing={3}>
                            <Grid className="leftWrapper" item xs={12} md={6}>
                                <div className='LeftInformationBox'>
                                    <div className='ProjectNameBox'>
                                        <div className='RequiredElements'>
                                            <div className='NameTitle'>
                                                COMPANY INFORMATION
                                        </div>
                                            <div className='Emphasis'>
                                                *
                                        </div>
                                        </div>
                                        <div className='SelectTitle'>
                                            <input type="text" placeholder="Company information" />

                                        </div>
                                    </div>

                                    <div className='ProjectNameBox'>
                                        <div className='RequiredElements'>
                                            <div className='NameTitle'>
                                                NAME OF PERSON IN CHARGE / POSITION
                                        </div>
                                            <div className='Emphasis'>
                                                *
                                        </div>
                                        </div>
                                        <div className='SelectTitle'>
                                            <input type="text" placeholder="Name of person in charge / Position" />

                                        </div>
                                    </div>

                                    <div className='ProjectNameBox'>
                                        <div className='RequiredElements'>
                                            <div className='NameTitle'>
                                                CONTACT
                                        </div>
                                            <div className='Emphasis'>
                                                *
                                        </div>
                                        </div>
                                        <div className='SelectTitle'>
                                            <input type="text" placeholder="Contact" />

                                        </div>
                                    </div>

                                </div>

                            </Grid>
                            <Grid className="rightWrapper" item xs={12} md={6}>
                                <div className='RightInformationBox'>

                                    <div className='ProjectNameBox'>
                                        <div className='ProjectNameBox'>
                                            <div className='RequiredElements'>
                                                <div className='NameTitle'>
                                                    EMAIL
                                                 </div>
                                                <div className='Emphasis'>
                                                    *
                                                 </div>
                                            </div>
                                            <div className='SelectTitle'>
                                                <input type="text" placeholder="Email" />

                                            </div>
                                        </div>
                                    </div>

                                    <div className='ProjectNameBox'>
                                        <div className='ProjectNameBox'>
                                            <div className='RequiredElements'>
                                                <div className='NameTitle'>
                                                    WEBSITE URL
                                                 </div>
                                                {/* <div className='Emphasis'>
                *
                                                 </div> */}
                                            </div>
                                            <div className='SelectTitle'>
                                                <input type="text" placeholder="Website URL" />

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Grid>

                        </Grid>
                    </div>
                    <div className='LastContainer'>
                        <div className='LastPrivacyLeftBox' onClick={this.handleAgreeCheck}>

                            <CheckboxButton handleChange={this.handleAgreeCheck} label="I agree with the privacy policy" requiredText="*Required" />
                            
                        </div>
                        <div className='LastPrivacyRightBox'>
                            {/* <button className='SubmitButton'>
                                    SUBMIT
                            </button> */}
                            <div className='Button'
                                onMouseDown={() => this.setState({ ComBtn: true })}
                                onMouseUp={() => this.setState({ ComBtn: false })} >
                                SUBMIT
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        );
    }
}

export default SharePage;