/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect } from "react";
import "./FullScreenSquare.scss";
import { Flipped } from "react-flip-toolkit";
import { useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function FullScreenSquare({ toggleFullScreen, item }) {
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        const timeOut = setTimeout(() => {
            setIsLoading(false);
        }, 500);
        return () => {
            if (timeOut) {
                clearTimeout(timeOut);
            }
        };
    }, []);
    const handleLoad = (e) => {
        setTimeout(() => {
            setIsLoading(false);
        }, 500);
    };

    const imgList = () => {
        return item.images.map((img, index) => (
            <img
                onLoad={handleLoad}
                style={{
                    marginBottom: "15px",
                    display: isLoading ? "none" : "block",
                }}
                src={img}
                alt="fullScreen"
            />
        ));
    };

    return (
        <Flipped flipId="square">
            <div className="full-screen-square" onClick={toggleFullScreen}>
                <div className="back-container">
                    <div className="back-button">&#215;</div>
                </div>
                {isLoading && <CircularProgress />}
                {item && item.video?.trim() ? (
                    <div
                        style={{
                            width: "100%",
                            position: "relative",
                            paddingBottom: "56.25%",
                        }}
                    >
                        <iframe
                            src={item.video}
                            frameborder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowfullscreen
                            style={{
                                position: "absolute",
                                top: "0",
                                left: "0",
                                width: "100%",
                                height: "100%",
                            }}
                        />
                    </div>
                ) : (
                    <></>
                )}
                {/* {item && item.video ? <video></video> : <></>} */}
                {imgList()}
                {/* {<img onLoad={handleLoad} style={{ display: isLoading ? "none" : "block" }} src={item?.fullScreenImg} alt="fullScreen" />} */}
            </div>
        </Flipped>
    );
}
