import React from "react";
import "./style/Ptender.scss";

const Ptender = () => {
  return (
    <div className="Prender_container">
      <div className="Prender_cont_1">
        <div className="Ptender_bx_01"></div>
      </div>
      <div className="Prender_cont_2"></div>
      <div className="Prender_cont_3">
        <div className="Ptender_bx_02"></div>
      </div>
      <div className="Prender_cont_4">
        <div className="Ptender_bx_03"></div>
      </div>
      <div className="Ptender_tit">
        <div className="Left_bx">
          저가형 마스크팩의 경우 제품 전면에 타공 처리를 하여 소비자들이
          <br></br>
          마스크 시트의 특징을 확인할 수 있도록 하였으며, 고가형 마스크팩의 경우
          <br></br>
          드라이갤 제형의 특성상 마스크 시트를 여러번 접을 수 있다는 특징을
          <br></br>
          고려하여 기존 마스크팩 보다 크기가 작은 형태로 디자인하였습니다.
        </div>
        <div className="Right_bx">
          Mask Sheet Packaging<br></br>
          (Low Price / High Price)
        </div>
      </div>
    </div>
  );
};

export default Ptender;
