import React, { useState, useEffect } from "react";
import "./style/Gvaluable.scss";
import styled from "styled-components";

import vlauelist1 from "../../../../_asset/images/g9/img_mobile_premiem_01.png";
import vlauelist2 from "../../../../_asset/images/g9/img_mobile_premiem_02.png";
import vlauevd from "../../../../_asset/images/g9/08_premium_mobile.mp4";
import vlauelist3 from "../../../../_asset/images/g9/img_mobile_premiem_03.png";

const TextBox = styled.div`
  right: 96px;
  top: -101px;
  position: absolute;
`;

const UlList = styled.div`
  display: flex;
`;

const Gvaluable = ({ pageY }) => {
  const [trigger, setTrigger] = useState(false);
  useEffect(() => {
    if (pageY >= 21000) {
      setTrigger(true);
    }
  });
  return (
    <div className="Gvaluablecontainer">
      <div className="container">
        <div className="premium_mobile_inner">
          <TextBox>
            <div className="Gvalue_title">
              MAKE VALUABLE<br></br>
              PRODUCTS MORE VALUABLE
            </div>
            <div className="Gvalue_text">
              G9에는 보다 가치있는 상품을 구매할 수 있는 프리미엄관이
              존재합니다. 프리미엄관은 <br></br>
              상품의 가치를 더욱 가치있게 만들어 줍니다. 그리고 상설관인 스타일
              페이지는 <br></br>
              그때그때 상황에 맞게 제작되어 새로운 경험을 제공할 것입니다.
            </div>
          </TextBox>
          <UlList>
            <li className="value_list01">
              <img src={vlauelist1} alt="value_list" />
            </li>
            <li className="value_list02">
              <img src={vlauelist2} alt="value_list" />
            </li>
            <li className="value_list03">
              <video id="valuevd" loop autoPlay>
                <source src={vlauevd} alt="valuevd" />
              </video>
            </li>
            <li className="value_list04">
              <img src={vlauelist3} alt="value_list" />
            </li>
          </UlList>
        </div>
      </div>
    </div>
  );
};

export default Gvaluable;
