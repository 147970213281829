import React, { useEffect, useState } from "react";
import Cover from "../29TV/function/cover";
import Overview from "./function/overview";
import Mission from "./function/mission";
import Marcket from "./function/marcket";
import Interview from "./function/interview";
import "./Tv.scss";
import Strategy from "./function/strategy";
import Media from "./function/media";
import Launch from "./function/launch";
import Social from "./function/social";
import Archive from "./function/archive";
import Brand from "./function/brand";
import Video from "./function/video";
import Connect from "./function/connect";
import Movie from "./function/movie";
import Credit from "./function/credit";

const Tv = () => {
  const [pageY, setPageY] = useState(0);

  useEffect(() => {
    const body = document.querySelector("body");
    body.style.fontFamily = "GmarketSansTTF";
    const handleScroll = () => {
      // pageYOffset : 스크롤 Y 위치 값
      const { pageYOffset } = window;
      setPageY(pageYOffset);
      console.log(pageYOffset);
    };
    // 페이지에서 스크롤 될때마다 발생하는 이벤트 추가 (handleScroll 함수)
    window.addEventListener("scroll", handleScroll);
  }, []);

  //Overview로 pageY state 넘겨줌
  return (
    <div className="Tvcontainer">
      <Cover></Cover>
      <Overview pageY={pageY}></Overview>
      <Mission pageY={pageY}></Mission>
      <Marcket pageY={pageY}></Marcket>
      <section className="black">
        <Interview pageY={pageY}></Interview>
      </section>
      <section className="black">
        <Strategy pageY={pageY}></Strategy>
      </section>
      <Media pageY={pageY}></Media>
      <section className="pupple">
        <Launch pageY={pageY}></Launch>
      </section>
      <section className="white">
        <Social pageY={pageY}></Social>
      </section>
      <Archive pageY={pageY}></Archive>
      <section className="brand">
        <Brand pageY={pageY}></Brand>
      </section>
      <section className="video">
        <Video pageY={pageY}></Video>
      </section>
      <section className="connect">
        <Connect pageY={pageY}></Connect>
      </section>
      <section className="movie">
        <Movie></Movie>
      </section>
      <section className="credit">
        <Credit pageY={pageY}></Credit>
      </section>
    </div>
  );
};

export default Tv;
