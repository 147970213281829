import React from "react";
import "./style/session5.scss";
import img from "../../../../_asset/images/severkit/05-img-all_2.png";

export default function Session5() {
    return (
        <div className="serverkit_session5">
            <div className="sk_main">
                <div className="sk_left">
                    백엔드 개발의 대부분이<br></br>반복 작업이라는 사실을
                    <br></br>아시나요?
                </div>
                <div className="sk_right">
                    <div className="sk_top">
                        <img src={img} alt="" />
                    </div>
                    <div className="sk_bottom">
                        개발 프로젝트의 성공을 위해서는<br></br>
                        <span>효율적인 개발이</span> 필요합니다.
                    </div>
                </div>
            </div>
        </div>
    );
}
