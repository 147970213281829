import React from "react";
import "./style/Ksection02.scss";
import section02 from "../../../../_asset/images/Kaola/img_strategy1.png";
import contbx2 from "../../../../_asset/images/Kaola/img_background.png";

const Ksection02 = () => {
  return (
    <div className="Ksection02_container">
      <div className="Ksection02_cont_1">
        <div className="Section02_cont">
          <div className="tit_bx">
            <div className="tit">A. Brand Strategy</div>
            <div className="txt">
              왕이카올라의 브랜드 경험을 만드는 것은 왕이카올라디움을 정의하는
              것에서부터 시작합니다.<br></br>
              기존 왕이카올라의 브랜드 자산을 바탕으로 왕이카올라 고유의 본질을
              파악하여 브랜드 이미지, 핵심가치,<br></br>
              표현원칙 등의 브랜드 방향성을 정의합니다.
            </div>
          </div>
        </div>
        <div>
          <img src={section02} alt="section_image" />
        </div>
      </div>
      <div className="Ksection02_cont_2">
        <div className="cont_2">
          <img src={contbx2} alt="contbx2_image" className="cont_bx2" />
        </div>
      </div>
      <div className="Ksection03_cont_3">
        <div className="tit3">Brand Manifesto</div>
        <div className="cont_3">
          <div className="Left">왕이카올라는,</div>
          <div className="Right">
            시시각각 변하는 트렌드를 쫓기보다 가치 위주의<br></br>
            삶의 방식을 소개하는 라이프스타일 큐레이션 플랫폼입니다.<br></br>
            <br></br>
            불필요한 정보에 귀 기울이지 않고 아무 브랜드나 섭취하지 않는
            <br></br>
            확고한 취향으로 값지고 유익한 세상의 모든 것들을 모아<br></br>
            고객에게 필요한 순간에 바로 제공하여<br></br>
            <br></br>
            세상의 다양하고 멋진 라이프스타일과 고객 사이의<br></br>
            시간과 거리를 줄여주는 라이프스타일 얼리어답터를 지향합니다.
            <br></br>
            단순히 좋은 제품을 제공하는 것이 아니라<br></br>
            이유 있는 선택을 제공하는 왕이카올라를 통해 모든 고객이<br></br>
            보다 좋은 라이프스타일을 향유할 수 있기를 바랍니다.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ksection02;
