import React, { Component } from 'react';
import "./AboutTop.scss"
import firstimg from '../../../_asset/images/mainback/about-img-1@3x.png'
import LeftImg from '../../../_asset/images/mainback/about-img-2@2x.png'
import RightImg from '../../../_asset/images/mainback/about-img-3@2x.png'

class AboutTop extends Component {
    render() {
        return (
            <div className="AboutTopContainer">
                <div className="AboutTopMain">
                    <div className="AboutTopTitleBox">
                        <div className='FontBox'>
                            <div className='RightText'>
                                -UIX<br />
                                ALWAYS<br />
                                THINKS.
                            </div>
                            <div className="LeftTextBox">
                                <div className='LeftText'>
                                    Creative and innovative ideas<br />
                                    Communicate and <span>empathize</span> with customers.<br />
                                    For better <span>completion</span>.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="FirstImgBox">
                        <img className="firstimg" src={firstimg} alt='first' />
                    </div>
                    <div className="ExperienceContainer" id="EXPERIENCE">
                        <div className='ExprienceMain'>
                            <div className="ExprienceTextBox">
                                <div className='Value'>
                                    01 / First Value.
                                </div>
                                <div className='Title'>
                                    Experience
                                </div>
                                <div className='SubText'>
                                    Uix has evolved through many experiences.<br />
                                    Everything we do will have reliable results.<br />
                                    Practical and reliable work,<br />
                                    The abilities that have been created by experience.<br />
                                    It's the power we've built up.
                                </div>
                            </div>
                            <div className="ExprienceImgBox">
                                <div className='LeftImgBox'>
                                    <img className='LeftImg' src={LeftImg} alt='LeftImg' />
                                    <div className='ImgText'>
                                        DETAIL VIEW. CONTACT
                                    </div>
                                </div>
                                <div className='RightImgBox'>
                                    <img className='RightImg' src={RightImg} alt='RightImg' />
                                    <div className='ImgText'>
                                        DETAIL VIEW. CONTACT
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AboutTop;