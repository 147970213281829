import React from "react";
import "./style/session2.scss";
import img1 from '../../../../_asset/images/severkit/02-img-01.png'
import img2 from '../../../../_asset/images/severkit/02-img-02.png'
import img3 from '../../../../_asset/images/severkit/02-img-03.png'


export default function Session2() {
    return (
        <div className="serverkit_session2">
            <div className="sk_main">
                <div className="sk_top">
                    <div className="sk_title">눈에 보이는 백엔드</div>
                    <div className="sk_content">서버 개발의 새로운 패러다임을 경험해보시 보세요.</div>
                </div>

                <div className="sk_bottom">
                    <div className="sk_item">
                        <img src={img1} alt="" /> 

                    </div> 
                    <div className="sk_item">
                        <img src={img2} alt="" /> 

                    </div> 
                    <div className="sk_item">
                        <img src={img3} alt="" /> 

                    </div> 
                </div>
            </div>
        </div>
    );
}
