import React from "react";
import "./style/Plast.scss";

const Plast = () => {
  return (
    <div className="Plast_container">
      <div className="Plast_cont_1"></div>
      <div className="Plast_cont_2"></div>
      <div className="Plast_tit_bx">
        <div className="tit">SNS Contents</div>
      </div>
    </div>
  );
};

export default Plast;
