import React, { Component } from "react";
import "./Style/FifthPage.scss";
import "../../Common/LinkClick/LinkClick.scss";
import backimg from "../../_asset/images/mainback/bg-05@3x.png";

class FifthPage extends Component {
  handleClickBoard = () => {
    navigator.clipboard.writeText("uix.hello@gmail.com");
    alert("이메일이 복사되었습니다.");
  };
  render() {
    return (
      <div
        className="FifthPage"
        style={{ width: "100%", height: "100%", color: "#FFF" }}
      >
        <div className="BackImg">
          <img src={backimg} alt="Background-image" />
          <div className="Link">
            <div className="link-container">
              <div className="LinkClickContainer" onClick={this.handleClickBoard} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FifthPage;
