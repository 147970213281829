import React, { Component } from 'react';
import './About.scss';
import './Slider.scss';
import brand1 from '../../_asset/images/About/logo-01@2x.png';
import brand2 from '../../_asset/images/About/logo-02@2x.png';
import brand3 from '../../_asset/images/About/logo-03@2x.png';
import brand4 from '../../_asset/images/About/logo-04@2x.png';
import brand5 from '../../_asset/images/About/logo-05@2x.png';
import brand6 from '../../_asset/images/About/logo-06@2x.png';
import brand7 from '../../_asset/images/About/logo-07@2x.png';
import brand8 from '../../_asset/images/About/logo-08@2x.png';
import brand9 from '../../_asset/images/About/logo-09@2x.png';
import brand10 from '../../_asset/images/About/logo-10@2x.png';
import firstimg from '../../_asset/images/mainback/about-img-1@3x.png';
import LeftImg from '../../_asset/images/mainback/about-img-2@2x.png';
import RightImg from '../../_asset/images/mainback/about-img-3@2x.png';
import AboutTop from './AboutTop/AboutTop';
import AboutMiddle from './AboutMiddle/AboutMiddle';
import AboutBot from './AboutBot/AboutBot';
import Flickity from 'react-flickity-component';
import gsap from 'gsap';
import Slider from 'react-slick';

import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { withRouter } from 'react-router-dom';
gsap.registerPlugin(ScrollToPlugin);

const slideshowEl = document.querySelector('.js-slideshow');

// const flickityOptions = {
//     prevNextButtons: true,
//     pageDots: false,
//     draggable: true,
//     wrapAround: true,
//     selectedAttraction: 0.015,
//     friction: 0.25,
//     initialIndex: 5,
//   };
const settings = {
  speed: 5000,
  autoplay: true,
  autoplaySpeed: 0,
  centerMode: true,
  cssEase: 'linear',
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  infinite: true,
  initialSlide: 1,
  arrows: false,
  buttons: false,
};
class About extends Component {
  state = {
    tickerSpeed: 0.5,
    slideshowEl: document.querySelector('.js-slideshow'),
    isCateClicked: false,
    isPause: false,
  };
  flkty;
  flickityOptions = {
    prevNextButtons: false,
    pageDots: false,
    draggable: true,
    wrapAround: true,
    // selectedAttraction: 0.015,
    friction: 0.25,
    initialIndex: 5,
    autoPlay: 100,
  };
  componentDidMount = () => {

    // this.flkty.on('dragEnd', (event, pointer, cellElement, cellIndex) => {
    //   this.flkty.playPlayer();
    // });
    const section = this.props.history.location.hash;
    gsap.to(window, { duration: 0, scrollTo: `${section}`, ease: "none" });
  }
  // componentDidMount = async () => {
  //     // const slideshowEl = document.querySelector('.js-slideshow')
  //     const prevNextButtons = document.getElementsByClassName("flickity-button");

  //     for (var i = 0; i < prevNextButtons.length; i++) {
  //       prevNextButtons[i].addEventListener("mouseover", this.pause, false);
  //     }

  //     for (var i = 0; i < prevNextButtons.length; i++) {
  //       prevNextButtons[i].addEventListener("click", this.handleToPause, false);
  //     }

  //     for (var i = 0; i < prevNextButtons.length; i++) {
  //       prevNextButtons[i].addEventListener(
  //         "mouseleave",
  //         this.handleToPlay,
  //         false
  //       );
  //     }

  //     // prevNextButtons.addEventListener('mouseover', this.test, false)

  //     this.flkty.on("staticClick", (e) => {
  //       const itemId = e.path[1].attributes[1].value;
  //       if (this.state.clickedCategory === itemId) {
  //         this.pause();
  //         this.handleClickClear();
  //       } else {
  //         console.log(this.fikty)

  //       }
  //     });

  //     this.flkty.on("dragStart", () => {
  //       this.flkty.slider.childNodes.forEach(
  //         (slide) => (slide.style.pointerEvents = "none")
  //       );
  //       this.setState({ isPaused: true });
  //     });

  //     this.update();
  //   };
  //     update = () => {
  //         if (this.state.isPaused) {
  //           return;
  //         } else {
  //           this.flkty.x =
  //             (this.flkty.x - this.state.tickerSpeed) % this.flkty.slideableWidth;
  //           this.flkty.selectedIndex = this.flkty.dragEndRestingSelect();
  //           this.flkty.updateSelectedSlide();
  //           this.flkty.settle(this.flkty.x);
  //           window.requestAnimationFrame(this.update);
  //         }
  //       };

  //   handleToPlay = () => {
  //     this.setState({ isPaused: false, tickerSpeed: 0.5 }, () => {
  //       this.update();
  //     });
  //   };
  render() {
    return (
      <div className="AboutContainer">
        <AboutTop />
        <AboutMiddle />
        <AboutBot />
        <div className="BorderBox">
          <div className="Border" />
        </div>
        <div className="AboutBox">
          <div className="AboutRightBox">
            <Slider
              // flickityRef={(c) => (this.flkty = c)}
              className={'slideshow js-slideshow'} // default ''
              // options={this.flickityOptions}
              {...settings}
            >
              <div className="slide-item">
                <img src={brand1} alt="협력 기업" />
              </div>
              <div className="slide-item">
                <img src={brand2} alt="협력 기업" />
              </div>
              <div className="slide-item">
                <img src={brand3} alt="협력 기업" />
              </div>
              <div className="slide-item">
                <img src={brand4} alt="협력 기업" />
              </div>
              <div className="slide-item">
                <img src={brand5} alt="협력 기업" />
              </div>
              <div className="slide-item">
                <img src={brand6} alt="협력 기업" />
              </div>
              <div className="slide-item">
                <img src={brand7} alt="협력 기업" />
              </div>
              <div className="slide-item">
                <img src={brand8} alt="협력 기업" />
              </div>
              <div className="slide-item">
                <img src={brand9} alt="협력 기업" />
              </div>
              <div className="slide-item">
                <img src={brand10} alt="협력 기업" />
              </div>
            </Slider>
          </div>
        </div>
        <div className="BorderBox">
          <div className="Border" />
        </div>
      </div>
    );
  }
}

export default withRouter(About);
