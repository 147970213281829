import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "./style/Edirection.scss";
import number from "../../../../_asset/images/emart/txt_num.png";
import phone01 from "../../../../_asset/images/emart/redirection1.png";
import phone02 from "../../../../_asset/images/emart/redirection02.png";
import phone03 from "../../../../_asset/images/emart/redirection03.png";
import phone04 from "../../../../_asset/images/emart/redirection04.png";
import phone05 from "../../../../_asset/images/emart/redirection05.png";
import imgae from "../../../../_asset/images/emart/img_direction.png";

let list = [
  {
    title: "#탐색",
    text:
      "명확하지 않은 메뉴명은 쉬운\n 레이블로 개선하고, 메뉴를\n 재그룹핑합니다.",
  },
  {
    title: "#메모",
    text:
      "장보기 경험에서 핵심 과업인\n 메모 기능을 제공하여 장보기\n 준비를 돕습니다.",
  },
  {
    title: "#수집",
    text:
      "장보기 전, 고객에게 유용한\n 쇼핑 정보를 한 눈에 볼 수 있게\n 제공합니다.",
  },
  {
    title: "#수집 #메모",
    text:
      "보유하고 있는 SNS 콘텐츠를\n 이마트 서비스와 연계하여 장보는\n 행동으로 연결될 수 있도록 합니다.",
  },
  {
    title: "#습관 #수집",
    text: "고객이 필요로 하는 상품, 정보를\n 잘 보이는 곳에서 보여줍니다.",
  },
];

const Title = styled.div`
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 1s linear;
`;
const ListOne = styled.div`
  margin-top: 482px;
  margin-right: 151px;
  list-style: none;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 60px, 0px)"};
  transition: opacity 1.8s linear;
  transition: transform 1.8s linear;
`;
const ListTwo = styled.div`
  margin-top: 296px;
  margin-right: 153px;
  list-style: none;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 60px, 0px)"};
  transition: opacity 1.4s linear;
  transition: transform 1.4s linear;
`;

const ListThree = styled.div`
  list-style: none;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 1s linear;
`;
const BottomImgae = styled.div`
  overflow: hidden;
  margin-top: 198px;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 2s linear;
  transition: transform 2s linear;
`;

const Edirection = ({ pageY }) => {
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    if (pageY >= 6000) {
      setTrigger(true);
    }
  });
  return (
    <div className="Edirectioncontainer">
      <div className="txt_box">
        <Title trigger={trigger}>
          <div className="number">
            <img src={number} alt="direction_image01" />
          </div>
          <div className="screen_title">DIRECTION</div>
          <div className="screen_text">
            서비스 스터디, 실무진 인터뷰, 사용자 조사, 통계 데이터를 <br></br>
            분석하여 서비스 방향성과 키워드를 도출하여<br></br>
            서비스 콘셉을 도출하기위한 토대를 마련했습니다.
          </div>
        </Title>
        <ul style={{ padding: "0px" }} className="screen_bx">
          <ListOne trigger={trigger}>
            <img src={phone01} alt="direction_image02" />
            <div className="em">Quck Acess</div>
          </ListOne>
          <ListTwo trigger={trigger}>
            <div className="image_Wrap">
              <img src={phone02} alt="direction_image03" />
              <div className="em">Key Task</div>
            </div>
            <img src={phone03} alt="direction_image04" />
            <div className="em">Cross Marketing</div>
          </ListTwo>
          <ListThree trigger={trigger}>
            <div className="image_Wrap">
              <img src={phone04} alt="direction_image05" />
              <div className="em">One Page Catalogue</div>
            </div>
            <img src={phone05} alt="direction_image06" />
            <div className="em">Come Close</div>
          </ListThree>
        </ul>
        <BottomImgae trigger={trigger}>
          <img src={imgae} alt="redirection_image07" />
          <ul className="txt">
            {list.map((item, index) => {
              return (
                <div className="txt_area" key={index}>
                  <div className="txt_title">{item.title}</div>
                  <div className="txt_text">
                    {item.text.split("\n").map((e) => (
                      <span>
                        {e}
                        <br />
                      </span>
                    ))}
                  </div>
                </div>
              );
            })}
          </ul>
        </BottomImgae>
      </div>
    </div>
  );
};

export default Edirection;
