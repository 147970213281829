import React, { Component } from 'react';
import News from '../../Home/News/News'
import FooterMainWhite from "../../Common/FooterMainWhite/FooterMainWhite";
import Header from '../../Common/HeaderMain/HeaderMain';
import Footer from '../../Common/FooterMain/FooterMain';

class RouterNews extends Component {

    render() {
        return (
            <div>
                <Header isLogoBlack={true} />
                <News match={this.props.match} />
                <FooterMainWhite />
            </div>
        );
    }
}

export default RouterNews;