import React, { Component } from 'react';
import './HeaderMain.scss';
import HeaderLogo from '../HeaderLogo/HeaderLogo';
import HamburgerMenu from '../HamburgerMenu/HamburgerMenu';


class Header extends Component {
    render() {
        const { isLogoBlack } = this.props
        const headerClassName = isLogoBlack ? "HeaderMainContainer black" : "HeaderMainContainer"
        return (
            <div className={headerClassName}>
                <div className="HeaderMain">
                    <HeaderLogo isLogoBlack={isLogoBlack}></HeaderLogo>
                    <HamburgerMenu isLogoBlack={isLogoBlack}></HamburgerMenu>
                </div>
            </div>
        );
    }
}

export default Header;