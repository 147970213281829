import React, { useState, useEffect } from "react";
import "./style/interview.scss";
import styled from "styled-components";
import interviewImg from "../../../../_asset/images/29TV/txt_interview02-1.png";

const Title = styled.div`
  font-size: 55px;
  letter-spacing: 25px;
  font-weight: 700;
  line-height: 1.8;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 1s linear;
  color: #fff;
`;

const Subtitle = styled.div`
  font-size: 16px;
  margin-top: 50px;
  line-height: 2;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 1s linear;
  color: #fff;
`;
const Interview = ({ pageY }) => {
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    if (pageY >= 5300) {
      setTrigger(true);
    }
  }, [pageY]);
  return (
    <div className="interveiwcontainer">
      <div className="interview_top">
        <Title trigger={trigger}>
          IN-DEPTH
          <br />
          INTERVIEW
        </Title>
        <Subtitle trigger={trigger}>
          프로젝트를 시작할 당시의 29CM 비즈니스 플랜과 제약사항을 파악하고
          <br />
          프로젝트 목표 및 방향성을 재확인하기 위해 29CM 임직원 인터뷰를
          진행했습니다.
        </Subtitle>
      </div>
      <div className="interview_block">
        <div className="contents_01">29CM Contents</div>
        <div className="contents_02">
          <img src={interviewImg} alt="contents_Image" />
        </div>
        <div className="contents_Wrap">
          <dt className="contents_03">
            <div className="contents_title">Media Commerce</div>
          </dt>
          <div className="contents_04">
            29CM 2.0는 29CM가 가진 콘텐츠적 강점을 살릴 수 있는
            <br />
            미디어커머스를 지향합니다.
            <br />
            따라서, 29CM는 쇼핑에 유용한 미디어 콘텐츠를 바탕으로 사람들을
            유입시키고,
            <br />
            유입된 사람들이 29CM에서 콘텐츠를 제작해야 할 이유와 동기를 제공해야
            하며,
            <br />
            누구나 쉽게 콘텐츠를 제작할 수 있는 도구를 제공해야 합니다.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Interview;
