import React from 'react';
import Header from './function/Section1/Section1';
import Section2 from './function/Section2/Section2';
import Section3 from './function/Section3/Section3';
import Section4 from './function/Section4/Section4';
import Section5 from './function/Section5/Section5';
import Section6 from './function/Section6/Section6';
import Section7 from './function/Section7/Section7';
import Footer from './function/Section8/Footer';
import './styles.scss';

function Topzone() {
  return (
    <div className="topzone-container">
      <Header />
      <Section2 />
      <Section3  />
      <Section4 />
      <Section5 />
      <Section6 />
      <Section7 />
      <Footer />

    </div>
  )
}

export default Topzone;
