import { Grid } from '@material-ui/core';
import React, { Component } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Fackbooksvg from '../../_asset/images/mainback/sns-icon-face.svg'
import Instagramsvg from '../../_asset/images/mainback/sns-icon-insta.svg'
import Vimeosvg from '../../_asset/images/mainback/sns-icon-vimeo.svg'
import './Contact.scss'


class Contact extends Component {
    render() {
        const url = "uix.hello@gmail.com"; // url 복사
        const number = "070-4272-8645"; // phone number 복사

        return (
            <div className='ContactContainer'>
                <div className='ContactMain'>
                    <div className='RightText'>
                        LEAD THE TREND<br />
                        WITH US<br />
                        RIGHT NOW.
                    </div>
                    <div className='ContactMiddle'>
                        <Grid container className='ContactMiddleBox'>
                            <Grid item xs={6} lg={6}>
                                <div className='ContactLeftBox'>
                                    <div className="InfoContents">
                                        <div className="InfoBox Call">
                                            <div className='InfoTitle'>
                                                CALL
                                        </div>
                                            <div className='InfoText'>
                                                <CopyToClipboard text={number}>
                                                    <button className='ContactTitle'>070-4272-8645</button>
                                                </CopyToClipboard>
                                            </div>
                                        </div>
                                        <div className="InfoBox Support">
                                            <div className='InfoTitle'>
                                                SUPPORT
                                        </div>
                                            <div className='InfoText'>
                                                <CopyToClipboard text={url}>
                                                    <button className='ContactTitle'>uix.hello@gmail.com</button>
                                                </CopyToClipboard>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Grid>
                            <Grid item xs={6} lg={6}>
                                <div className='ContactRightBox'>
                                    <div className='Title'>
                                        FOLLOW
                                </div>
                                    <div className="FollowBox">
                                        <button className='SnsBox Facebook'>
                                            <a href="/">
                                                <div className='SnsTitle'>
                                                    Facebook
                                            </div>
                                                <img className="SnsIcon" src={Fackbooksvg} alt='Fackbooksvg' />
                                            </a>
                                        </button>

                                        <button className='SnsBox Instagram'>
                                            <a href="/">
                                                <div className='SnsTitle'>
                                                    Instagram
                                            </div>
                                                <img className="SnsIcon" src={Instagramsvg} alt='Instagramsvg' />
                                            </a>
                                        </button>
                                        <button className='SnsBox Vimeo'>
                                            <a href="/">
                                                <div className='SnsTitle'>
                                                    Vimeo
                                            </div>
                                                <img className="SnsIcon" src={Vimeosvg} alt='Vimeosvg' />
                                            </a>
                                        </button>
                                    </div>
                                </div>

                            </Grid>
                        </Grid>
                        <div className="AddressBox">
                            <div className='Title'>
                                ADDRESS
                                    </div>
                            <div className='AddressText'>
                             
                            4F-411, 125, Wangsimni-ro,<br />
                            Seongdong-gu,<br />
                            Seoul, Republic of Korea
                                    </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Contact;