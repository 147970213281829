import React from "react";
import "./style/session9.scss";
import img1 from "../../../../_asset/images/severkit/f-logo.png";
import img2 from "../../../../_asset/images/severkit/inst-icon.png";
import img3 from "../../../../_asset/images/severkit/youtube-icon.png";

export default function Session9() {
    return (
        <div className="serverkit_session9">
            <div className="sk_main">
                <div className="sk_logo">
                    <img src={img1} alt="" />
                </div>
                <div className="sk_middle">
                    <div className="sk_top">
                        4F-411, 125, Wangsimni-ro, Seongdong-gu, Seoul, Republic
                        of Korea uix.hello@gmail.com
                    </div>
                    <div className="sk_bottom">
                        Copyrightⓒ UIX All rights reserved.
                    </div>
                </div>
                <div className="sk_social">
                    <div className="sk_item">
                        <img src={img2} alt="" />
                        <span>Instagram</span>
                    </div>
                    <div className="sk_item">
                        <img src={img3} alt="" />
                        <span>Youtube</span>
                    </div>
                </div>
            </div>
        </div>
    );
}
