import React from 'react'
import img from "../../../../_asset/images/topzone/01-m-img@3x.png"
import "./styles.scss"
function Section2() {
  return (
      <div className='section2'>
          <div className='section2-container'>
          <div className='content'>
              <div className='content-title'>
                  <div className='blue-text'>탑존과 유아엑스의 </div>
                  <div className='blue-text'>구독플랫폼 영업으로</div>
                  <div>안정적인 고수익을</div>
                  <div>창출해 보세요.</div>
                      
              </div>
              <div className='content-detail'>
                  유아이엑스는 오랜 브랜딩과 개발경력을 가진 전문 <br />
                  에 이션시로써 탑존의 유능한 네트워크를 가진 <br />
                  인재분들 과 함께 일하고자 합니다. 제공되는 
              </div>
          </div>
          <div className='img'>
              <img src={img} alt="" />
              </div>
          </div>
    </div>
  )
}

export default Section2