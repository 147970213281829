import React, { useState, useEffect } from "react";
import "./style/Eelement.scss";
import styled from "styled-components";
import elementimg from "../../../../_asset/images/emart/tit_design_element.png";
import Icon from "../../../../_asset/images/emart/ico_element.png";
import fontimg from "../../../../_asset/images/emart/tit_font.png";
import fonttxt from "../../../../_asset/images/emart/txt_font.png";
import text01 from "../../../../_asset/images/emart/txt_pointcolor1.png";
import text02 from "../../../../_asset/images/emart/txt_pointcolor2.png";
import text03 from "../../../../_asset/images/emart/txt_pointcolor3.png";
import colorimg from "../../../../_asset/images/emart/tit_color.png";
import icon01 from "../../../../_asset/images/emart/img_element1.png";
import icon02 from "../../../../_asset/images/emart/img_element2.png";
import icon03 from "../../../../_asset/images/emart/img_element3.png";
import icon04 from "../../../../_asset/images/emart/img_element4.png";
import icon05 from "../../../../_asset/images/emart/img_element5.png";
import icon06 from "../../../../_asset/images/emart/img_element6.png";
import icon07 from "../../../../_asset/images/emart/img_element7.png";
import icon08 from "../../../../_asset/images/emart/img_element8.png";
import icon09 from "../../../../_asset/images/emart/img_element9.png";
import icon10 from "../../../../_asset/images/emart/img_element10.png";
import icon11 from "../../../../_asset/images/emart/img_element11.png";
import icon12 from "../../../../_asset/images/emart/img_element12.png";

const list = [
  {
    icons: icon01,
  },
  {
    icons: icon02,
  },
  {
    icons: icon03,
  },
  {
    icons: icon04,
  },
  {
    icons: icon05,
  },
  {
    icons: icon06,
  },
  {
    icons: icon07,
  },
  {
    icons: icon08,
  },
  {
    icons: icon09,
  },
  {
    icons: icon10,
  },
  {
    icons: icon11,
  },
  {
    icons: icon12,
  },
];

const Title = styled.div`
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 1s linear;
`;

const IconBox = styled.div`
  text-align: center;
  display: block;
  overflow: hidden;
  height: 157px;
  margin-top: 146px;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 1s linear;
`;

const ListIcons = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  margin: 112px 0 0 18px;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1.2s linear;
  transition: transform 1.2s linear;
`;

const Color = styled.div`
  overflow: hidden;
  margin-top: 200px;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1.4s linear;
  transition: transform 1.4s linear;
`;

const Font = styled.div`
  overflow: hidden;
  margin-top: 211px;
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1.8s linear;
  transition: transform 1.8s linear;
`;

const Eelement = ({ pageY }) => {
  const [trigger, setTrigger] = useState(false);
  useEffect(() => {
    if (pageY >= 31000) {
      setTrigger(true);
    }
  });
  return (
    <div className="Eelementcontainer">
      <div className="con_bx">
        <Title trigger={trigger}>
          <div className="txt_img">
            <img src={elementimg} alt="element_tit_image" />
          </div>
          <div className="txt">
            아이콘스타일, 컬러, 폰트는 고객이 서비스에서 자연스럽게 접하는
            요소입니다. <br></br>
            요소들을 정의하고 일관되게 사용하여 이마트다움을 전달합니다.
          </div>
        </Title>
        <IconBox trigger={trigger}>
          <img src={Icon} alt="element_icon" />
        </IconBox>
        <ListIcons trigger={trigger}>
          {list.map((item, index) => {
            return (
              <div className="icon_bx" key={index}>
                <img src={item.icons} alt="icon_image" />
              </div>
            );
          })}
        </ListIcons>
        <Color trigger={trigger}>
          <div className="color_tit">
            <img src={colorimg} alt="colo_tit" />
          </div>
          <ul className="list_color">
            <li className="list">
              <div className="circle1"></div>
              <div className="point_col">
                <img src={text01} alt="color_text" />
              </div>
            </li>
            <li className="list">
              <div className="circle2"></div>
              <div className="point_col">
                <img src={text02} alt="color_text" />
              </div>
            </li>
            <li className="list02">
              <div className="circle3"></div>
              <div className="point_col">
                <img src={text03} alt="color_text" />
              </div>
            </li>
          </ul>
        </Color>
        <Font trigger={trigger}>
          <div className="font_tit">
            <img src={fontimg} alt="font_tit" />
          </div>
          <div className="font_txt">
            <img src={fonttxt} alt="gont_txt" />
          </div>
        </Font>
      </div>
    </div>
  );
};

export default Eelement;
