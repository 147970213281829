import React from "react";
import "./style/Pcolor.scss";
import color from "../../../../_asset/images/Prorenata/img_prn17.png";

const Pcolor = () => {
  return (
    <div className="Pcolor_container">
      <div className="Pcolor_cont_1">
        <div className="Pcolor_txt_bx">
          <div className="Pcolor_tit">Color Palette</div>
          <div className="Pcolor_txt">
            프로레나타의 브랜드 컬러는 모노톤을 중심으로 커뮤니케이션하여
            바이오슈티컬 브랜드의 진정성과 신뢰성을 전달합니다.<br></br>
            또한, 각 라인업의 특성을 반영한 컬러를 브랜드 전반에 적극적으로
            활용합니다.
          </div>
        </div>
      </div>
      <div className="Pcolor_cont_2">
        <div className="Pcolor_img_Wrap">
          <img src={color} alt="Pcolor_image" />
        </div>
      </div>
    </div>
  );
};

export default Pcolor;
