import React, { Component } from "react";
// import covermv from "../../../../_asset/images/29TV/PlusXCreative Partner.mp4";
import "./style/cover.scss";

class cover extends Component {
  render() {
    return (
      <div className="covercontainer">
        <div className="cover_textbox">
          <h1>29TV</h1>
          <div className="mv_Wrap">
            {/* <video id="cover_video" loop autoPlay>
              <source src={covermv} type="video/mp4" />
            </video> */}
          </div>
          <p className="desc">
            Guide to better choice. with better moment. <br />
            29CM UI/UX Service consulting project. <br />
            New experience - Moment
          </p>
        </div>
        <div className="copy01">© 2020</div>
        <p className="copy02">
          00:29
          <br />
          29:00
        </p>
        <p className="copy03">Plus X Creative Partner</p>
      </div>
    );
  }
}

export default cover;
