import React from 'react'
import './Square.scss'
import { Flipped } from 'react-flip-toolkit';
import NextArrow from "../../../../_asset/images/mainback/work-next-arrow-icon.svg";

export default function Square({ toggleFullScreen, item: e, }) {
    return (
        <div>
            <Flipped flipId="square">
                <div className="square" onClick={toggleFullScreen}>
                    <div className="work__item bx active visible">
                        <a className="item__link">
                            <div className="item__frame">
                                <div
                                    className="item__dimd"
                                    style={{
                                        // backgroundColor: "rgb(255, 64, 1)",
                                        animation:
                                            "360ms cubic-bezier(0.215, 0.61, 0.355, 1) 50ms 1 normal both running slideOutDown",
                                    }}
                                >
                                    <img src={e.img} alt="thumbnail" />
                                </div>
                                <div className="hidden-container">
                                    <div className="hidden-title">
                                        <div>{e.title}</div>
                                        <div>{e.category}</div>
                                    </div>
                                    {/* <div className="NextArrowBox"></div> */}
                                    <img className='NextArrow' src={NextArrow} alt='NextArrow' />
                                </div>
                            </div>
                        </a>
                    </div>

                </div>
            </Flipped>
        </div>
    )
}
