import React, { Component } from 'react';
import "./AboutBot.scss"
import BotFirstImg from '../../../_asset/images/mainback/about-img-6@2x.png'
import LeftImg from '../../../_asset/images/mainback/about-img-7@2x.png'
import RightImg from '../../../_asset/images/mainback/about-img-8@2x.png'
import shape from '../../../_asset/images/mainback/adout-shape-img.svg'
import LastLeftBox from '../../../_asset/images/mainback/about-img-9@2x.png'
import LastMiddleBox from '../../../_asset/images/mainback/about-img-10@2x.png'
import LastRightBox from '../../../_asset/images/mainback/about-img-11@2x.png'
import ContentsSlider from '../ContentsSlider/ContentsSlider';

class AboutBot extends Component {
    render() {
        return (
            <div className="AboutBotContainer">
                <div className="FirstImg">
                    <img className='BotFirstImg' src={BotFirstImg} alt='BotFirstImg'/>
                </div>
                <div className="AboutBotMain">
                    <div className="TopBox" id="STORY">
                        <div className="TextBox">
                            <div className='Value'>03 / third Value.</div>
                            <div className='Title'>Story</div>
                            <div className='SubText'>
                                ALL designs have a story. if there is a reason for anything without a<br/>
                                design, there is a story that causes that reason. Through that story,<br/>
                                we sometimes come up with creative elements.
                            </div>
                        </div>
                        <div className='ImgBox'>
                            <div className='LeftImgBox'>
                                <img src={LeftImg} alt='이미지'/>
                                <div className='ImgText'>DETAIL VIEW. CONTACT</div>
                            </div>
                            <div className='RightImgBox'>
                                <div className='ShapeBox'>
                                    <img className='shape' src={shape} alt='shape'/>
                                </div>
                                <img src={RightImg} alt='이미지'/>
                                <div className='ImgText'>DETAIL VIEW. CONTACT</div>
                            </div>
                        </div>
                    </div>
                    <div className="BottomBox" id="CREATIVITY">
                        <div className="TextBoxMain">
                            <div className='FlexBox'/>
                            <div className="TextBox FlexBox">
                                <div className='Value'>05 / Five Value.</div>
                                <div className='Title'>Creativity</div>
                                <div className='LastSubText'>
                                    I think creativity is the driving force for people to<br/>
                                    grow. That is why people always think about it.<br/>
                                    UIX understands and empathizes with customers'<br/>
                                    wishes and divides creative ideas. 
                                </div>
                            </div>
                        </div>
                        <div className="ContentsSilder">
                            <ContentsSlider/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AboutBot;