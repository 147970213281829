import React, { Component } from 'react';
import "./ContentsSlider.scss"
import DisplayData from "../../../Common/Id/WorksId/index"
import Flickity from 'react-flickity-component';


class ContentsSlider extends Component {
    state = {
        tickerSpeed: 0.5,
        slideshowEl: document.querySelector(".js-slideshow"),
        isCateClicked: false,
        isPause: false,
    };

    flickityOptions = {
        prevNextButtons: false,
        pageDots: false,
        draggable: true,
        selectedAttraction: 0.015,
        friction: 0.25,
        initialIndex: 5,
        autoPlay: true,
        wrapAround: true,
    };
    componentDidMount() {
        this.flkty.on('dragEnd', (event, pointer, cellElement, cellIndex) => { this.flkty.playPlayer() });
    }
    render() {
        return (
            <div className="ContentsSliderContainer">
                <div className='ContentsSliderMain'>
                    <Flickity
                        flickityRef={(c) => (this.flkty = c)}
                        className={"slideshow js-slideshow"} // default ''
                        options={this.flickityOptions}
                    >
                        {DisplayData.list.map(item => {
                            return (
                                <div className="ContentsListBox">
                                    <div className="ListImgBox">
                                        <img className="ListImg" src={item.img} />
                                    </div>
                                    <div className="ListTextBox">
                                        <div className="ListTitle">{item.name}</div>
                                        <div className='ListText'>{item.text}</div>
                                    </div>
                                </div>
                            )
                        })
                        }
                    </Flickity>
                </div>
            </div>
        );
    }
}

export default ContentsSlider;