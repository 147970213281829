import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "./style/Eevent.scss";
import eventimg from "../../../../_asset/images/emart/tit_event.png";
import eventimage01 from "../../../../_asset/images/emart/img_event1.png";
import eventimage02 from "../../../../_asset/images/emart/roulette.gif";
import eventimage03 from "../../../../_asset/images/emart/img_event3.png";
import eventimage04 from "../../../../_asset/images/emart/img_event4.png";
import eventimage05 from "../../../../_asset/images/emart/img_event5.png";

const Title = styled.div`
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1.3s linear;
  transition: transform 1.3s linear;
`;

const EventListOne = styled.div`
  margin-top: 400px;
  display: inline-block;
  margin-right: 59px;
  vertical-align: top;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1.8s linear;
  transition: transform 1.8s linear;
  > img {
    box-shadow: 40px 40px 70px 20px rgba(34, 34, 34, 0.08);
  }
`;

const EventListTwo = styled.div`
  margin-top: 200px;
  border-radius: 40px;
  display: inline-block;
  margin-right: 59px;
  vertical-align: top;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1.4s linear;
  transition: transform 1.4s linear;
  > img {
    box-shadow: 40px 40px 70px 20px rgba(34, 34, 34, 0.08);
  }
`;

const EventListThree = styled.div`
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 1s linear;
`;

const EventImage = styled.div`
  box-shadow: 40px 40px 70px 20px rgba(34, 34, 34, 0.08);
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 3s linear;
  transition: transform 3s linear;
`;

const EventImageTwo = styled.div`
  box-shadow: 40px 40px 70px 20px rgba(34, 34, 34, 0.08);
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 3s linear;
  transition: transform 3s linear;
`;

const Eevent = ({ pageY }) => {
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    if (pageY >= 23000) {
      setTrigger(true);
    }
  });
  return (
    <div className="Eeventcontainer">
      <Title trigger={trigger}>
        <img src={eventimg} alt="event_tit" />
        <div className="sub_text">
          이마트의 이벤트영역에서는 출석체크와 E-stamp 및 다양한<br></br>
          이벤트 컨텐츠를 제공하고 있습니다. 심플함을 컨셉으로<br></br>
          사용자가 즐겁게 경험할 수 있도록 디자인 하였습니다.
        </div>
      </Title>
      <div className="event_screen">
        <ul className="list_box">
          <EventListOne trigger={trigger}>
            <img src={eventimage01} alt="event_image01" />
          </EventListOne>
          <EventListTwo trigger={trigger}>
            <div className="event_Wrap">
              <img src={eventimage02} alt="event_image02" />
            </div>
            <EventImage trigger={trigger}>
              <img src={eventimage03} alt="event_image03" />
            </EventImage>
          </EventListTwo>
          <EventListThree trigger={trigger}>
            <div className="event_Wrap02">
              <img src={eventimage04} alt="event_image04" />
            </div>
            <EventImageTwo trigger={trigger}>
              <img src={eventimage05} alt="event_image05" />
            </EventImageTwo>
          </EventListThree>
        </ul>
      </div>
    </div>
  );
};

export default Eevent;
