import React, { Component } from 'react';
import HeaderMain from '../../Common/HeaderMain/HeaderMain';
import Footer from '../../Common/FooterMain/FooterMain';
import Grid from '../../Experience/ExMain/Grid/Grid';

class RouterExperience extends Component {
    render() {
        return (
            <div>
                <HeaderMain></HeaderMain>
                <Grid></Grid>
                <Footer/>
            </div>
        );
    }
}

export default RouterExperience;