import React, { useState } from 'react';
import './BackToTop.scss';
import arrowTop from '../../_asset/images/toast/btn-scrollon.png'
import { useEffect } from 'react';

function BackToTop() {
  const [visible, setVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const toggleVisible = () => {
    const scrolled = window.pageYOffset;
    // Chiều cao tổng thể của tài liệu
    const docHeight = document.documentElement.offsetHeight;
    // Chiều cao của cửa sổ trình duyệt
    const windowHeight = window.innerHeight;


    if (docHeight - scrolled <= windowHeight) {
      setVisible(true);
    } else  {
      setVisible(false);
    }
  };

  // window.addEventListener('scroll', toggleVisible);
  useEffect(() => {
    window.addEventListener('scroll', toggleVisible);
    return () => {
      window.removeEventListener('scroll', toggleVisible);
    };
  }, []);
  return (
    <div
      className={`${visible ? 'back-to-top active' : 'back-to-top'}`}
      onClick={scrollToTop}
    >
      <img src={arrowTop} alt="back to top" />
    </div>
  );
}

export default BackToTop;
