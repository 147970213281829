import React from "react";
import "./style/Gcover.scss";
import styled from "styled-components";
import coverimg from "../../../../_asset/images/g9/img_cover01.png";
import Gcoverimage from "../../../../_asset/images/g9/img_cover02.png";
import Gcovertxt from "../../../../_asset/images/g9/img_txt_cover.png";

const Gcover = () => {
  return (
    <div className="Gcovercontainer">
      <div className="top_logo">
        <div className="logo_Wrap">
          <img src={coverimg} alt="Gcover_image" />
        </div>
      </div>
      <div className="GcoverImage">
        <img src={Gcoverimage} alt="Gcover_image" />
      </div>
      <div className="Gcover_center">
        <div className="inner">
          <div className="tit_pr">
            <img src={Gcovertxt} alt="Gcover_tit" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gcover;
