import React, { Component } from 'react';
import './FooterMainWhite.scss';
import Top from '../../_asset/images/WhiteBack/goup-2-icon.svg'

class FooterMain extends Component {

    state = {
        display: '0'
    }

    componentDidMount() {

        // 스크롤에 따른 Scroll To Top Btn 표시 or 미표시
        if (typeof window !== "undefined") {
            window.onscroll = () => {
                let currentScrollPos = window.pageYOffset;
                let maxScroll = document.body.scrollHeight - window.innerHeight;
                // console.log(maxScroll)
                if (currentScrollPos > 300) {
                    this.setState({ display: "block" })
                } else {
                    this.setState({ display: "none" })
                }
            }
        }

    }



    scrollToTop = () => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' })
    }

    render() {
        const FooterClassName = this.props.isFooterBlack ? "footerMainContainer black" : "footerMainContainer";
        return (
            <div className={FooterClassName}>
                <div className='FooterMain'>
                    <div className='FooterLeftBox'>
                        <div className='linksBox'>
                            <ul className='links'>
                                <li className='links_item'>
                                    <a href='https://www.behance.net' target='_blank' className='hv-underline'>
                                        Behance
                                    </a>
                                </li>
                                <li className='links_item'>
                                    <a href='https://www.facebook.com' target='_blank' className='hv-underline'>
                                        Facebook
                                    </a>
                                </li>
                                <li className='links_item'>
                                    <a href='https://www.instagram.com' target='_blank' className='hv-underline'>
                                        Instagram
                                    </a>
                                </li>
                                <li className='links_item'>
                                    <a href='https://www.vimeo.com' target='_blank' className='hv-underline'>
                                        Vimeo
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className='copyright'>
                            ⓒ UIX. All rights reserved.
                        </div>
                    </div>

                    <button className='FooterRightBox' style={{ display: `${this.state.display}` }} onClick={() => this.scrollToTop()}>
                        <img src={Top} alt='위로 이동' />
                    </button>
                </div>
            </div>
        );
    }
}

export default FooterMain;
