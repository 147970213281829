import React from "react";
import "./style/session3.scss";
import img from "../../../../_asset/images/severkit/03-img-all.png";

export default function Session3() {
    return (
        <div className="serverkit_session3">
            <div className="sk_main">
                <div className="sk_top">
                    우리 서비스의 백엔드는 <br></br>잘 개발되고 있을까요?
                </div>
                <div className="sk_img">
                    <img src={img} alt="" />
                </div>
                <div className="sk_bottom">
                    백엔드는 <span>눈에 안보이기</span> 때문에 문제는{" "}
                    <span>나중에 발견됩니다.</span>
                </div>
            </div>
        </div>
    );
}
