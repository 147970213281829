import React, { useState, useEffect } from "react";
import "./style/Ename.scss";
import styled from "styled-components";
import pluslogo from "../../../../_asset/images/emart/img_logo_plusx.png";
import plustxt from "../../../../_asset/images/emart/img_name_plusx.png";

const Name = styled.div`
  display: flex;
  overflow: hidden;
  width: 1200px;
  margin: 0 auto;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 80px, 0px)"};
  transition: opacity 2s linear;
  transition: transform 2s linear;
`;
const Ename = ({ pageY }) => {
  const [trigger, setTrigger] = useState(false);
  useEffect(() => {
    if (pageY >= 32100) {
      setTrigger(true);
    }
  });
  return (
    <div className="Enamecontainer">
      <Name trigger={trigger}>
        <div className="name_img">
          <img src={pluslogo} alt="name_logo" />
        </div>
        <div className="name_txt">
          <img src={plustxt} alt="name_txt" />
        </div>
      </Name>
      <div className="name_bg"></div>
    </div>
  );
};

export default Ename;
