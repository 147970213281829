import React, { useState, useEffect } from "react";
import "./style/Pfeature.scss";

const feature = [
  {
    number: "1",
    numbertit: "Natural\n Bedrock Water",
    title: "무공해 해양심층암반수",
    text:
      "해양심층수와 천연암반수가 만나는\n 희귀 지형에서 자연 정제되어 극소량\n 생성되는 고도의 자연 정제가 완료되어\n 오염이 불가능한 100% 무공해\n 해양심층암반수만을 사용합니다.",
  },
  {
    number: "2",
    numbertit: "Skin-friendly\n Prescribe",
    title: "피부 친화적 처방",
    text:
      "피부자극을 유발할 수 있는 유해성분을\n 최대한 배제하고, 꼭 필요한 최소성분만을\n 담아낸다. 무첨가 원칙에 따라 피부자극을\n 최소화하고, 식약처 및 EU지정 알러젠\n 의심 향료는 모두 배제합니다.",
  },
  {
    number: "3",
    numbertit: "Own\n Laboratory",
    title: "자체 보유 연구소",
    text:
      "기존의 화장품 브랜드가 시도하지 않은\n 임상을 까다롭게 진행하여 제품 효능을\n 증명한다. 자체 연구소와 공장에서\n 혁신적인 원료 개발 및 유효성분 전달\n 기술 특허를 지속적으로 연구합니다.",
  },
  {
    number: "4",
    numbertit: "Core Ingredient\n & Technology",
    title: "핵심성분과 기술",
    text:
      "피부 재생에 뛰어난 천년초, 천연 유래\n 세정 성분의 마이셀 기술 등 좋은 원료와\n 기술을 지속적으로 개발합니다. 또한,\n 유효성분이 가장 잘 스며들 수 있는\n 효과적인 방식을 끊임없이 연구합니다.",
  },
];

const Pfeature = () => {
  return (
    <div className="Pfeature_container">
      <div className="Pfeature_cont">
        <div className="Pfeature_tit_box">
          <div className="tit">Brand Feature</div>
          <div className="subtit">
            프로레나타의 브랜드 핵심 가치 도출을 위해 <br></br>
            브랜드의 주요 특성을 정의하였습니다.
          </div>
        </div>
        <div className="Pfeature_List">
          {feature.map((i, index) => {
            return (
              <div className="List_Box" key={index}>
                <div className="List_Number">{i.number}</div>
                <div className="List_NumberTit">
                  {i.numbertit.split("\n").map((e) => (
                    <span>
                      {e}
                      <br />
                    </span>
                  ))}
                </div>
                <div className="List_Title">{i.title}</div>
                <div className="List_Text">
                  {i.text.split("\n").map((e) => (
                    <span>
                      {e}
                      <br />
                    </span>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Pfeature;
