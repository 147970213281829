import React, { Component } from 'react';
import HeaderMain from '../../Common/HeaderMain/HeaderMain';
import Footer from '../../Common/FooterMain/FooterMain';
import Grid from '../../Experience/ExMain/Grid/Grid';
import ToastCanvas from '../../Home/ToastCanvas/ToastCanvas.js';

class RouterToastCanvas extends Component {
    render() {
        return (
            <div>
                <ToastCanvas/>
            </div>
        );
    }
}

export default RouterToastCanvas;