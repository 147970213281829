import React from "react";
import "./style/Pphoto.scss";
import photo01 from "../../../../_asset/images/Prorenata/img_prn49.png";
import photo02 from "../../../../_asset/images/Prorenata/img_prn50.png";

const Pphoto = () => {
  return (
    <div className="Pphoto_container">
      <div className="Pphoto_cont_1">
        <img src={photo01} alt="Photo_image01" />
      </div>
      <div className="Pphoto_cont_2">
        <img src={photo02} alt="Photo_image02" />
      </div>
      <div className="Pphoto_tit_bx">
        <div className="tit">Photos by Danny Rothschild</div>
      </div>
    </div>
  );
};

export default Pphoto;
