// import ProjectData from '../../Data/WorksData/index'
// import fullScreenBetterSamsung from '../../Data/WorksData/ProjectData/BetterSamsung/Img/betterSamsung.jpeg'
// import fullScreenTimingDark from '../../Data/WorksData/ProjectData/TimingDark/Img/003.jpg'
// import fullScreenTiming from '../../Data/WorksData/ProjectData/Timing/Img/004.jpg'
// import fullScreenMilkShake from '../../Data/WorksData/ProjectData/MilkShake/Img/009.jpg'
// import fullScreenVictor from '../../Data/WorksData/ProjectData/Victor/Img/001.jpg'
// import fullScreenCircle from '../../Data/WorksData/ProjectData/Circle/Img/006.jpg'
// import fullScreenLyn from '../../Data/WorksData/ProjectData/Lyn/Img/002.jpg'
// import fullScreenSkylife from '../../Data/WorksData/ProjectData/Skylife/Img/007.jpg'
// import fullScreenDaisy from '../../Data/WorksData/ProjectData/Daisy/Img/005.jpg'
// import fullScreenHappened from '../../Data/WorksData/ProjectData/Happened/Img/011.jpg'
// import fullScreenHyundaiRima from '../../Data/WorksData/ProjectData/Hyundai_rima/Img/010.jpg'
// import fullScreenPriest from '../../Data/WorksData/ProjectData/Priest/Img/008.jpg'
// import tv29 from "../../Data/WorksData/ProjectData/Image/1@2x.png";
// import BetterSamsung from "../../Data/WorksData/ProjectData/Image/BetterSamsung.jpg";
// import TimingDark from "../../Data/WorksData/ProjectData/Image/TimingDark.jpg";
// import Timing from "../../Data/WorksData/ProjectData/Image/Timing.jpg";
// import MilkShake from "../../Data/WorksData/ProjectData/Image/MilkShake.jpg";
// import Victor from "../../Data/WorksData/ProjectData/Image/Victor.jpg";
// import Circle from "../../Data/WorksData/ProjectData/Image/Circle.jpg";
// import Lyn from "../../Data/WorksData/ProjectData/Image/Lyn.jpg";
// import Skylife from "../../Data/WorksData/ProjectData/Image/Skylife.jpg";
// import Daisy from "../../Data/WorksData/ProjectData/Image/Daisy.jpg";
// import Happened from "../../Data/WorksData/ProjectData/Image/Happened.jpg";
// import Hyundai_rima from "../../Data/WorksData/ProjectData/Image/Hyundai_rima.jpg";
// import Priest from "../../Data/WorksData/ProjectData/Image/Priest.jpg";
//////////////////////////////////스포츠 몬스터 이미지////////////////////////////////////////////////
import SportsMonsterThum from '../../Data/WorksData/ProjectData/SportsMonster/Img/thumnailimg.png';
import SportsMonster1Img from '../../Data/WorksData/ProjectData/SportsMonster/Img/mainimg-01.png';
import SportsMonster2Img from '../../Data/WorksData/ProjectData/SportsMonster/Img/mainimg-02.png';
import SportsMonster3Img from '../../Data/WorksData/ProjectData/SportsMonster/Img/mainimg-03.png';
// import SportsMonster4Img from '../../Data/WorksData/ProjectData/SportsMonster/Img/mainimg-04.png';
// import SportsMonster5Img from '../../Data/WorksData/ProjectData/SportsMonster/Img/mainimg-05.png';
// import SportsMonster6Img from '../../Data/WorksData/ProjectData/SportsMonster/Img/mainimg-06.png';
// import SportsMonster7Img from '../../Data/WorksData/ProjectData/SportsMonster/Img/mainimg-07.png';
// import SportsMonster8Img from '../../Data/WorksData/ProjectData/SportsMonster/Img/mainimg-08.png';
import SportsMonster9Img from '../../Data/WorksData/ProjectData/SportsMonster/Img/mainimg-09.png';
import SportsMonster10Img from '../../Data/WorksData/ProjectData/SportsMonster/Img/mainimg-10.png';
// import SportsMonster11Img from '../../Data/WorksData/ProjectData/SportsMonster/Img/mainimg-11.png';
import SportsMonster12Img from '../../Data/WorksData/ProjectData/SportsMonster/Img/mainimg-12.png';
// import SportsMonster13Img from '../../Data/WorksData/ProjectData/SportsMonster/Img/mainimg-13.png';
import SportsMonster14Img from '../../Data/WorksData/ProjectData/SportsMonster/Img/mainimg-14.png';
// import SportsMonster15Img from '../../Data/WorksData/ProjectData/SportsMonster/Img/mainimg-15.png';
// import SportsMonster16Img from '../../Data/WorksData/ProjectData/SportsMonster/Img/mainimg-16.png';
// import SportsMonster17Img from '../../Data/WorksData/ProjectData/SportsMonster/Img/mainimg-17.png';
// import SportsMonster18Img from '../../Data/WorksData/ProjectData/SportsMonster/Img/mainimg-18.png';
/////////////////////////////////엘지 디스플레이 이미지/////////////////////////////////////////////////
import LGDisplayThum from '../../Data/WorksData/ProjectData/LGDisplay/Img/thumnailimg.png';
import LGDisplay1Img from '../../Data/WorksData/ProjectData/LGDisplay/Img/mainimg-01.png';
import LGDisplay2Img from '../../Data/WorksData/ProjectData/LGDisplay/Img/mainimg-02.png';
// import LGDisplay3Img from '../../Data/WorksData/ProjectData/LGDisplay/Img/mainimg-03.png';
import LGDisplay4Img from '../../Data/WorksData/ProjectData/LGDisplay/Img/mainimg-04.png';
// import LGDisplay5Img from '../../Data/WorksData/ProjectData/LGDisplay/Img/mainimg-05.png';
// import LGDisplay6Img from '../../Data/WorksData/ProjectData/LGDisplay/Img/mainimg-06.png';
import LGDisplay7Img from '../../Data/WorksData/ProjectData/LGDisplay/Img/mainimg-07.png';
// import LGDisplay8Img from '../../Data/WorksData/ProjectData/LGDisplay/Img/mainimg-08.png';
// import LGDisplay9Img from '../../Data/WorksData/ProjectData/LGDisplay/Img/mainimg-09.png';
// import LGDisplay10Img from '../../Data/WorksData/ProjectData/LGDisplay/Img/mainimg-10.png';
import LGDisplay11Img from '../../Data/WorksData/ProjectData/LGDisplay/Img/mainimg-11.png';
// import LGDisplay12Img from '../../Data/WorksData/ProjectData/LGDisplay/Img/mainimg-12.png';
// import LGDisplay13Img from '../../Data/WorksData/ProjectData/LGDisplay/Img/mainimg-13.png';
/////////////////////////////////의왕레일파크 이미지////////////////////////////////////////////////////
import RailParkThum from '../../Data/WorksData/ProjectData/RailPark/Img/thumnailimg.png';
import RailPark1Img from '../../Data/WorksData/ProjectData/RailPark/Img/mainimg-01.png';
import RailPark2Img from '../../Data/WorksData/ProjectData/RailPark/Img/mainimg-02.png';
import RailPark3Img from '../../Data/WorksData/ProjectData/RailPark/Img/mainimg-03.png';
// import RailPark4Img from '../../Data/WorksData/ProjectData/RailPark/Img/mainimg-04.png';
import RailPark5Img from '../../Data/WorksData/ProjectData/RailPark/Img/mainimg-05.png';
import RailPark6Img from '../../Data/WorksData/ProjectData/RailPark/Img/mainimg-06.png';
// import RailPark7Img from '../../Data/WorksData/ProjectData/RailPark/Img/mainimg-07.png';
import RailPark8Img from '../../Data/WorksData/ProjectData/RailPark/Img/mainimg-08.png';
// import RailPark9Img from '../../Data/WorksData/ProjectData/RailPark/Img/mainimg-09.png';
// import RailPark10Img from '../../Data/WorksData/ProjectData/RailPark/Img/mainimg-10.png';
/////////////////////////////////삼성SDS 이미지////////////////////////////////////////////////////
import SamsungSdsThum from '../../Data/WorksData/ProjectData/SamsungSds/Img/thumnailimg.png';
import SamsungSds1Img from '../../Data/WorksData/ProjectData/SamsungSds/Img/mainimg-01.png';
import SamsungSds2Img from '../../Data/WorksData/ProjectData/SamsungSds/Img/mainimg-02.png';
import SamsungSds3Img from '../../Data/WorksData/ProjectData/SamsungSds/Img/mainimg-03.png';
import SamsungSds4Img from '../../Data/WorksData/ProjectData/SamsungSds/Img/mainimg-04.png';
import SamsungSds5Img from '../../Data/WorksData/ProjectData/SamsungSds/Img/mainimg-05.png';
import SamsungSds6Img from '../../Data/WorksData/ProjectData/SamsungSds/Img/mainimg-06.png';
import SamsungSds7Img from '../../Data/WorksData/ProjectData/SamsungSds/Img/mainimg-07.png';
import SamsungSds8Img from '../../Data/WorksData/ProjectData/SamsungSds/Img/mainimg-08.png';
import SamsungSds9Img from '../../Data/WorksData/ProjectData/SamsungSds/Img/mainimg-09.png';
import SamsungSds10Img from '../../Data/WorksData/ProjectData/SamsungSds/Img/mainimg-10.png';
import SamsungSds11Img from '../../Data/WorksData/ProjectData/SamsungSds/Img/mainimg-11.png';
/////////////////////////////////Galleria//////////////////////////////////////////////////////////
import GalleriaThum from '../../Data/WorksData/ProjectData/Galleria/Img/thumnailimg.png';
import Galleria1Img from '../../Data/WorksData/ProjectData/Galleria/Img/mainimg-01.png';
import Galleria2Img from '../../Data/WorksData/ProjectData/Galleria/Img/mainimg-02.png';
import Galleria3Img from '../../Data/WorksData/ProjectData/Galleria/Img/mainimg-03.png';
import Galleria4Img from '../../Data/WorksData/ProjectData/Galleria/Img/mainimg-04.png';
import Galleria5Img from '../../Data/WorksData/ProjectData/Galleria/Img/mainimg-05.png';
import Galleria6Img from '../../Data/WorksData/ProjectData/Galleria/Img/mainimg-06.png';
/////////////////////////////////SkyPark//////////////////////////////////////////////////////////
import SkyParkThum from '../../Data/WorksData/ProjectData/SkyPark/Img/thumnailimg.png';
import SkyPark1Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-01.png';
import SkyPark2Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-02.png';
import SkyPark3Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-03.png';
// import SkyPark4Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-04.png';
import SkyPark5Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-05.png';
import SkyPark6Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-06.png';
import SkyPark7Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-07.png';
// import SkyPark8Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-08.png';
import SkyPark9Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-09.png';
import SkyPark10Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-10.png';
// import SkyPark11Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-11.png';
// import SkyPark12Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-12.png';
import SkyPark13Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-13.png';
// import SkyPark14Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-14.png';
// import SkyPark15Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-15.png';
// import SkyPark16Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-16.png';
// import SkyPark17Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-17.png';
// import SkyPark18Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-18.png';
// import SkyPark19Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-19.png';
// import SkyPark20Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-20.png';
// import SkyPark21Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-21.png';
// import SkyPark22Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-22.png';
// import SkyPark23Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-23.png';
// import SkyPark24Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-24.png';
// import SkyPark25Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-25.png';
// import SkyPark26Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-26.png';
import SkyPark27Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-27.png';
// import SkyPark28Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-28.png';
// import SkyPark29Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-29.png';
// import SkyPark30Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-30.png';
// import SkyPark31Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-31.png';
// import SkyPark32Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-32.png';
// import SkyPark33Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-33.png';
// import SkyPark34Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-34.png';
// import SkyPark35Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-35.png';
// import SkyPark36Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-36.png';
// import SkyPark37Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-37.png';
// import SkyPark38Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-38.png';
// import SkyPark39Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-39.png';
// import SkyPark40Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-40.png';
// import SkyPark41Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-41.png';
// import SkyPark42Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-42.png';
// import SkyPark43Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-43.png';
// import SkyPark44Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-44.png';
// import SkyPark45Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-45.png';
// import SkyPark46Img from '../../Data/WorksData/ProjectData/SkyPark/Img/mainimg-46.png';
/////////////////////////////////Jurassic//////////////////////////////////////////////////////////
import JurassicThum from '../../Data/WorksData/ProjectData/Jurassic/Img/thumnailimg.png';
import Jurassic1Img from '../../Data/WorksData/ProjectData/Jurassic/Img/mainimg-01.png';
import Jurassic2Img from '../../Data/WorksData/ProjectData/Jurassic/Img/mainimg-02.png';
// import Jurassic3Img from '../../Data/WorksData/ProjectData/Jurassic/Img/mainimg-03.png';
// import Jurassic4Img from '../../Data/WorksData/ProjectData/Jurassic/Img/mainimg-04.png';
// import Jurassic5Img from '../../Data/WorksData/ProjectData/Jurassic/Img/mainimg-05.png';
/////////////////////////////////KimMuseum//////////////////////////////////////////////////////////
import KimChungUpMuseumThum from '../../Data/WorksData/ProjectData/KimChungUpMuseum/Img/thumnailimg.png';
import KimChungUpMuseum1Img from '../../Data/WorksData/ProjectData/KimChungUpMuseum/Img/mainimg-01.png';
import KimChungUpMuseum2Img from '../../Data/WorksData/ProjectData/KimChungUpMuseum/Img/mainimg-02.png';
import KimChungUpMuseum3Img from '../../Data/WorksData/ProjectData/KimChungUpMuseum/Img/mainimg-03.png';
/////////////////////////////////Samsung SDS 3면영상//////////////////////////////////////////////////////////
import SamsungSds2Thum from '../../Data/WorksData/ProjectData/SamsungSds2/Img/thumnailimg.png';
import SamsungSds21Img from '../../Data/WorksData/ProjectData/SamsungSds2/Img/mainimg-01.png';
import SamsungSds22Img from '../../Data/WorksData/ProjectData/SamsungSds2/Img/mainimg-02.png';
import SamsungSds23Img from '../../Data/WorksData/ProjectData/SamsungSds2/Img/mainimg-03.png';
/////////////////////////////////중국 정저우 도서관//////////////////////////////////////////////////////////
import ChinaLibraryThum from '../../Data/WorksData/ProjectData/ChinaLibrary/Img/thumnailimg.png';
import ChinaLibrary1Img from '../../Data/WorksData/ProjectData/ChinaLibrary/Img/mainimg-01.png';
import ChinaLibrary2Img from '../../Data/WorksData/ProjectData/ChinaLibrary/Img/mainimg-02.png';
import ChinaLibrary3Img from '../../Data/WorksData/ProjectData/ChinaLibrary/Img/mainimg-03.png';
import ChinaLibrary4Img from '../../Data/WorksData/ProjectData/ChinaLibrary/Img/mainimg-04.png';
/////////////////////////////////종근당 HistoryMuseum//////////////////////////////////////////////////////////
import HistoryMuseumThum from '../../Data/WorksData/ProjectData/HistoryMuseum/Img/thumnailimg.png';
import HistoryMuseum1Img from '../../Data/WorksData/ProjectData/HistoryMuseum/Img/mainimg-01.png';
import HistoryMuseum2Img from '../../Data/WorksData/ProjectData/HistoryMuseum/Img/mainimg-02.png';
import HistoryMuseum3Img from '../../Data/WorksData/ProjectData/HistoryMuseum/Img/mainimg-03.png';
/////////////////////////////////카이스트 커뮤니티 라운지//////////////////////////////////////////////////////////
import KistThum from '../../Data/WorksData/ProjectData/Kist/Img/thumnailimg.png';
import Kist1Img from '../../Data/WorksData/ProjectData/Kist/Img/mainimg-01.png';
import Kist2Img from '../../Data/WorksData/ProjectData/Kist/Img/mainimg-02.png';
import Kist3Img from '../../Data/WorksData/ProjectData/Kist/Img/mainimg-03.png';
import Kist4Img from '../../Data/WorksData/ProjectData/Kist/Img/mainimg-04.png';
/////////////////////////////////동대문 플라자//////////////////////////////////////////////////////////
import DDPThum from '../../Data/WorksData/ProjectData/DDP/Img/thumnailimg.png';
import DDP1Img from '../../Data/WorksData/ProjectData/DDP/Img/mainimg-01.png';
import DDP2Img from '../../Data/WorksData/ProjectData/DDP/Img/mainimg-02.png';
import DDP3Img from '../../Data/WorksData/ProjectData/DDP/Img/mainimg-03.png';
/////////////////////////////////롯데월드 아쿠아리움//////////////////////////////////////////////////////////
import AquariumThum from '../../Data/WorksData/ProjectData/Aquarium/Img/thumnailimg.png';
import Aquarium1Img from '../../Data/WorksData/ProjectData/Aquarium/Img/mainimg-01.png';
import Aquarium2Img from '../../Data/WorksData/ProjectData/Aquarium/Img/mainimg-02.png';
import Aquarium3Img from '../../Data/WorksData/ProjectData/Aquarium/Img/mainimg-03.png';
import Aquarium4Img from '../../Data/WorksData/ProjectData/Aquarium/Img/mainimg-04.png';
import Aquarium5Img from '../../Data/WorksData/ProjectData/Aquarium/Img/mainimg-05.png';
/////////////////////////////////KimWall//////////////////////////////////////////////////////////
import KimChungUpWallThum from '../../Data/WorksData/ProjectData/KimChungUpWall/Img/thumnailimg.png';
import KimChungUpWall1Img from '../../Data/WorksData/ProjectData/KimChungUpWall/Img/mainimg-01.png';
import KimChungUpWall2Img from '../../Data/WorksData/ProjectData/KimChungUpWall/Img/mainimg-02.png';
import KimChungUpWall3Img from '../../Data/WorksData/ProjectData/KimChungUpWall/Img/mainimg-03.png';
import KimChungUpWall4Img from '../../Data/WorksData/ProjectData/KimChungUpWall/Img/mainimg-04.png';
/////////////////////////////////롯데월드 아쿠아리움//////////////////////////////////////////////////////////
import ComedyTownThum from '../../Data/WorksData/ProjectData/ComedyTown/Img/thumnailimg.png';
import ComedyTown1Img from '../../Data/WorksData/ProjectData/ComedyTown/Img/mainimg-01.png';
import ComedyTown2Img from '../../Data/WorksData/ProjectData/ComedyTown/Img/mainimg-02.png';

///////////////////////////////모션트렌드////////////////////////////////////////////
import MotionTrendThum from '../../Data/WorksData/ProjectData/MotionTrend/Img/thumnailimg.png';
import MotionTrend1Img from '../../Data/WorksData/ProjectData/MotionTrend/Img/mainimg-01.png';
import MotionTrend2Img from '../../Data/WorksData/ProjectData/MotionTrend/Img/mainimg-02.png';
import MotionTrend3Img from '../../Data/WorksData/ProjectData/MotionTrend/Img/mainimg-03.png';
import MotionTrend4Img from '../../Data/WorksData/ProjectData/MotionTrend/Img/mainimg-04.png';
import MotionTrend5Img from '../../Data/WorksData/ProjectData/MotionTrend/Img/mainimg-05.png';
import MotionTrend6Img from '../../Data/WorksData/ProjectData/MotionTrend/Img/mainimg-06.png';
import MotionTrend7Img from '../../Data/WorksData/ProjectData/MotionTrend/Img/mainimg-07.png';
import MotionTrend8Img from '../../Data/WorksData/ProjectData/MotionTrend/Img/mainimg-08.png';
import MotionTrend9Img from '../../Data/WorksData/ProjectData/MotionTrend/Img/mainimg-09.png';


///////////////////////////////프롱////////////////////////////////////////////
import ProngThum from '../../Data/WorksData/ProjectData/Prong/Img/thumnailimg.png';
import Prong1Img from '../../Data/WorksData/ProjectData/Prong/Img/mainimg-01.png';
import Prong2Img from '../../Data/WorksData/ProjectData/Prong/Img/mainimg-02.png';
import Prong3Img from '../../Data/WorksData/ProjectData/Prong/Img/mainimg-03.png';
import Prong4Img from '../../Data/WorksData/ProjectData/Prong/Img/mainimg-04.png';
import Prong5Img from '../../Data/WorksData/ProjectData/Prong/Img/mainimg-05.png';
import Prong6Img from '../../Data/WorksData/ProjectData/Prong/Img/mainimg-06.png';
import Prong7Img from '../../Data/WorksData/ProjectData/Prong/Img/mainimg-07.png';
import Prong8Img from '../../Data/WorksData/ProjectData/Prong/Img/mainimg-08.png';
import Prong9Img from '../../Data/WorksData/ProjectData/Prong/Img/mainimg-09.png';
import Prong10Img from '../../Data/WorksData/ProjectData/Prong/Img/mainimg-10.png';

///////////////////////////////레논////////////////////////////////////////////
import LennonThum from '../../Data/WorksData/ProjectData/Lennon/Img/thumnailimg.png';
import Lennon1Img from '../../Data/WorksData/ProjectData/Lennon/Img/mainimg-01.png';
import Lennon2Img from '../../Data/WorksData/ProjectData/Lennon/Img/mainimg-02.png';
import Lennon3Img from '../../Data/WorksData/ProjectData/Lennon/Img/mainimg-03.png';
import Lennon4Img from '../../Data/WorksData/ProjectData/Lennon/Img/mainimg-04.png';
import Lennon5Img from '../../Data/WorksData/ProjectData/Lennon/Img/mainimg-05.png';
import Lennon6Img from '../../Data/WorksData/ProjectData/Lennon/Img/mainimg-06.png';
import Lennon7Img from '../../Data/WorksData/ProjectData/Lennon/Img/mainimg-07.png';

///////////////////////////////더널리////////////////////////////////////////////
import TheNulliThum from '../../Data/WorksData/ProjectData/TheNulli/Img/thumnailimg.png';
import TheNulli1Img from '../../Data/WorksData/ProjectData/TheNulli/Img/mainimg-01.png';
import TheNulli2Img from '../../Data/WorksData/ProjectData/TheNulli/Img/mainimg-02.png';
import TheNulli3Img from '../../Data/WorksData/ProjectData/TheNulli/Img/mainimg-03.png';
import TheNulli4Img from '../../Data/WorksData/ProjectData/TheNulli/Img/mainimg-04.png';
import TheNulli5Img from '../../Data/WorksData/ProjectData/TheNulli/Img/mainimg-05.png';
import TheNulli6Img from '../../Data/WorksData/ProjectData/TheNulli/Img/mainimg-06.png';
import TheNulli7Img from '../../Data/WorksData/ProjectData/TheNulli/Img/mainimg-07.png';
import TheNulli8Img from '../../Data/WorksData/ProjectData/TheNulli/Img/mainimg-08.png';
import TheNulli9Img from '../../Data/WorksData/ProjectData/TheNulli/Img/mainimg-09.png';

///////////////////////////////세종시////////////////////////////////////////////
import SejongSiThum from '../../Data/WorksData/ProjectData/SejongSi/Img/thumnailimg.png';
import SejongSi1Img from '../../Data/WorksData/ProjectData/SejongSi/Img/mainimg-01.png';

///////////////////////////////토스트캔버스////////////////////////////////////////////
import ToastCanvasThum from '../../Data/WorksData/ProjectData/ToastCanvas/Img/thumnailimg.png';
import ToastCanvas1Img from '../../Data/WorksData/ProjectData/ToastCanvas/Img/mainimg-01.png';
import ToastCanvas2Img from '../../Data/WorksData/ProjectData/ToastCanvas/Img/mainimg-02.png';
import ToastCanvas3Img from '../../Data/WorksData/ProjectData/ToastCanvas/Img/mainimg-03.png';
import ToastCanvas4Img from '../../Data/WorksData/ProjectData/ToastCanvas/Img/mainimg-04.png';
import ToastCanvas5Img from '../../Data/WorksData/ProjectData/ToastCanvas/Img/mainimg-05.png';
import ToastCanvas6Img from '../../Data/WorksData/ProjectData/ToastCanvas/Img/mainimg-06.png';
import ToastCanvas7Img from '../../Data/WorksData/ProjectData/ToastCanvas/Img/mainimg-07.png';
import ToastCanvas8Img from '../../Data/WorksData/ProjectData/ToastCanvas/Img/mainimg-08.png';

///////////////////////////////엑스온////////////////////////////////////////////
import ExonThum from '../../Data/WorksData/ProjectData/Exon/Img/thumnailimg.png';
import Exon1Img from '../../Data/WorksData/ProjectData/Exon/Img/mainimg-01.png';
import Exon2Img from '../../Data/WorksData/ProjectData/Exon/Img/mainimg-02.png';
import Exon3Img from '../../Data/WorksData/ProjectData/Exon/Img/mainimg-03.png';
import Exon4Img from '../../Data/WorksData/ProjectData/Exon/Img/mainimg-04.png';
import Exon5Img from '../../Data/WorksData/ProjectData/Exon/Img/mainimg-05.png';
import Exon6Img from '../../Data/WorksData/ProjectData/Exon/Img/mainimg-06.png';
import Exon7Img from '../../Data/WorksData/ProjectData/Exon/Img/mainimg-07.png';
import Exon8Img from '../../Data/WorksData/ProjectData/Exon/Img/mainimg-08.png';
import Exon9Img from '../../Data/WorksData/ProjectData/Exon/Img/mainimg-09.png';
import Exon10Img from '../../Data/WorksData/ProjectData/Exon/Img/mainimg-10.png';

///////////////////////////////한양도성////////////////////////////////////////////
import HanyangARThum from '../../Data/WorksData/ProjectData/HanyangAR/Img/thumnailimg.png';
import HanyangAR1Img from '../../Data/WorksData/ProjectData/HanyangAR/Img/mainimg-01.png';
import HanyangAR2Img from '../../Data/WorksData/ProjectData/HanyangAR/Img/mainimg-02.png';
import HanyangAR3Img from '../../Data/WorksData/ProjectData/HanyangAR/Img/mainimg-03.png';
import HanyangAR4Img from '../../Data/WorksData/ProjectData/HanyangAR/Img/mainimg-04.png';
import HanyangAR5Img from '../../Data/WorksData/ProjectData/HanyangAR/Img/mainimg-05.png';
import HanyangAR6Img from '../../Data/WorksData/ProjectData/HanyangAR/Img/mainimg-06.png';
import HanyangAR7Img from '../../Data/WorksData/ProjectData/HanyangAR/Img/mainimg-07.png';

///////////////////////////////SamsungPMS////////////////////////////////////////////
import SamsungPMSThum from '../../Data/WorksData/ProjectData/SamsungPMS/Img/thumnailimg.png';
import SamsungPMS1Img from '../../Data/WorksData/ProjectData/SamsungPMS/Img/mainimg-01.png';
// import SamsungPMS2Img from '../../Data/WorksData/ProjectData/SamsungPMS/Img/mainimg-02.png';
// import SamsungPMS3Img from '../../Data/WorksData/ProjectData/SamsungPMS/Img/mainimg-03.png';
// import SamsungPMS4Img from '../../Data/WorksData/ProjectData/SamsungPMS/Img/mainimg-04.png';
// import SamsungPMS5Img from '../../Data/WorksData/ProjectData/SamsungPMS/Img/mainimg-05.png';
// import SamsungPMS6Img from '../../Data/WorksData/ProjectData/SamsungPMS/Img/mainimg-06.png';
// import SamsungPMS7Img from '../../Data/WorksData/ProjectData/SamsungPMS/Img/mainimg-07.png';
// import SamsungPMS8Img from '../../Data/WorksData/ProjectData/SamsungPMS/Img/mainimg-08.png';
// import SamsungPMS9Img from '../../Data/WorksData/ProjectData/SamsungPMS/Img/mainimg-09.png';

///////////////////////////////Kotra////////////////////////////////////////////
import KotraThum from '../../Data/WorksData/ProjectData/Kotra/Img/thumnailimg.png';
import Kotra1Img from '../../Data/WorksData/ProjectData/Kotra/Img/mainimg-01.png';
import Kotra2Img from '../../Data/WorksData/ProjectData/Kotra/Img/mainimg-02.png';
import Kotra3Img from '../../Data/WorksData/ProjectData/Kotra/Img/mainimg-03.png';
import Kotra4Img from '../../Data/WorksData/ProjectData/Kotra/Img/mainimg-04.png';
import Kotra5Img from '../../Data/WorksData/ProjectData/Kotra/Img/mainimg-05.png';
import Kotra6Img from '../../Data/WorksData/ProjectData/Kotra/Img/mainimg-06.png';
import Kotra7Img from '../../Data/WorksData/ProjectData/Kotra/Img/mainimg-07.png';


///////////////////////////////Famoz////////////////////////////////////////////
import FamozThum from '../../Data/WorksData/ProjectData/Famoz/Img/thumnailimg.png';
import Famoz1Img from '../../Data/WorksData/ProjectData/Famoz/Img/mainimg-01.png';

///////////////////////////////VideoMonster////////////////////////////////////////////
import VideoMonsterThum from '../../Data/WorksData/ProjectData/VideoMonster/Img/thumnailimg.png';
import VideoMonster1Img from '../../Data/WorksData/ProjectData/VideoMonster/Img/mainimg-01.png';
import VideoMonster2Img from '../../Data/WorksData/ProjectData/VideoMonster/Img/mainimg-02.png';
import VideoMonster3Img from '../../Data/WorksData/ProjectData/VideoMonster/Img/mainimg-03.png';
import VideoMonster4Img from '../../Data/WorksData/ProjectData/VideoMonster/Img/mainimg-04.png';
import VideoMonster5Img from '../../Data/WorksData/ProjectData/VideoMonster/Img/mainimg-05.png';
import VideoMonster6Img from '../../Data/WorksData/ProjectData/VideoMonster/Img/mainimg-06.png';
import VideoMonster7Img from '../../Data/WorksData/ProjectData/VideoMonster/Img/mainimg-07.png';
import VideoMonster8Img from '../../Data/WorksData/ProjectData/VideoMonster/Img/mainimg-08.png';
//Exon, SamsungPMS, SejongSi, MotionTrend, Kotra, VideoMonster, ToastCanvas, HanyangAR, Prong, Lennon, Famoz, TheNulli


///////////////////////////////Icarus//////////////////////////////////////////// 
import IcarusThum from '../../Data/WorksData/ProjectData/Icarus/Img/thumb_Icarus_503x503.jpg';
import Icarus1Img from '../../Data/WorksData/ProjectData/Icarus/Img/210427_1차완성_Ash.jpg';


///////////////////////////////Yame//////////////////////////////////////////// 
import YameThum from '../../Data/WorksData/ProjectData/Yame/Img/thumb_Yame_503x503.jpg';
import Yame1Img from '../../Data/WorksData/ProjectData/Yame/Img/Yame.jpeg';

///////////////////////////////FarmPharm//////////////////////////////////////////// 
import FarmPharmThum from '../../Data/WorksData/ProjectData/FarmPharm/Img/thumb_Farm_503x503.jpg';
import FarmPharm1Img from '../../Data/WorksData/ProjectData/FarmPharm/Img/thumb_Farm_840x528.jpg';

///////////////////////////////ㅅㅇㄹ//////////////////////////////////////////// 
import ㅅㅇㄹThum from '../../Data/WorksData/ProjectData/ㅅㅇㄹ/Img/thumb_ㅅㅇㄹ_503x503.jpg';
import ㅅㅇㄹ1Img from '../../Data/WorksData/ProjectData/ㅅㅇㄹ/Img/Contents/image (1).jpg';
import ㅅㅇㄹ2Img from '../../Data/WorksData/ProjectData/ㅅㅇㄹ/Img/Contents/image (2).jpg';
import ㅅㅇㄹ3Img from '../../Data/WorksData/ProjectData/ㅅㅇㄹ/Img/Contents/image (3).jpg';
import ㅅㅇㄹ4Img from '../../Data/WorksData/ProjectData/ㅅㅇㄹ/Img/Contents/image (4).jpg';
import ㅅㅇㄹ5Img from '../../Data/WorksData/ProjectData/ㅅㅇㄹ/Img/Contents/image (5).jpg';
import ㅅㅇㄹ6Img from '../../Data/WorksData/ProjectData/ㅅㅇㄹ/Img/Contents/image (6).jpg';
import ㅅㅇㄹ7Img from '../../Data/WorksData/ProjectData/ㅅㅇㄹ/Img/Contents/image (7).jpg';
import ㅅㅇㄹ8Img from '../../Data/WorksData/ProjectData/ㅅㅇㄹ/Img/Contents/image (8).jpg';
import ㅅㅇㄹ9Img from '../../Data/WorksData/ProjectData/ㅅㅇㄹ/Img/Contents/image (9).jpg';
import ㅅㅇㄹ10Img from '../../Data/WorksData/ProjectData/ㅅㅇㄹ/Img/Contents/image (10).jpg';
import ㅅㅇㄹ11Img from '../../Data/WorksData/ProjectData/ㅅㅇㄹ/Img/Contents/image (11).jpg';
import ㅅㅇㄹ12Img from '../../Data/WorksData/ProjectData/ㅅㅇㄹ/Img/Contents/image (12).jpg';
import ㅅㅇㄹ13Img from '../../Data/WorksData/ProjectData/ㅅㅇㄹ/Img/Contents/image (13).jpg';
import ㅅㅇㄹ14Img from '../../Data/WorksData/ProjectData/ㅅㅇㄹ/Img/Contents/image (14).jpg';
import ㅅㅇㄹ15Img from '../../Data/WorksData/ProjectData/ㅅㅇㄹ/Img/Contents/image (15).jpg';
import ㅅㅇㄹ16Img from '../../Data/WorksData/ProjectData/ㅅㅇㄹ/Img/Contents/image (16).jpg';
import ㅅㅇㄹ17Img from '../../Data/WorksData/ProjectData/ㅅㅇㄹ/Img/Contents/image (17).jpg';
import ㅅㅇㄹ18Img from '../../Data/WorksData/ProjectData/ㅅㅇㄹ/Img/Contents/image (18).jpg';
import ㅅㅇㄹ19Img from '../../Data/WorksData/ProjectData/ㅅㅇㄹ/Img/Contents/image (19).jpg';
import ㅅㅇㄹ20Img from '../../Data/WorksData/ProjectData/ㅅㅇㄹ/Img/Contents/image (20).jpg';
import ㅅㅇㄹ21Img from '../../Data/WorksData/ProjectData/ㅅㅇㄹ/Img/Contents/image (21).jpg';
import ㅅㅇㄹ22Img from '../../Data/WorksData/ProjectData/ㅅㅇㄹ/Img/Contents/image (22).jpg';
import ㅅㅇㄹ23Img from '../../Data/WorksData/ProjectData/ㅅㅇㄹ/Img/Contents/image (23).jpg';

///////////////////////////////GuardSignature//////////////////////////////////////////// 
import GuardSignatureThum from '../../Data/WorksData/ProjectData/GuardSignature/Img/thumb_GuardSignature_503x503.jpg';
import GuardSignature1Img from '../../Data/WorksData/ProjectData/GuardSignature/Img/Contents/image (1).jpg';
import GuardSignature2Img from '../../Data/WorksData/ProjectData/GuardSignature/Img/Contents/image (2).jpg';
import GuardSignature3Img from '../../Data/WorksData/ProjectData/GuardSignature/Img/Contents/image (3).jpg';
import GuardSignature4Img from '../../Data/WorksData/ProjectData/GuardSignature/Img/Contents/image (4).jpg';
import GuardSignature5Img from '../../Data/WorksData/ProjectData/GuardSignature/Img/Contents/image (5).jpg';
import GuardSignature6Img from '../../Data/WorksData/ProjectData/GuardSignature/Img/Contents/image (6).jpg';
import GuardSignature7Img from '../../Data/WorksData/ProjectData/GuardSignature/Img/Contents/image (7).jpg';
import GuardSignature8Img from '../../Data/WorksData/ProjectData/GuardSignature/Img/Contents/image (8).jpg';
import GuardSignature9Img from '../../Data/WorksData/ProjectData/GuardSignature/Img/Contents/image (9).jpg';
import GuardSignature10Img from '../../Data/WorksData/ProjectData/GuardSignature/Img/Contents/image (10).jpg';
import GuardSignature11Img from '../../Data/WorksData/ProjectData/GuardSignature/Img/Contents/image (11).jpg';
import GuardSignature12Img from '../../Data/WorksData/ProjectData/GuardSignature/Img/Contents/image (12).jpg';
import GuardSignature13Img from '../../Data/WorksData/ProjectData/GuardSignature/Img/Contents/image (13).jpg';
import GuardSignature14Img from '../../Data/WorksData/ProjectData/GuardSignature/Img/Contents/image (14).jpg';
import GuardSignature15Img from '../../Data/WorksData/ProjectData/GuardSignature/Img/Contents/image (15).jpg';
import GuardSignature16Img from '../../Data/WorksData/ProjectData/GuardSignature/Img/Contents/image (16).jpg';
import GuardSignature17Img from '../../Data/WorksData/ProjectData/GuardSignature/Img/Contents/image (17).jpg';
import GuardSignature18Img from '../../Data/WorksData/ProjectData/GuardSignature/Img/Contents/image (18).jpg';
import GuardSignature19Img from '../../Data/WorksData/ProjectData/GuardSignature/Img/Contents/image (19).jpg';
import GuardSignature20Img from '../../Data/WorksData/ProjectData/GuardSignature/Img/Contents/image (20).jpg';
import GuardSignature21Img from '../../Data/WorksData/ProjectData/GuardSignature/Img/Contents/image (21).jpg';
import GuardSignature22Img from '../../Data/WorksData/ProjectData/GuardSignature/Img/Contents/image (22).jpg';
import GuardSignature23Img from '../../Data/WorksData/ProjectData/GuardSignature/Img/Contents/image (23).jpg';
import GuardSignature24Img from '../../Data/WorksData/ProjectData/GuardSignature/Img/Contents/image (24).jpg';
import GuardSignature25Img from '../../Data/WorksData/ProjectData/GuardSignature/Img/Contents/image (25).jpg';
import GuardSignature26Img from '../../Data/WorksData/ProjectData/GuardSignature/Img/Contents/image (26).jpg';
import GuardSignature27Img from '../../Data/WorksData/ProjectData/GuardSignature/Img/Contents/image (27).jpg';
import GuardSignature28Img from '../../Data/WorksData/ProjectData/GuardSignature/Img/Contents/image (28).jpg';
import GuardSignature29Img from '../../Data/WorksData/ProjectData/GuardSignature/Img/Contents/image (29).jpg';
import GuardSignature30Img from '../../Data/WorksData/ProjectData/GuardSignature/Img/Contents/image (30).jpg';
import GuardSignature31Img from '../../Data/WorksData/ProjectData/GuardSignature/Img/Contents/image (31).jpg';
import GuardSignature32Img from '../../Data/WorksData/ProjectData/GuardSignature/Img/Contents/image (32).jpg';
import GuardSignature33Img from '../../Data/WorksData/ProjectData/GuardSignature/Img/Contents/image (33).jpg';
import GuardSignature34Img from '../../Data/WorksData/ProjectData/GuardSignature/Img/Contents/image (34).jpg';
import GuardSignature35Img from '../../Data/WorksData/ProjectData/GuardSignature/Img/Contents/image (35).jpg';
import GuardSignature36Img from '../../Data/WorksData/ProjectData/GuardSignature/Img/Contents/image (36).jpg';

///////////////////////////////WHT30//////////////////////////////////////////// 
import WHT30Thum from '../../Data/WorksData/ProjectData/WHT30/Img/thumb_WHT30_503x503.jpg';
import WHT30_1Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (1).jpg';
import WHT30_2Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (2).jpg';
import WHT30_3Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (3).jpg';
import WHT30_4Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (4).jpg';
import WHT30_5Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (5).jpg';
import WHT30_6Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (6).jpg';
import WHT30_7Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (7).jpg';
import WHT30_8Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (8).jpg';
import WHT30_9Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (9).jpg';
import WHT30_10Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (10).jpg';
import WHT30_11Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (11).jpg';
import WHT30_12Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (12).jpg';
import WHT30_13Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (13).jpg';
import WHT30_14Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (14).jpg';
import WHT30_15Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (15).jpg';
import WHT30_16Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (16).jpg';
import WHT30_17Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (17).jpg';
import WHT30_18Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (18).jpg';
import WHT30_19Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (19).jpg';
import WHT30_20Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (20).jpg';
import WHT30_21Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (21).jpg';
import WHT30_22Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (22).jpg';
import WHT30_23Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (23).jpg';
import WHT30_24Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (24).jpg';
import WHT30_25Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (25).jpg';
import WHT30_26Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (26).jpg';
import WHT30_27Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (27).jpg';
import WHT30_28Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (28).jpg';
import WHT30_29Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (29).jpg';
import WHT30_30Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (30).jpg';
import WHT30_31Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (31).jpg';
import WHT30_32Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (32).jpg';
import WHT30_33Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (33).jpg';
import WHT30_34Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (34).jpg';
import WHT30_35Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (35).jpg';
import WHT30_36Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (36).jpg';
import WHT30_37Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (37).jpg';
import WHT30_38Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (38).jpg';
import WHT30_39Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (39).jpg';
import WHT30_40Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (40).jpg';
import WHT30_41Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (41).jpg';
import WHT30_42Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (42).jpg';
import WHT30_43Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (43).jpg';
import WHT30_44Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (44).jpg';
import WHT30_45Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (45).jpg';
import WHT30_46Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (46).jpg';
import WHT30_47Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (47).jpg';
import WHT30_48Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (48).jpg';
import WHT30_49Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (49).jpg';
import WHT30_50Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (50).jpg';
import WHT30_51Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (51).jpg';
import WHT30_52Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (52).jpg';
import WHT30_53Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (53).jpg';
import WHT30_54Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (54).jpg';
import WHT30_55Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (55).jpg';
import WHT30_56Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (56).jpg';
import WHT30_57Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (57).jpg';
import WHT30_58Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (58).jpg';
import WHT30_59Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (59).jpg';
import WHT30_60Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (60).jpg';
import WHT30_61Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (61).jpg';
import WHT30_62Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (62).jpg';
import WHT30_63Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (63).jpg';
import WHT30_64Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (64).jpg';
import WHT30_65Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (65).jpg';
import WHT30_66Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (66).jpg';
import WHT30_67Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (67).jpg';
import WHT30_68Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (68).jpg';
import WHT30_69Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (69).jpg';
import WHT30_70Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (70).jpg';
import WHT30_71Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (71).jpg';
import WHT30_72Img from '../../Data/WorksData/ProjectData/WHT30/Img/Contents/image (72).jpg';

///////////////////////////////Artificialcity////////////////////////////////////////////
import ArtificialcityThum from '../../Data/WorksData/ProjectData/Artificialcity/Img/thumb_artificialcity_503x503.jpg';
import Artificialcity1Img from '../../Data/WorksData/ProjectData/Artificialcity/Img/Contents/PG_ArtificialCity.jpg';

///////////////////////////////Videonly////////////////////////////////////////////
import VideonlyThum from '../../Data/WorksData/ProjectData/Videonly/Img/thumb_videonly_503x503.jpg';
import Videonly1Img from '../../Data/WorksData/ProjectData/Videonly/Img/Contents/image (1).jpg';
import Videonly2Img from '../../Data/WorksData/ProjectData/Videonly/Img/Contents/image (2).jpg';
import Videonly3Img from '../../Data/WorksData/ProjectData/Videonly/Img/Contents/image (3).jpg';
import Videonly4Img from '../../Data/WorksData/ProjectData/Videonly/Img/Contents/image (4).jpg';
import Videonly5Img from '../../Data/WorksData/ProjectData/Videonly/Img/Contents/image (5).jpg';
import Videonly6Img from '../../Data/WorksData/ProjectData/Videonly/Img/Contents/image (6).jpg';
import Videonly7Img from '../../Data/WorksData/ProjectData/Videonly/Img/Contents/image (7).jpg';
import Videonly8Img from '../../Data/WorksData/ProjectData/Videonly/Img/Contents/image (8).jpg';
import Videonly9Img from '../../Data/WorksData/ProjectData/Videonly/Img/Contents/image (9).jpg';
import Videonly10Img from '../../Data/WorksData/ProjectData/Videonly/Img/Contents/image (10).jpg';
import Videonly11Img from '../../Data/WorksData/ProjectData/Videonly/Img/Contents/image (11).jpg';
import Videonly12Img from '../../Data/WorksData/ProjectData/Videonly/Img/Contents/image (12).jpg';
import Videonly13Img from '../../Data/WorksData/ProjectData/Videonly/Img/Contents/image (13).jpg';
import Videonly14Img from '../../Data/WorksData/ProjectData/Videonly/Img/Contents/image (14).jpg';
import Videonly15Img from '../../Data/WorksData/ProjectData/Videonly/Img/Contents/image (15).jpg';
import Videonly16Img from '../../Data/WorksData/ProjectData/Videonly/Img/Contents/image (16).jpg';
import Videonly17Img from '../../Data/WorksData/ProjectData/Videonly/Img/Contents/image (17).jpg';
import Videonly18Img from '../../Data/WorksData/ProjectData/Videonly/Img/Contents/image (18).jpg';
import Videonly19Img from '../../Data/WorksData/ProjectData/Videonly/Img/Contents/image (19).jpg';
import Videonly20Img from '../../Data/WorksData/ProjectData/Videonly/Img/Contents/image (20).jpg';
import Videonly21Img from '../../Data/WorksData/ProjectData/Videonly/Img/Contents/image (21).jpg';
import Videonly22Img from '../../Data/WorksData/ProjectData/Videonly/Img/Contents/image (22).jpg';
import Videonly23Img from '../../Data/WorksData/ProjectData/Videonly/Img/Contents/image (23).jpg';
import Videonly24Img from '../../Data/WorksData/ProjectData/Videonly/Img/Contents/image (24).jpg';
import Videonly25Img from '../../Data/WorksData/ProjectData/Videonly/Img/Contents/image (25).jpg';
import Videonly26Img from '../../Data/WorksData/ProjectData/Videonly/Img/Contents/image (26).jpg';
import Videonly27Img from '../../Data/WorksData/ProjectData/Videonly/Img/Contents/image (27).jpg';
import Videonly28Img from '../../Data/WorksData/ProjectData/Videonly/Img/Contents/image (28).jpg';
import Videonly29Img from '../../Data/WorksData/ProjectData/Videonly/Img/Contents/image (29).jpg';
import Videonly30Img from '../../Data/WorksData/ProjectData/Videonly/Img/Contents/image (30).jpg';
import Videonly31Img from '../../Data/WorksData/ProjectData/Videonly/Img/Contents/image (31).jpg';
import Videonly32Img from '../../Data/WorksData/ProjectData/Videonly/Img/Contents/image (32).jpg';
import Videonly33Img from '../../Data/WorksData/ProjectData/Videonly/Img/Contents/image (33).jpg';
import Videonly34Img from '../../Data/WorksData/ProjectData/Videonly/Img/Contents/image (34).jpg';
import Videonly35Img from '../../Data/WorksData/ProjectData/Videonly/Img/Contents/image (35).jpg';
import Videonly36Img from '../../Data/WorksData/ProjectData/Videonly/Img/Contents/image (36).jpg';
import Videonly37Img from '../../Data/WorksData/ProjectData/Videonly/Img/Contents/image (37).jpg';
import Videonly38Img from '../../Data/WorksData/ProjectData/Videonly/Img/Contents/image (38).jpg';
import Videonly39Img from '../../Data/WorksData/ProjectData/Videonly/Img/Contents/image (39).jpg';
import Videonly40Img from '../../Data/WorksData/ProjectData/Videonly/Img/Contents/image (40).jpg';
import Videonly41Img from '../../Data/WorksData/ProjectData/Videonly/Img/Contents/image (41).jpg';
import Videonly42Img from '../../Data/WorksData/ProjectData/Videonly/Img/Contents/image (42).jpg';
import Videonly43Img from '../../Data/WorksData/ProjectData/Videonly/Img/Contents/image (43).jpg';
import Videonly44Img from '../../Data/WorksData/ProjectData/Videonly/Img/Contents/image (44).jpg';

///////////////////////////////////////////////////////////////////////////
import NomadThum from '../../Data/WorksData/ProjectData/Nomad/Img/thumb_nomad_503x503.jpg';
import Nomad1Img from '../../Data/WorksData/ProjectData/Nomad/Img/Contents/image (1).jpg';
import Nomad2Img from '../../Data/WorksData/ProjectData/Nomad/Img/Contents/image (2).jpg';
import Nomad3Img from '../../Data/WorksData/ProjectData/Nomad/Img/Contents/image (3).jpg';
import Nomad4Img from '../../Data/WorksData/ProjectData/Nomad/Img/Contents/image (4).jpg';
import Nomad5Img from '../../Data/WorksData/ProjectData/Nomad/Img/Contents/image (5).jpg';
import Nomad6Img from '../../Data/WorksData/ProjectData/Nomad/Img/Contents/image (6).jpg';
import Nomad7Img from '../../Data/WorksData/ProjectData/Nomad/Img/Contents/image (7).jpg';
import Nomad8Img from '../../Data/WorksData/ProjectData/Nomad/Img/Contents/image (8).jpg';
import Nomad9Img from '../../Data/WorksData/ProjectData/Nomad/Img/Contents/image (9).jpg';
import Nomad10Img from '../../Data/WorksData/ProjectData/Nomad/Img/Contents/image (10).jpg';
import Nomad11Img from '../../Data/WorksData/ProjectData/Nomad/Img/Contents/image (11).jpg';
import Nomad12Img from '../../Data/WorksData/ProjectData/Nomad/Img/Contents/image (12).jpg';
import Nomad13Img from '../../Data/WorksData/ProjectData/Nomad/Img/Contents/image (13).jpg';
import Nomad14Img from '../../Data/WorksData/ProjectData/Nomad/Img/Contents/image (14).jpg';
import Nomad15Img from '../../Data/WorksData/ProjectData/Nomad/Img/Contents/image (15).jpg';
import Nomad16Img from '../../Data/WorksData/ProjectData/Nomad/Img/Contents/image (16).jpg';
import Nomad17Img from '../../Data/WorksData/ProjectData/Nomad/Img/Contents/image (17).jpg';
import Nomad18Img from '../../Data/WorksData/ProjectData/Nomad/Img/Contents/image (18).jpg';
import Nomad19Img from '../../Data/WorksData/ProjectData/Nomad/Img/Contents/image (19).jpg';
import Nomad20Img from '../../Data/WorksData/ProjectData/Nomad/Img/Contents/image (20).jpg';
import Nomad21Img from '../../Data/WorksData/ProjectData/Nomad/Img/Contents/image (21).jpg';
import Nomad22Img from '../../Data/WorksData/ProjectData/Nomad/Img/Contents/image (22).jpg';
import Nomad23Img from '../../Data/WorksData/ProjectData/Nomad/Img/Contents/image (23).jpg';
import Nomad24Img from '../../Data/WorksData/ProjectData/Nomad/Img/Contents/image (24).jpg';
import Nomad25Img from '../../Data/WorksData/ProjectData/Nomad/Img/Contents/image (25).jpg';

///////////////////////////////UIX//////////////////////////////////////////// 
import UIXThum from '../../Data/WorksData/ProjectData/UIX/Img/thumb_uix_503x503.jpg';
import UIX0Img from '../../Data/WorksData/ProjectData/UIX/Img/Contents/00.jpg';
import UIX1Img from '../../Data/WorksData/ProjectData/UIX/Img/Contents/01.jpg';
import UIX2Img from '../../Data/WorksData/ProjectData/UIX/Img/Contents/02.jpg';
import UIX3Img from '../../Data/WorksData/ProjectData/UIX/Img/Contents/03.jpg';
import UIX4Img from '../../Data/WorksData/ProjectData/UIX/Img/Contents/04.jpg';
import UIX5Img from '../../Data/WorksData/ProjectData/UIX/Img/Contents/05.jpg';
import UIX6Img from '../../Data/WorksData/ProjectData/UIX/Img/Contents/06.jpg';
import UIX7Img from '../../Data/WorksData/ProjectData/UIX/Img/Contents/07.jpg';
import UIX8Img from '../../Data/WorksData/ProjectData/UIX/Img/Contents/08.jpg';
import UIX9Img from '../../Data/WorksData/ProjectData/UIX/Img/Contents/09.jpg';
import UIX10Img from '../../Data/WorksData/ProjectData/UIX/Img/Contents/10.jpg';
import UIX11Img from '../../Data/WorksData/ProjectData/UIX/Img/Contents/11.jpg';
import UIX12Img from '../../Data/WorksData/ProjectData/UIX/Img/Contents/12.jpg';
import UIX13Img from '../../Data/WorksData/ProjectData/UIX/Img/Contents/13.jpg';
import UIX14Img from '../../Data/WorksData/ProjectData/UIX/Img/Contents/14.jpg';
import UIX15Img from '../../Data/WorksData/ProjectData/UIX/Img/Contents/15.jpg';
import UIX16Img from '../../Data/WorksData/ProjectData/UIX/Img/Contents/16.jpg';
import UIX17Img from '../../Data/WorksData/ProjectData/UIX/Img/Contents/17.jpg';
import UIX18Img from '../../Data/WorksData/ProjectData/UIX/Img/Contents/18.jpg';
import UIX19Img from '../../Data/WorksData/ProjectData/UIX/Img/Contents/19.jpg';

///////////////////////////////KKAL//////////////////////////////////////////// 
import KKALThum from '../../Data/WorksData/ProjectData/KKAL/Img/thumb_KKAL_503x503.jpg';
import KKAL1Img from '../../Data/WorksData/ProjectData/KKAL/Img/Contents/00. Cover.jpg';
import KKAL2Img from '../../Data/WorksData/ProjectData/KKAL/Img/Contents/01-1. Brand Guide rule.jpg';
import KKAL3Img from '../../Data/WorksData/ProjectData/KKAL/Img/Contents/01-2. Brand Guide rule.jpg';
import KKAL4Img from '../../Data/WorksData/ProjectData/KKAL/Img/Contents/02-1. Conept.jpg';
import KKAL5Img from '../../Data/WorksData/ProjectData/KKAL/Img/Contents/02-2. Concept.jpg';
import KKAL6Img from '../../Data/WorksData/ProjectData/KKAL/Img/Contents/02-3. Concept.jpg';
import KKAL7Img from '../../Data/WorksData/ProjectData/KKAL/Img/Contents/02-4. Concept.jpg';
import KKAL8Img from '../../Data/WorksData/ProjectData/KKAL/Img/Contents/03. CI.jpg';
import KKAL9Img from '../../Data/WorksData/ProjectData/KKAL/Img/Contents/04. CI Style.jpg';
import KKAL10Img from '../../Data/WorksData/ProjectData/KKAL/Img/Contents/05-1. Brand font.jpg';
import KKAL11Img from '../../Data/WorksData/ProjectData/KKAL/Img/Contents/06. Color system.jpg';
import KKAL12Img from '../../Data/WorksData/ProjectData/KKAL/Img/Contents/07. Icon.jpg';
import KKAL13Img from '../../Data/WorksData/ProjectData/KKAL/Img/Contents/08-1. illustration.jpg';
import KKAL14Img from '../../Data/WorksData/ProjectData/KKAL/Img/Contents/08-2. illustration.jpg';
import KKAL15Img from '../../Data/WorksData/ProjectData/KKAL/Img/Contents/08-9. illustration.jpg';
import KKAL16Img from '../../Data/WorksData/ProjectData/KKAL/Img/Contents/08-10. illustration.jpg';
import KKAL17Img from '../../Data/WorksData/ProjectData/KKAL/Img/Contents/08-11. illustration.jpg';
import KKAL18Img from '../../Data/WorksData/ProjectData/KKAL/Img/Contents/08-12. illustration.jpg';
import KKAL19Img from '../../Data/WorksData/ProjectData/KKAL/Img/Contents/08-13. illustration.jpg';
import KKAL20Img from '../../Data/WorksData/ProjectData/KKAL/Img/Contents/08-14. illustration.jpg';
import KKAL21Img from '../../Data/WorksData/ProjectData/KKAL/Img/Contents/10-1. poster.jpg';
import KKAL22Img from '../../Data/WorksData/ProjectData/KKAL/Img/Contents/10-2. poster.jpg';
import KKAL23Img from '../../Data/WorksData/ProjectData/KKAL/Img/Contents/11-1. Sticker.jpg';
import KKAL24Img from '../../Data/WorksData/ProjectData/KKAL/Img/Contents/11-2. Sticker.jpg';
import KKAL25Img from '../../Data/WorksData/ProjectData/KKAL/Img/Contents/12-1. business card.jpg';
import KKAL26Img from '../../Data/WorksData/ProjectData/KKAL/Img/Contents/12-2. business card.jpg';
import KKAL27Img from '../../Data/WorksData/ProjectData/KKAL/Img/Contents/13-1. id card.jpg';
import KKAL28Img from '../../Data/WorksData/ProjectData/KKAL/Img/Contents/14-1. business set.jpg';
import KKAL29Img from '../../Data/WorksData/ProjectData/KKAL/Img/Contents/15-1. button.jpg';
import KKAL30Img from '../../Data/WorksData/ProjectData/KKAL/Img/Contents/15-1. tape.jpg';
import KKAL31Img from '../../Data/WorksData/ProjectData/KKAL/Img/Contents/15-2. button.jpg';
import KKAL32Img from '../../Data/WorksData/ProjectData/KKAL/Img/Contents/16. sign.jpg';
import KKAL33Img from '../../Data/WorksData/ProjectData/KKAL/Img/Contents/17-1. banner.jpg';
import KKAL34Img from '../../Data/WorksData/ProjectData/KKAL/Img/Contents/17-2. banner.jpg';
import KKAL35Img from '../../Data/WorksData/ProjectData/KKAL/Img/Contents/18-1. book.jpg';
import KKAL36Img from '../../Data/WorksData/ProjectData/KKAL/Img/Contents/18-2. book.jpg';

///////////////////////////////Sejong//////////////////////////////////////////// 
import SejongThum from '../../Data/WorksData/ProjectData/Sejong/Img/thumb_sejong_503x503.jpg';
import Sejong1Img from '../../Data/WorksData/ProjectData/Sejong/Img/Contents/220216_sejong_projectguide.jpg';

///////////////////////////////Methin//////////////////////////////////////////// 
import MethinThum from '../../Data/WorksData/ProjectData/Methin/Img/thumb_methin_503x503.jpg';
import Methin1Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (1).jpg';
import Methin2Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (2).jpg';
import Methin3Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (3).jpg';
import Methin4Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (4).jpg';
import Methin5Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (5).jpg';
import Methin6Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (6).jpg';
import Methin7Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (7).jpg';
import Methin8Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (8).jpg';
import Methin9Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (9).jpg';
import Methin10Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (10).jpg';
import Methin11Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (11).jpg';
import Methin12Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (12).jpg';
import Methin13Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (13).jpg';
import Methin14Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (14).jpg';
import Methin15Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (15).jpg';
import Methin16Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (16).jpg';
import Methin17Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (17).jpg';
import Methin18Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (18).jpg';
import Methin19Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (19).jpg';
import Methin20Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (20).jpg';
import Methin21Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (21).jpg';
import Methin22Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (22).jpg';
import Methin23Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (23).jpg';
import Methin24Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (24).jpg';
import Methin25Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (25).jpg';
import Methin26Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (26).jpg';
import Methin27Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (27).jpg';
import Methin28Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (28).jpg';
import Methin29Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (29).jpg';
import Methin30Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (30).jpg';
import Methin31Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (31).jpg';
import Methin32Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (32).jpg';
import Methin33Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (33).jpg';
import Methin34Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (34).jpg';
import Methin35Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (35).jpg';
import Methin36Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (36).jpg';
import Methin37Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (37).jpg';
import Methin38Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (38).jpg';
import Methin39Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (39).jpg';
import Methin40Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (40).jpg';
import Methin41Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (41).jpg';
import Methin42Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (42).jpg';
import Methin43Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (43).jpg';
import Methin44Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (44).jpg';
import Methin45Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (45).jpg';
import Methin46Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (46).jpg';
import Methin47Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (47).jpg';
import Methin48Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (48).jpg';
import Methin49Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (49).jpg';
import Methin50Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (50).jpg';
import Methin51Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (51).jpg';
import Methin52Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (52).jpg';
import Methin53Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (53).jpg';
import Methin54Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (54).jpg';
import Methin55Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (55).jpg';
import Methin56Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (56).jpg';
import Methin57Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (57).jpg';
import Methin58Img from '../../Data/WorksData/ProjectData/Methin/Img/Contents/image (58).jpg';

///////////////////////////////Servekit//////////////////////////////////////////// 
import ServekitThum from '../../Data/WorksData/ProjectData/Servekit/Img/thumb_severkit_503x503.jpg';
import Servekit1Img from '../../Data/WorksData/ProjectData/Servekit/Img/Contents/image (1).jpg';
import Servekit2Img from '../../Data/WorksData/ProjectData/Servekit/Img/Contents/image (2).jpg';
import Servekit3Img from '../../Data/WorksData/ProjectData/Servekit/Img/Contents/image (3).jpg';
import Servekit4Img from '../../Data/WorksData/ProjectData/Servekit/Img/Contents/image (4).jpg';
import Servekit5Img from '../../Data/WorksData/ProjectData/Servekit/Img/Contents/image (5).jpg';
import Servekit6Img from '../../Data/WorksData/ProjectData/Servekit/Img/Contents/image (6).jpg';
import Servekit7Img from '../../Data/WorksData/ProjectData/Servekit/Img/Contents/image (7).jpg';
import Servekit8Img from '../../Data/WorksData/ProjectData/Servekit/Img/Contents/image (8).jpg';
import Servekit9Img from '../../Data/WorksData/ProjectData/Servekit/Img/Contents/image (9).jpg';
import Servekit10Img from '../../Data/WorksData/ProjectData/Servekit/Img/Contents/image (10).jpg';
import Servekit11Img from '../../Data/WorksData/ProjectData/Servekit/Img/Contents/image (11).jpg';
import Servekit12Img from '../../Data/WorksData/ProjectData/Servekit/Img/Contents/image (12).jpg';
import Servekit13Img from '../../Data/WorksData/ProjectData/Servekit/Img/Contents/image (13).jpg';
import Servekit14Img from '../../Data/WorksData/ProjectData/Servekit/Img/Contents/image (14).jpg';
import Servekit15Img from '../../Data/WorksData/ProjectData/Servekit/Img/Contents/image (15).jpg';
import Servekit16Img from '../../Data/WorksData/ProjectData/Servekit/Img/Contents/image (16).jpg';
import Servekit17Img from '../../Data/WorksData/ProjectData/Servekit/Img/Contents/image (17).jpg';
import Servekit18Img from '../../Data/WorksData/ProjectData/Servekit/Img/Contents/image (18).jpg';
import Servekit19Img from '../../Data/WorksData/ProjectData/Servekit/Img/Contents/image (19).jpg';
import Servekit20Img from '../../Data/WorksData/ProjectData/Servekit/Img/Contents/image (20).jpg';
import Servekit21Img from '../../Data/WorksData/ProjectData/Servekit/Img/Contents/image (21).jpg';
import Servekit22Img from '../../Data/WorksData/ProjectData/Servekit/Img/Contents/image (22).jpg';
import Servekit23Img from '../../Data/WorksData/ProjectData/Servekit/Img/Contents/image (23).jpg';
import Servekit24Img from '../../Data/WorksData/ProjectData/Servekit/Img/Contents/image (24).jpg';
import Servekit25Img from '../../Data/WorksData/ProjectData/Servekit/Img/Contents/image (25).jpg';
import Servekit26Img from '../../Data/WorksData/ProjectData/Servekit/Img/Contents/image (26).jpg';
import Servekit27Img from '../../Data/WorksData/ProjectData/Servekit/Img/Contents/image (27).jpg';
import Servekit28Img from '../../Data/WorksData/ProjectData/Servekit/Img/Contents/image (28).jpg';
import Servekit29Img from '../../Data/WorksData/ProjectData/Servekit/Img/Contents/image (29).jpg';
import Servekit30Img from '../../Data/WorksData/ProjectData/Servekit/Img/Contents/image (30).jpg';
import Servekit31Img from '../../Data/WorksData/ProjectData/Servekit/Img/Contents/image (31).jpg';
import Servekit32Img from '../../Data/WorksData/ProjectData/Servekit/Img/Contents/image (32).jpg';
import Servekit33Img from '../../Data/WorksData/ProjectData/Servekit/Img/Contents/image (33).jpg';
import Servekit34Img from '../../Data/WorksData/ProjectData/Servekit/Img/Contents/image (34).jpg';
import Servekit35Img from '../../Data/WorksData/ProjectData/Servekit/Img/Contents/image (35).jpg';
import Servekit36Img from '../../Data/WorksData/ProjectData/Servekit/Img/Contents/image (36).jpg';
import Servekit37Img from '../../Data/WorksData/ProjectData/Servekit/Img/Contents/image (37).jpg';
import Servekit38Img from '../../Data/WorksData/ProjectData/Servekit/Img/Contents/image (38).jpg';
import Servekit39Img from '../../Data/WorksData/ProjectData/Servekit/Img/Contents/image (39).jpg';
import Servekit40Img from '../../Data/WorksData/ProjectData/Servekit/Img/Contents/image (40).jpg';

///////////////////////////////ConceptCube////////////////////////////////////////////
import ConceptCubeThum from '../../Data/WorksData/ProjectData/ConceptCube/Img/thumb_conceptcube_503x503.jpg';
import ConceptCube1Img from '../../Data/WorksData/ProjectData/ConceptCube/Img/Contents/00. 메인.jpg';
import ConceptCube2Img from '../../Data/WorksData/ProjectData/ConceptCube/Img/Contents/01. 사용규정.jpg';
import ConceptCube3Img from '../../Data/WorksData/ProjectData/ConceptCube/Img/Contents/02. 목차.jpg';
import ConceptCube4Img from '../../Data/WorksData/ProjectData/ConceptCube/Img/Contents/03-1. logo 기본.jpg';
import ConceptCube5Img from '../../Data/WorksData/ProjectData/ConceptCube/Img/Contents/03-2. logo 사용규정.jpg';
import ConceptCube6Img from '../../Data/WorksData/ProjectData/ConceptCube/Img/Contents/03-3. logo 사용예시.jpg';
import ConceptCube7Img from '../../Data/WorksData/ProjectData/ConceptCube/Img/Contents/03-4. logo 사용예시.jpg';
import ConceptCube8Img from '../../Data/WorksData/ProjectData/ConceptCube/Img/Contents/04. color.jpg';
import ConceptCube9Img from '../../Data/WorksData/ProjectData/ConceptCube/Img/Contents/06-1.typography.jpg';
import ConceptCube10Img from '../../Data/WorksData/ProjectData/ConceptCube/Img/Contents/06-2.typography1_.jpg';
import ConceptCube11Img from '../../Data/WorksData/ProjectData/ConceptCube/Img/Contents/07-1. icon.jpg';
import ConceptCube12Img from '../../Data/WorksData/ProjectData/ConceptCube/Img/Contents/07-2. Icon 사용예시.jpg';
import ConceptCube13Img from '../../Data/WorksData/ProjectData/ConceptCube/Img/Contents/07-3. icon 사용예시.jpg';
import ConceptCube14Img from '../../Data/WorksData/ProjectData/ConceptCube/Img/Contents/08-1. 명함.jpg';
import ConceptCube15Img from '../../Data/WorksData/ProjectData/ConceptCube/Img/Contents/08-2 명함.jpg';
import ConceptCube16Img from '../../Data/WorksData/ProjectData/ConceptCube/Img/Contents/08-3 명함 + 서류.jpg';
import ConceptCube17Img from '../../Data/WorksData/ProjectData/ConceptCube/Img/Contents/09-1. 서류.jpg';
import ConceptCube18Img from '../../Data/WorksData/ProjectData/ConceptCube/Img/Contents/09-2 서류.jpg';
import ConceptCube19Img from '../../Data/WorksData/ProjectData/ConceptCube/Img/Contents/09-3 봉투.jpg';
import ConceptCube20Img from '../../Data/WorksData/ProjectData/ConceptCube/Img/Contents/09-4 봉투.jpg';
import ConceptCube21Img from '../../Data/WorksData/ProjectData/ConceptCube/Img/Contents/10-1. 사원증.jpg';
import ConceptCube22Img from '../../Data/WorksData/ProjectData/ConceptCube/Img/Contents/10-2. 사원증.jpg';
import ConceptCube23Img from '../../Data/WorksData/ProjectData/ConceptCube/Img/Contents/13-1. 스티커.jpg';
import ConceptCube24Img from '../../Data/WorksData/ProjectData/ConceptCube/Img/Contents/13-2. 스티커.jpg';
import ConceptCube25Img from '../../Data/WorksData/ProjectData/ConceptCube/Img/Contents/14-1. 포스터.jpg';
import ConceptCube26Img from '../../Data/WorksData/ProjectData/ConceptCube/Img/Contents/14-2. 포스터.jpg';
import ConceptCube27Img from '../../Data/WorksData/ProjectData/ConceptCube/Img/Contents/14-3. 포스터.jpg';
import ConceptCube28Img from '../../Data/WorksData/ProjectData/ConceptCube/Img/Contents/15-1. 전광판_.jpg';
import ConceptCube29Img from '../../Data/WorksData/ProjectData/ConceptCube/Img/Contents/15-2. 전광판 copy.jpg';
import ConceptCube30Img from '../../Data/WorksData/ProjectData/ConceptCube/Img/Contents/15-2. 전광판.jpg';
import ConceptCube31Img from '../../Data/WorksData/ProjectData/ConceptCube/Img/Contents/16-1. 가방.jpg';
import ConceptCube32Img from '../../Data/WorksData/ProjectData/ConceptCube/Img/Contents/16-2. 의류.jpg';
import ConceptCube33Img from '../../Data/WorksData/ProjectData/ConceptCube/Img/Contents/16-3. 의류.jpg';
import ConceptCube34Img from '../../Data/WorksData/ProjectData/ConceptCube/Img/Contents/17-1. 테이프.jpg';
import ConceptCube35Img from '../../Data/WorksData/ProjectData/ConceptCube/Img/Contents/17-2. 테이프.jpg';
import ConceptCube36Img from '../../Data/WorksData/ProjectData/ConceptCube/Img/Contents/18. contact.jpg';

///////////////////////////////LocalMoney//////////////////////////////////////////// 
import LocalMoneyThum from '../../Data/WorksData/ProjectData/LocalMoney/Img/thumb_loca_503x503.jpg';
import LocalMoney1Img from '../../Data/WorksData/ProjectData/LocalMoney/Img/Contents/220214_locacard.jpg';

///////////////////////////////Maxius//////////////////////////////////////////// 
import MaxiusThum from '../../Data/WorksData/ProjectData/Maxius/Img/thumb_maxius_503x503.jpg';
import Maxius1Img from '../../Data/WorksData/ProjectData/Maxius/Img/Contents/image (1).jpg';
import Maxius2Img from '../../Data/WorksData/ProjectData/Maxius/Img/Contents/image (2).jpg';
import Maxius3Img from '../../Data/WorksData/ProjectData/Maxius/Img/Contents/image (3).jpg';
import Maxius4Img from '../../Data/WorksData/ProjectData/Maxius/Img/Contents/image (4).jpg';
import Maxius5Img from '../../Data/WorksData/ProjectData/Maxius/Img/Contents/image (5).jpg';
import Maxius6Img from '../../Data/WorksData/ProjectData/Maxius/Img/Contents/image (6).jpg';
import Maxius7Img from '../../Data/WorksData/ProjectData/Maxius/Img/Contents/image (7).jpg';
import Maxius8Img from '../../Data/WorksData/ProjectData/Maxius/Img/Contents/image (8).jpg';
import Maxius9Img from '../../Data/WorksData/ProjectData/Maxius/Img/Contents/image (9).jpg';
import Maxius10Img from '../../Data/WorksData/ProjectData/Maxius/Img/Contents/image (10).jpg';
import Maxius11Img from '../../Data/WorksData/ProjectData/Maxius/Img/Contents/image (11).jpg';
import Maxius12Img from '../../Data/WorksData/ProjectData/Maxius/Img/Contents/image (12).jpg';
import Maxius13Img from '../../Data/WorksData/ProjectData/Maxius/Img/Contents/image (13).jpg';
import Maxius14Img from '../../Data/WorksData/ProjectData/Maxius/Img/Contents/image (14).jpg';
import Maxius15Img from '../../Data/WorksData/ProjectData/Maxius/Img/Contents/image (15).jpg';
import Maxius16Img from '../../Data/WorksData/ProjectData/Maxius/Img/Contents/image (16).jpg';
import Maxius17Img from '../../Data/WorksData/ProjectData/Maxius/Img/Contents/image (17).jpg';
import Maxius18Img from '../../Data/WorksData/ProjectData/Maxius/Img/Contents/image (18).jpg';
import Maxius19Img from '../../Data/WorksData/ProjectData/Maxius/Img/Contents/image (19).jpg';
import Maxius20Img from '../../Data/WorksData/ProjectData/Maxius/Img/Contents/image (20).jpg';
import Maxius21Img from '../../Data/WorksData/ProjectData/Maxius/Img/Contents/image (21).jpg';
import Maxius22Img from '../../Data/WorksData/ProjectData/Maxius/Img/Contents/image (22).jpg';
import Maxius23Img from '../../Data/WorksData/ProjectData/Maxius/Img/Contents/image (23).jpg';
import Maxius24Img from '../../Data/WorksData/ProjectData/Maxius/Img/Contents/image (24).jpg';
import Maxius25Img from '../../Data/WorksData/ProjectData/Maxius/Img/Contents/image (25).jpg';
import Maxius26Img from '../../Data/WorksData/ProjectData/Maxius/Img/Contents/image (26).jpg';
import Maxius27Img from '../../Data/WorksData/ProjectData/Maxius/Img/Contents/image (27).jpg';
import Maxius28Img from '../../Data/WorksData/ProjectData/Maxius/Img/Contents/image (28).jpg';
import Maxius29Img from '../../Data/WorksData/ProjectData/Maxius/Img/Contents/image (29).jpg';
import Maxius30Img from '../../Data/WorksData/ProjectData/Maxius/Img/Contents/image (30).jpg';
import Maxius31Img from '../../Data/WorksData/ProjectData/Maxius/Img/Contents/image (31).jpg';
import Maxius32Img from '../../Data/WorksData/ProjectData/Maxius/Img/Contents/image (32).jpg';
import Maxius33Img from '../../Data/WorksData/ProjectData/Maxius/Img/Contents/image (33).jpg';
import Maxius34Img from '../../Data/WorksData/ProjectData/Maxius/Img/Contents/image (34).jpg';
import Maxius35Img from '../../Data/WorksData/ProjectData/Maxius/Img/Contents/image (35).jpg';
import Maxius36Img from '../../Data/WorksData/ProjectData/Maxius/Img/Contents/image (36).jpg';
import Maxius37Img from '../../Data/WorksData/ProjectData/Maxius/Img/Contents/image (37).jpg';
import Maxius38Img from '../../Data/WorksData/ProjectData/Maxius/Img/Contents/image (38).jpg';

export default {
	list: [
		//추가//
		{
			id: "Maxius",
			category: 2,
			img: MaxiusThum,
			// vimeo: "https://player.vimeo.com/video/237692139",
			video: null,
			imges: [Maxius1Img, Maxius2Img, Maxius3Img, Maxius4Img, Maxius5Img, Maxius6Img, Maxius7Img, Maxius8Img, Maxius9Img, Maxius10Img,
				Maxius11Img, Maxius12Img, Maxius13Img, Maxius14Img, Maxius15Img, Maxius16Img, Maxius17Img, Maxius18Img, Maxius19Img, Maxius20Img,
				Maxius21Img, Maxius22Img, Maxius23Img, Maxius24Img, Maxius25Img, Maxius26Img, Maxius27Img, Maxius28Img, Maxius29Img, Maxius30Img,
				Maxius31Img, Maxius32Img, Maxius33Img, Maxius34Img, Maxius35Img, Maxius36Img, Maxius37Img, Maxius38Img],
			title: "Maxius",
			sub: "Homepage Development",
			name: "Maxius",
			text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
		},
		{
			id: "LocalMoney",
			category: 4,
			img: LocalMoneyThum,
			// vimeo: "https://player.vimeo.com/video/237692139 ,
			video: null,
			imges: [LocalMoney1Img],
			title: "LocalMoney",
			sub: "Homepage Development",
			name: "LocalMoney",
			text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
		},
		{
			id: "ConceptCube",
			category: 2,
			img: ConceptCubeThum,
			// vimeo: "https://player.vimeo.com/video/237692139",
			video: null,
			imges: [ConceptCube1Img, ConceptCube2Img, ConceptCube3Img, ConceptCube4Img, ConceptCube5Img, ConceptCube6Img, ConceptCube7Img, ConceptCube8Img, ConceptCube9Img, ConceptCube10Img,
				ConceptCube11Img, ConceptCube12Img, ConceptCube13Img, ConceptCube14Img, ConceptCube15Img, ConceptCube16Img, ConceptCube17Img, ConceptCube18Img, ConceptCube19Img, ConceptCube20Img,
				ConceptCube21Img, ConceptCube22Img, ConceptCube23Img, ConceptCube24Img, ConceptCube25Img, ConceptCube26Img, ConceptCube27Img, ConceptCube28Img, ConceptCube29Img, ConceptCube30Img,
				ConceptCube31Img, ConceptCube32Img, ConceptCube33Img, ConceptCube34Img, ConceptCube35Img, ConceptCube36Img],
			title: "ConceptCube",
			sub: "Homepage Development",
			name: "ConceptCube",
			text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
		},
		{
			id: "Servekit",
			category: 2,
			img: ServekitThum,
			// vimeo: "https://player.vimeo.com/video/237692139",
			video: null,
			imges: [Servekit1Img, Servekit2Img, Servekit3Img, Servekit4Img, Servekit5Img, Servekit6Img, Servekit7Img, Servekit8Img, Servekit9Img, Servekit10Img,
				Servekit11Img, Servekit12Img, Servekit13Img, Servekit14Img, Servekit15Img, Servekit16Img, Servekit17Img, Servekit18Img, Servekit19Img, Servekit20Img,
				Servekit21Img, Servekit22Img, Servekit23Img, Servekit24Img, Servekit25Img, Servekit26Img, Servekit27Img, Servekit28Img, Servekit29Img, Servekit30Img,
				Servekit31Img, Servekit32Img, Servekit33Img, Servekit34Img, Servekit35Img, Servekit36Img, Servekit37Img, Servekit38Img, Servekit39Img, Servekit40Img,],
			title: "Servekit",
			sub: "Homepage Development",
			name: "Servekit",
			text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
		},
		{
			id: "Methin",
			category: 2,
			img: MethinThum,
			// vimeo: "https://player.vimeo.com/video/237692139",
			video: null,
			imges: [Methin1Img, Methin2Img, Methin3Img, Methin4Img, Methin5Img, Methin6Img, Methin7Img, Methin8Img, Methin9Img, Methin10Img,
				Methin11Img, Methin12Img, Methin13Img, Methin14Img, Methin15Img, Methin16Img, Methin17Img, Methin18Img, Methin19Img, Methin20Img,
				Methin21Img, Methin22Img, Methin23Img, Methin24Img, Methin25Img, Methin26Img, Methin27Img, Methin28Img, Methin29Img, Methin30Img,
				Methin31Img, Methin32Img, Methin33Img, Methin34Img, Methin35Img, Methin36Img, Methin37Img, Methin38Img, Methin39Img, Methin40Img,
				Methin41Img, Methin42Img, Methin43Img, Methin44Img, Methin45Img, Methin46Img, Methin47Img, Methin48Img, Methin49Img, Methin50Img,
				Methin51Img, Methin52Img, Methin53Img, Methin54Img, Methin55Img, Methin56Img, Methin57Img, Methin58Img
			],
			title: "Methin",
			sub: "Homepage Development",
			name: "Methin",
			text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
		},
		{
			id: "Sejong",
			category: 4,
			img: SejongThum,
			// vimeo: "https://player.vimeo.com/video/237692139",
			video: null,
			imges: [Sejong1Img],
			title: "Sejong",
			sub: "Homepage Development",
			name: "Sejong",
			text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
		},
		{
			id: "KKAL",
			category: 2,
			img: KKALThum,
			// vimeo: "https://player.vimeo.com/video/237692139",
			video: null,
			imges: [KKAL1Img, KKAL2Img, KKAL3Img, KKAL4Img, KKAL5Img, KKAL6Img, KKAL7Img, KKAL8Img, KKAL9Img, KKAL10Img,
				KKAL11Img, KKAL12Img, KKAL13Img, KKAL14Img, KKAL15Img, KKAL16Img, KKAL17Img, KKAL18Img, KKAL19Img, KKAL20Img,
				KKAL21Img, KKAL22Img, KKAL23Img, KKAL24Img, KKAL25Img, KKAL26Img, KKAL27Img, KKAL28Img, KKAL29Img, KKAL30Img,
				KKAL31Img, KKAL32Img, KKAL33Img, KKAL34Img, KKAL35Img, KKAL36Img],
			title: "KKAL",
			sub: "Homepage Development",
			name: "KKAL",
			text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
		},
		{
			id: "UIX",
			category: 2,
			img: UIXThum,
			// vimeo: "https://player.vimeo.com/video/237692139",
			video: null,
			imges: [UIX0Img, UIX1Img, UIX2Img, UIX3Img, UIX4Img, UIX5Img, UIX6Img, UIX7Img, UIX8Img, UIX9Img, UIX10Img,
				UIX11Img, UIX12Img, UIX13Img, UIX14Img, UIX15Img, UIX16Img, UIX17Img, UIX18Img, UIX19Img],
			title: "UIX",
			sub: "Homepage Development",
			name: "UIX",
			text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
		},
		{
			id: "Nomad",
			category: 2,
			img: NomadThum,
			// vimeo: "https://player.vimeo.com/video/237692139",
			video: null,
			imges: [Nomad1Img, Nomad2Img, Nomad3Img, Nomad4Img, Nomad5Img, Nomad6Img, Nomad7Img, Nomad8Img, Nomad9Img, Nomad10Img,
				Nomad11Img, Nomad12Img, Nomad13Img, Nomad14Img, Nomad15Img, Nomad16Img, Nomad17Img, Nomad18Img, Nomad19Img, Nomad20Img,
				Nomad21Img, Nomad22Img, Nomad23Img, Nomad24Img, Nomad25Img,],
			title: "Nomad",
			sub: "Homepage Development",
			name: "Nomad",
			text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
		},
		{
			id: "Videonly",
			category: 2,
			img: VideonlyThum,
			// vimeo: "https://player.vimeo.com/video/237692139",
			video: null,
			imges: [Videonly1Img, Videonly2Img, Videonly3Img, Videonly4Img, Videonly5Img, Videonly6Img, Videonly7Img, Videonly8Img, Videonly9Img, Videonly10Img,
				Videonly11Img, Videonly12Img, Videonly13Img, Videonly14Img, Videonly15Img, Videonly16Img, Videonly17Img, Videonly18Img, Videonly19Img, Videonly20Img,
				Videonly21Img, Videonly22Img, Videonly23Img, Videonly24Img, Videonly25Img, Videonly26Img, Videonly27Img, Videonly28Img, Videonly29Img, Videonly30Img,
				Videonly31Img, Videonly32Img, Videonly33Img, Videonly34Img, Videonly35Img, Videonly36Img, Videonly37Img, Videonly38Img, Videonly39Img, Videonly40Img,
				Videonly41Img, Videonly42Img, Videonly43Img, Videonly44Img,
			],
			title: "Videonly",
			sub: "Homepage Development",
			name: "Videonly",
			text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
		},
		{
			id: "Artificialcity",
			category: 4,
			img: ArtificialcityThum,
			// vimeo: "https://player.vimeo.com/video/237692139",
			video: null,
			imges: [Artificialcity1Img],
			title: "Artificialcity",
			sub: "Homepage Development",
			name: "Artificialcity",
			text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
		},

		{
			id: "WHT30",
			category: 2,
			img: WHT30Thum,
			// vimeo: "https://player.vimeo.com/video/237692139",
			video: null,
			imges: [WHT30_1Img, WHT30_2Img, WHT30_3Img, WHT30_4Img, WHT30_5Img, WHT30_6Img, WHT30_7Img, WHT30_8Img, WHT30_9Img, WHT30_10Img,
				WHT30_11Img, WHT30_12Img, WHT30_13Img, WHT30_14Img, WHT30_15Img, WHT30_16Img, WHT30_17Img, WHT30_18Img, WHT30_19Img, WHT30_20Img,
				WHT30_21Img, WHT30_22Img, WHT30_23Img, WHT30_24Img, WHT30_25Img, WHT30_26Img, WHT30_27Img, WHT30_28Img, WHT30_29Img, WHT30_30Img,
				WHT30_31Img, WHT30_32Img, WHT30_33Img, WHT30_34Img, WHT30_35Img, WHT30_36Img, WHT30_37Img, WHT30_38Img, WHT30_39Img, WHT30_40Img,
				WHT30_41Img, WHT30_42Img, WHT30_43Img, WHT30_44Img, WHT30_45Img, WHT30_46Img, WHT30_47Img, WHT30_48Img, WHT30_49Img, WHT30_50Img,
				WHT30_51Img, WHT30_52Img, WHT30_53Img, WHT30_54Img, WHT30_55Img, WHT30_56Img, WHT30_57Img, WHT30_58Img, WHT30_59Img, WHT30_60Img,
				WHT30_61Img, WHT30_62Img, WHT30_63Img, WHT30_64Img, WHT30_65Img, WHT30_66Img, WHT30_67Img, WHT30_68Img, WHT30_69Img, WHT30_70Img,
				WHT30_71Img, WHT30_72Img

			],
			title: "WHT30",
			sub: "Homepage Development",
			name: "WHT30",
			text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
		},
		{
			id: "GuardSignature",
			category: 2,
			img: GuardSignatureThum,
			// vimeo: "https://player.vimeo.com/video/237692139",
			video: null,
			imges: [GuardSignature1Img, GuardSignature2Img, GuardSignature3Img, GuardSignature4Img, GuardSignature5Img, GuardSignature6Img, GuardSignature7Img, GuardSignature8Img, GuardSignature9Img, GuardSignature10Img,
				GuardSignature11Img, GuardSignature12Img, GuardSignature13Img, GuardSignature14Img, GuardSignature15Img, GuardSignature16Img, GuardSignature17Img, GuardSignature18Img, GuardSignature19Img, GuardSignature20Img,
				GuardSignature21Img, GuardSignature22Img, GuardSignature23Img, GuardSignature24Img, GuardSignature25Img, GuardSignature26Img, GuardSignature27Img, GuardSignature28Img, GuardSignature29Img, GuardSignature30Img,
				GuardSignature31Img, GuardSignature32Img, GuardSignature33Img, GuardSignature34Img, GuardSignature35Img, GuardSignature36Img
			],
			title: "GuardSignature",
			sub: "Homepage Development",
			name: "GuardSignature",
			text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
		},
		{
			id: "ㅅㅇㄹ",
			category: 2,
			img: ㅅㅇㄹThum,
			// vimeo: "https://player.vimeo.com/video/237692139",
			video: null,
			imges: [ㅅㅇㄹ1Img, ㅅㅇㄹ2Img, ㅅㅇㄹ3Img, ㅅㅇㄹ4Img, ㅅㅇㄹ5Img, ㅅㅇㄹ6Img, ㅅㅇㄹ7Img, ㅅㅇㄹ8Img, ㅅㅇㄹ9Img, ㅅㅇㄹ10Img,
				ㅅㅇㄹ11Img, ㅅㅇㄹ12Img, ㅅㅇㄹ13Img, ㅅㅇㄹ14Img, ㅅㅇㄹ15Img, ㅅㅇㄹ16Img, ㅅㅇㄹ17Img, ㅅㅇㄹ18Img, ㅅㅇㄹ19Img, ㅅㅇㄹ20Img,
				ㅅㅇㄹ21Img, ㅅㅇㄹ22Img, ㅅㅇㄹ23Img
			],
			title: "ㅅㅇㄹ",
			sub: "Homepage Development",
			name: "ㅅㅇㄹ",
			text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
		},
		{
			id: "FarmPharm",
			category: 2,
			img: FarmPharmThum,
			// vimeo: "https://player.vimeo.com/video/237692139",
			video: null,
			imges: [FarmPharm1Img],
			title: "FarmPharm",
			sub: "Homepage Development",
			name: "FarmPharm",
			text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
		},
		{
			id: "Yame",
			category: 2,
			img: YameThum,
			// vimeo: "https://player.vimeo.com/video/237692139",
			video: null,
			imges: [Yame1Img],
			title: "Yame",
			sub: "Homepage Development",
			name: "Yame",
			text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
		},
		{
			id: "Icarus",
			category: 4,
			img: IcarusThum,
			// vimeo: "https://player.vimeo.com/video/237692139",
			video: null,
			imges: [Icarus1Img],
			title: "Icarus",
			sub: "Homepage Development",
			name: "MotionTrend",
			text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
		},
		{
			id: "MotionTrend",
			category: 4,
			img: MotionTrendThum,
			// vimeo: "https://player.vimeo.com/video/237692139",
			video: null,
			imges: [MotionTrend1Img, MotionTrend2Img, MotionTrend3Img, MotionTrend4Img, MotionTrend5Img, MotionTrend6Img, MotionTrend7Img, MotionTrend8Img, MotionTrend9Img],
			title: "MotionTrend",
			sub: "Homepage Development",
			name: "MotionTrend",
			text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
		},
		{
			id: "Prong",
			category: 1,
			img: ProngThum,
			// vimeo: "https://player.vimeo.com/video/237692139",
			video: null,
			imges: [Prong1Img, Prong2Img, Prong3Img, Prong4Img, Prong5Img, Prong6Img, Prong7Img, Prong8Img, Prong9Img, Prong10Img],
			title: "Prong",
			sub: "Homepage Development",
			name: "Prong",
			text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
		},
		{
			id: "Lennon",
			category: 1,
			img: LennonThum,
			// vimeo: "https://player.vimeo.com/video/237692139",
			video: null,
			imges: [Lennon1Img, Lennon2Img, Lennon3Img, Lennon4Img, Lennon5Img, Lennon6Img, Lennon7Img],
			title: "Lennon",
			sub: "Homepage Development",
			name: "Lennon",
			text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
		},
		{
			id: "TheNulli",
			category: 1,
			img: TheNulliThum,
			// vimeo: "https://player.vimeo.com/video/237692139",
			video: null,
			imges: [TheNulli1Img, TheNulli2Img, TheNulli3Img, TheNulli4Img, TheNulli5Img, TheNulli6Img, TheNulli7Img, TheNulli8Img, TheNulli9Img],
			title: "TheNulli",
			sub: "Homepage Development",
			name: "TheNulli",
			text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
		},
		{
			id: "SejongSi",
			category: 1,
			img: SejongSiThum,
			// vimeo: "https://player.vimeo.com/video/237692139",
			video: null,
			imges: [SejongSi1Img],
			title: "SejongSi",
			sub: "Homepage Development",
			name: "SejongSi",
			text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
		},
		{
			id: "ToastCanvas",
			category: 1,
			img: ToastCanvasThum,
			// vimeo: "https://player.vimeo.com/video/237692139",
			video: null,
			imges: [ToastCanvas1Img, ToastCanvas2Img, ToastCanvas3Img, ToastCanvas4Img, ToastCanvas5Img, ToastCanvas6Img, ToastCanvas7Img, ToastCanvas8Img],
			title: "ToastCanvas",
			sub: "Homepage Development",
			name: "ToastCanvas",
			text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
		},
		{
			id: "Exon",
			category: 1,
			img: ExonThum,
			// vimeo: "https://player.vimeo.com/video/237692139",
			video: null,
			imges: [Exon1Img, Exon2Img, Exon3Img, Exon4Img, Exon5Img, Exon6Img, Exon7Img, Exon8Img, Exon9Img, Exon10Img],
			title: "Exon",
			sub: "Homepage Development",
			name: "Exon",
			text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
		},
		{
			id: "HanyangAR",
			category: 1,
			img: HanyangARThum,
			// vimeo: "https://player.vimeo.com/video/237692139",
			video: null,
			imges: [HanyangAR1Img, HanyangAR2Img, HanyangAR3Img, HanyangAR4Img, HanyangAR5Img, HanyangAR6Img, HanyangAR7Img],
			title: "HanyangAR",
			sub: "Homepage Development",
			name: "HanyangAR",
			text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
		},
		{
			id: "SamsungPMS",
			category: 1,
			img: SamsungPMSThum,
			// vimeo: "https://player.vimeo.com/video/237692139",
			video: null,
			imges: [SamsungPMS1Img],
			title: "SamsungPMS",
			sub: "Kiosk & Android Development",
			name: "SamsungPMS",
			text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
		},
		{
			id: "Kotra",
			category: 1,
			img: KotraThum,
			// vimeo: "https://player.vimeo.com/video/237692139",
			video: null,
			imges: [Kotra1Img, Kotra2Img, Kotra3Img, Kotra4Img, Kotra5Img, Kotra6Img, Kotra7Img],
			title: "Kotra",
			sub: "Homepage Development",
			name: "Kotra",
			text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
		},
		{
			id: "Famoz",
			category: 1,
			img: FamozThum,
			// vimeo: "https://player.vimeo.com/video/237692139",
			video: null,
			imges: [Famoz1Img],
			title: "Famoz",
			sub: "Homepage Development",
			name: "Famoz",
			text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
		},
		{
			id: "VideoMonster",
			category: 1,
			img: VideoMonsterThum,
			// vimeo: "https://player.vimeo.com/video/237692139",
			video: null,
			imges: [VideoMonster1Img, VideoMonster2Img, VideoMonster3Img, VideoMonster4Img, VideoMonster5Img, VideoMonster6Img, VideoMonster7Img, VideoMonster8Img],
			title: "VideoMonster",
			sub: "Homepage Development",
			name: "VideoMonster",
			text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
		},

		//추가//
		{
			id: "KimMuseum",
			category: 1,
			img: KimChungUpMuseumThum,
			vimeo: "https://player.vimeo.com/video/151469589",
			video: null,
			imges: [KimChungUpMuseum1Img, KimChungUpMuseum2Img, KimChungUpMuseum3Img],
			title: "KimChungUpMuseum",
			sub: "Homepage Development",
			name: "KimChungUpMuseum",
			text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
		},
		{
			id: "Jurassic",
			category: 1,
			img: JurassicThum,
			vimeo: "https://player.vimeo.com/video/196838613",
			video: null,
			imges: [Jurassic1Img, Jurassic2Img],
			title: "Anmyeondo Jurassic Museum",
			sub: "Homepage Development",
			name: "쥬라기",
			text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
		},
		{
			id: "Galleria",
			category: 1,
			img: GalleriaThum,
			vimeo: "https://player.vimeo.com/video/151468979",
			video: null,
			imges: [Galleria1Img, Galleria2Img, Galleria3Img, Galleria4Img, Galleria5Img, Galleria6Img],
			title: "Gangnam Galleria",
			sub: "Homepage Development",
			name: "Gangnam Galleria",
			text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
		},
		{
			id: "RailPark",
			category: 1,
			img: RailParkThum,
			vimeo: "https://player.vimeo.com/video/244931138",
			video: null,
			imges: [RailPark1Img, RailPark2Img, RailPark3Img, RailPark5Img, RailPark6Img, RailPark8Img],
			title: "Uiwang-RailPark",
			sub: "Homepage Development",
			name: "UiwangRailPark",
			text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
		},
		{
			id: "SumsungSds",
			category: 1,
			img: SamsungSdsThum,
			vimeo: "https://player.vimeo.com/video/151345247",
			video: null,
			imges: [SamsungSds1Img, SamsungSds2Img, SamsungSds3Img, SamsungSds4Img, SamsungSds5Img, SamsungSds6Img, SamsungSds7Img, SamsungSds8Img, SamsungSds9Img, SamsungSds10Img, SamsungSds11Img],
			title: "SumsungSDS",
			sub: "Homepage Development",
			name: "SumsungSDS",
			text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
		},
		{
			id: "SportsMonster",
			category: 1,
			img: SportsMonsterThum,
			vimeo: "https://player.vimeo.com/video/237692139",
			video: null,
			imges: [SportsMonster1Img, SportsMonster2Img, SportsMonster3Img, SportsMonster9Img, SportsMonster10Img, SportsMonster12Img, SportsMonster14Img],
			title: "SportsMonster",
			sub: "Homepage Development",
			name: "SportsMonster",
			text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
		},
		{
			id: "LGDisplay",
			category: 1,
			img: LGDisplayThum,
			vimeo: "https://player.vimeo.com/video/244596501",
			video: null,
			imges: [LGDisplay1Img, LGDisplay2Img, LGDisplay4Img, LGDisplay7Img, LGDisplay11Img],
			title: "LGDisplay",
			sub: "Homepage Development",
			name: "LGDisplay",
			text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
		},
		{
			id: "SamsungSds2",
			category: 1,
			img: SamsungSds2Thum,
			vimeo: "https://player.vimeo.com/video/187940773",
			video: null,
			imges: [SamsungSds21Img, SamsungSds22Img, SamsungSds23Img],
			title: "Samsung SDS 3WALL CINERMA",
			sub: "Homepage Development",
			name: "Samsung SDS 3WALL CINERMA",
			text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
		},

		{
			id: "SkyPark",
			category: 1,
			img: SkyParkThum,
			vimeo: "https://player.vimeo.com/video/245166605",
			video: null,
			imges: [SkyPark1Img, SkyPark2Img, SkyPark3Img, SkyPark5Img, SkyPark6Img, SkyPark7Img, SkyPark9Img, SkyPark10Img, SkyPark13Img, SkyPark27Img,],
			title: "Taean Shooting Star Sky Park",
			sub: "Homepage Development",
			name: "Taean Shooting Star Sky Park",
			text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
		},
		{
			id: "ChinaLibrary",
			category: 1,
			img: ChinaLibraryThum,
			vimeo: "https://player.vimeo.com/video/151366504",
			video: null,
			imges: [ChinaLibrary1Img, ChinaLibrary2Img, ChinaLibrary3Img, ChinaLibrary4Img],
			title: "Zhengzhou Library",
			sub: "Homepage Development",
			name: "Zhengzhou Library",
			text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
		},
		{
			id: "HistoryMuseum",
			category: 1,
			img: HistoryMuseumThum,
			vimeo: "https://player.vimeo.com/video/151469236",
			video: null,
			imges: [HistoryMuseum1Img, HistoryMuseum2Img, HistoryMuseum3Img],
			title: "Chong Kun Dang",
			sub: "Homepage Development",
			name: "Chong Kun Dang",
			text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
		},
		{
			id: "Kist",
			category: 1,
			img: KistThum,
			vimeo: "https://player.vimeo.com/video/151470243",
			video: null,
			imges: [Kist1Img, Kist2Img, Kist3Img, Kist4Img],
			title: "KAIST Community Lounge",
			sub: "Homepage Development",
			name: "KAIST Community Lounge",
			text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
		},
		{
			id: "DDP",
			category: 1,
			img: DDPThum,
			vimeo: "https://player.vimeo.com/video/151467892",
			video: null,
			imges: [DDP1Img, DDP2Img, DDP3Img],
			title: "Dongdaemun Digital Plaza",
			sub: "Homepage Development",
			name: "Dongdaemun Digital Plaza",
			text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
		},
		{
			id: "Aquarium",
			category: 1,
			img: AquariumThum,
			vimeo: "https://player.vimeo.com/video/151466362",
			video: null,
			imges: [Aquarium1Img, Aquarium2Img, Aquarium3Img, Aquarium4Img, Aquarium5Img],
			title: "Lotte World Aquarium",
			sub: "Homepage Development",
			name: "Lotte World Aquarium",
			text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
		},
		{
			id: "KimWall",
			category: 1,
			img: KimChungUpWallThum,
			vimeo: "https://player.vimeo.com/video/151469589",
			video: null,
			imges: [KimChungUpWall1Img, KimChungUpWall2Img, KimChungUpWall3Img, KimChungUpWall4Img],
			title: "KimChungUpMuseum Wall Library",
			sub: "Homepage Development",
			name: "KimChungUpMuseum Wall Library",
			text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
		},
		{
			id: "ComedyTown",
			category: 1,
			img: ComedyTownThum,
			vimeo: "https://player.vimeo.com/video/196838939",
			video: null,
			imges: [ComedyTown1Img, ComedyTown2Img],
			title: "Cheongdo Comedy Town",
			sub: "Homepage Development",
			name: "Cheongdo Comedy Town",
			text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
		},
		// {
		// 	id: "BetterSamsung",
		// 	category: 1,
		// 	img: BetterSamsung,
		// 	fullScreenImg: fullScreenBetterSamsung,
		// 	title: "Provider",
		// 	sub: "Homepage renewal",
		// 	name: "Better Samsung",
		// 	text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas.",
		// },
		// {
		// 	id: "Happened",
		// 	category: 3,
		// 	img: Happened,
		// 	fullScreenImg: fullScreenHappened,
		// 	title: "Provider",
		// 	sub: "Homepage renewal",
		// 	name: "What Happened to 30's",
		// 	text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas."
		// },
		// {
		// 	id: "Hyundai_rima",
		// 	category: 2,
		// 	img: Hyundai_rima,
		// 	fullScreenImg: fullScreenHyundaiRima,
		// 	title: "Provider",
		// 	sub: "Homepage renewal",
		// 	name: "Hyundai Card - Rima",
		// 	text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas."
		// },
		// {
		// 	id: "MilkShake",
		// 	category: 1,
		// 	img: MilkShake,
		// 	fullScreenImg: fullScreenMilkShake,
		// 	title: "Provider",
		// 	sub: "Homepage renewal",
		// 	name: "Milkshake M/V",
		// 	text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas."

		// },
		// {
		// 	id: "Priest",
		// 	category: 1,
		// 	img: Priest,
		// 	fullScreenImg: fullScreenPriest,
		// 	title: "Provider",
		// 	sub: "Homepage renewal",
		// 	name: "Priest",
		// 	text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas."
		// },
		// {
		// 	id: "Circle",
		// 	category: 2,
		// 	img: Circle,
		// 	fullScreenImg: fullScreenCircle,
		// 	title: "Provider",
		// 	sub: "Homepage renewal",
		// 	name: "Circle",
		// 	text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas."
		// },
		// {
		// 	id: "Skylife",
		// 	category: 3,
		// 	img: Skylife,
		// 	fullScreenImg: fullScreenSkylife,
		// 	title: "Provider",
		// 	sub: "Homepage renewal",
		// 	name: "Skylife",
		// 	text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas."
		// },
		// // {
		// // 	id: "Daisy",
		// // 	category: 7,
		// // 	img: Daisy,
		// // 	fullScreenImg: ProjectData.Daisy
		// // },
		// {
		// 	id: "Timing",
		// 	category: 2,
		// 	img: Timing,
		// 	fullScreenImg: fullScreenTiming,
		// 	title: "Provider",
		// 	sub: "Homepage renewal",
		// 	name: "Timing Alice",
		// 	text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas."
		// },
		// {
		// 	id: "TimingDark",
		// 	category: 2,
		// 	img: TimingDark,
		// 	fullScreenImg: fullScreenTimingDark,
		// 	title: "Provider",
		// 	sub: "Homepage renewal",
		// 	name: "Timing Alice",
		// 	text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas."
		// },
		// {
		// 	id: "Lyn",
		// 	category: 1,
		// 	img: Lyn,
		// 	fullScreenImg: fullScreenLyn,
		// 	title: "Provider",
		// 	sub: "Homepage renewal",
		// 	name: "Lyn - the Lightbringer",
		// 	text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas."
		// },
		// {
		// 	id: "Victor",
		// 	category: 1,
		// 	img: Victor,
		// 	fullScreenImg: fullScreenVictor,
		// 	title: "Provider",
		// 	sub: "Homepage renewal",
		// 	name: "Victor - for Elise",
		// 	text: "I think creativity is the driving force for people to grow. That is why people always think about it. UIX understands and empathizes with customers' wishes and divides creative ideas."
		// },
		// // {
		// // 	id: "tv29",
		// // 	category: 3,
		// // 	img: tv29,
		// // 	fullScreenImg: ProjectData.tv29
		// // },
	],
};