import React, { Component } from 'react';
import "./AboutMiddle.scss"
import FirstAboutImg from '../../../_asset/images/mainback/about-img-4@2x.png'
import SeconAboutdImg from '../../../_asset/images/mainback/about-img-5@2x.png'
import RightArrow from '../../../_asset/images/mainback/left-arrow-icon (1).svg'
import LeftArrow from '../../../_asset/images/mainback/left-arrow-icon.svg'
import Slider from 'react-slick';

class AboutMiddle extends Component {
    items = [{
        image: SeconAboutdImg,
        text: "EVERY DESIGN HAS ITS REASONS.",
        id: 1,
    }, {
        image: SeconAboutdImg,
        text: "EVERY DESIGN HAS ITS REASONS.",
        id: 2,
    }, {
        image: SeconAboutdImg,
        text: "EVERY DESIGN HAS ITS REASONS.",
        id: 3,
    }]
    renderSliderItems = (items) => (
        items.map(({ image, text, id }, index) => (
            <div className='slider-item' key={`${id}-${index}`}>
                <img className='SeconAboutdImg' src={image} alt='SeconAboutdImg' />
                <div className='ImgFont'>
                    {text}
                </div>
            </div>
        ))
    )
    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: <div className="nextArrow"> <img src={RightArrow} alt='RightArrow' /> </div>,
            prevArrow: <div className="prevArrow">  <img src={LeftArrow} alt='LeftArrow' /> </div>,
        };
        return (
            <div className="AboutMiddleContainer" id="REASON">
                <div className="AboutMiddleMain">
                    <div className='FirstContainer'>
                        <div className="TextBox">
                            <div className='Value'>
                                02 / Second Value.
                            </div>
                            <div className='Title'>
                                Reason
                            </div>
                            <div className='SubText'>
                            Every development has its reasons.<br />
                            Every design has its reasons.<br />
                            We make a beautiful development.<br />
                            It's practical and sure.
                            </div>
                        </div>
                        <div className='FirstImg'>
                            <img className='FirstAboutImg' src={FirstAboutImg} alt='FirstAboutImg' />
                        </div>
                    </div>

                    <div className="aboutMiddle-slider">
                        <Slider {...settings}>
                            {this.renderSliderItems(this.items)}
                        </Slider>
                        {/* <div className='ImgFontArrow'>
                            <div className='AllArrow'>
                                <button className='LeftArrow'>
                                    <img src={LeftArrow} alt='LeftArrow' />
                                </button>
                                <button className='RightArrow'>
                                    <img src={RightArrow} alt='RightArrow' />
                                </button>
                            </div>


                        </div>
                  */}
                    </div>
                </div>

                <div className='MainText'>
                    UIX always <span>thinks.</span><br />
                    Understand what <span>customers</span> want<br />
                    and empathize with them and have a conversation<br />
                    <span>creative ideas</span>.
                </div>
            </div>
        );
    }
}

export default AboutMiddle;