import React from 'react'
import img16 from "../../../../_asset/images/toast/f-logo.png";
import img21 from "../../../../_asset/images/toast/inst-icon.png";
import img26 from "../../../../_asset/images/toast/youtube-icon.png";
import "./styles.scss"

function Footer() {
  return (
    <div className="footer">
      <div className="footer-container">
        <img className="footer__logo" src={img16} alt="" />
        <div className="footer__address">
          <p>
            4F-411, 125, Wangsimni-ro, Seongdong-gu, Seoul, Republic of Korea
            uix.hello@gmail.com
          </p>
          <p>Copyrightⓒ UIX All rights reserved.</p>
        </div>
        <div className="footer__link">
          <div className="footer__icon">
            <img src={img21} alt="" />
            <p>Instagram</p>
          </div>
          <div className="footer__icon">
            <img src={img26} alt="" />
            <p>Youtube</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer