import React from "react";
import styled from "styled-components";
import "./style/Poverflow.scss";
import circleimg from "../../../../_asset/images/Prorenata/img_prn1.png";

const LeftText = styled.div`
  overflow: hidden;
  margin: 100px 109px 0 0;
`;
const RightText = styled.div`
  overflow: hidden;
  width: 575px;
  height: 507px;
  margin-top: 137px;
  > img {
    width: 100%;
  }
`;

const Poverflow = () => {
  return (
    <div className="Poverflow_container">
      <div className="over_cont">
        <LeftText>
          <div className="over_tit">Project Overview</div>
          <div className="over_txt">
            프로레나타는 2017년 카탈리스트가 새롭게<br></br>
            런칭하는 브랜드로서, 피부에 좋은 천연 원료와 <br></br>
            전문적인 바이오 기술력을 바탕으로 피부<br></br>
            상황에 따른 즉각적인 개선 효과를 부여하고, <br></br>
            피부 고민에 대한 체계적인 솔루션을 제시하는 <br></br>
            바이오슈티컬 스킨케어 브랜드입니다. <br></br>
            <br></br>
            코스메틱 시장에서 환경과 유해성분에 대한 <br></br>
            문제 의식이 대두됨에 따라 무첨가, 무방부제 <br></br>
            등의 이슈가 트렌드로 자리 잡았으며,<br></br>
            피부과를 시작으로 국내외의 브랜드들이<br></br>
            저마다의 기술력을 강조하고 있었습니다. <br></br>
            프로레나타는 포화된 스킨케어 시장에서 <br></br>
            후발로 시작하는 브랜드로 소비자 인식 속에<br></br>
            강력하게 자리 잡기 위해 브랜드 차원에서의<br></br>
            차별적인 메시지와 브랜드 아이덴티티가<br></br>
            필요하였습니다.
          </div>
        </LeftText>
        <RightText>
          <img src={circleimg} alt="over_img" />
        </RightText>
      </div>
    </div>
  );
};

export default Poverflow;
