import React, { useEffect, useState } from "react";
import "./style/Eoverflow.scss";
import styled from "styled-components";
import EmartIco from "../../../../_asset/images/emart/ico_launcher.png";

let list = [
  {
    title: "Study",
    text01: "이마트 웹/앱 스터디",
    text02: "실무진 인터뷰",
    text03: "사용자 리서치",
    text04: "통계 데이터 스터디",
  },
  { title: "Analysis", text01: "실 데이터 분석", text02: "서비스 방향 도출" },
  { title: "Concept", text01: "콘셉 도출", text02: "아이디어 구체화" },
  { title: "Output", text01: "디자인 시안 제작" },
];

const IconBox = styled.div`
  margin-bottom: 100px;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 1s linear;
  > img {
    border-radius: 20px;
    box-shadow: 12px 12px 30px rgba(34, 34, 34, 0.06);
  }
`;
const EoverTextBox = styled.div`
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 1s linear;
`;

const Process = styled.div`
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 2s linear;
  transition: transform 1.3s linear;
  display: flex;
  justify-content: space-between;
`;
const Eoverflow = ({ pageY }) => {
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    if (pageY >= 1100) {
      setTrigger(true);
    }
  });
  return (
    <div className="Eoverflowcontainer">
      <div className="con_bx">
        <IconBox trigger={trigger}>
          <img src={EmartIco} alt="Emart_icon" />
        </IconBox>
        <EoverTextBox trigger={trigger}>
          <div className="Eover_title">OVERVIEW</div>
          <div className="Eoveer_subtext">
            이마트는 고객의 행복한 라이프스타일을 만들고 지역 사회와 함께
            발전하는 신세계 그룹의 대형 유통 기업입니다.이마트앱은 ‘오프라인
            이마트를 <br />
            위한 마케팅 서비스’입니다.우리는 이마트앱을 ‘생활의 일부인 서비스,
            습관이 되는 서비스’로 성장 시키고자 하는 비지니스 니즈와 ‘3개월
            구축’ <br />
            이라는 물리적인 조건 아래에서, 기존 화면들의 개발 수정을
            최소화하면서 최대의 개선 효과를 볼 수 있는 솔루션을 고민했습니다.
          </div>
          <div className="Eover_Pro">PROCESS</div>
        </EoverTextBox>
        <Process trigger={trigger}>
          {list.map((item, index) => {
            return (
              <div className="Pro_ListBox" key={index}>
                <div className="titlebox">
                  <div className="yellowbox"></div>
                  <div className="title">{item.title}</div>
                </div>
                <div className="textbox">
                  <div className="text">{item.text01}</div>
                  <div className="text">{item.text02}</div>
                  <div className="text">{item.text03}</div>
                  <div className="text">{item.text04}</div>
                </div>
              </div>
            );
          })}
        </Process>
      </div>
    </div>
  );
};

export default Eoverflow;
