import React from 'react'
import img1 from "../../../../_asset/images/topzone/05-img-1@3x.png"
import arrowNext from "../../../../_asset/images/topzone/arrow-r@3x.png"
import arrowPrev from "../../../../_asset/images/topzone/arrow-l@3x.png"
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles.scss"

const setting = {
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
};

function Section6() {
  return (
      <div className='section6'>
          <div className='section6-container'>
              <div className='title'>세미나 사진</div>
              <div className='slides' >
                  <Slider {...setting}>
                      <SlideItem img={img1} />
                      <SlideItem img={img1} />
                      <SlideItem img={img1} />
                  </Slider>
              </div>
          </div>

    </div>
  )
}
function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={'next'}
            onClick={onClick}
        >
            <img src={arrowNext} alt='' />
        </div>
    );
}
function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={'prev'}
            onClick={onClick}
        > <img src={arrowPrev} alt='' /></div>
    );
}

const SlideItem = ({ img }) => {
    return (<div className='slide'>
        <img src={img} alt='' />
    </div>)
}
export default Section6