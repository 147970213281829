import React from "react";
import data from "./data";
import "./styles.scss";

function Section7() {
  return (
    <div className="section7">
      <div className="section7-container">
        <div className="title">PARTNERSHIP</div>
        <div className="detail">
          (주)탑존과 함께하는 협력사를 소개합니다. <br />
          모두가 상생하는 미래를 위해 함께 노력하겠습니다.
        </div>

        <div className="list">
          <div className="list-details">
            {data.map((item, index) => (
              <div className="list-item" key={index}>
                <div className={`list-inner image-${index + 1}`}>
                  <img src={item.img} alt="" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section7;
