import React from "react";
import "./style/Pdesign.scss";
import pdesignimg from "../../../../_asset/images/Prorenata/img_prn25.png";

const Pdesign = () => {
  return (
    <div className="Pdesign_container">
      <div className="Pdesign_cont">
        <div className="Pdesign_txt_bx">
          <div className="Pdesign_tit">Brand Experience Design</div>
          <div className="Pdesign_txt">
            플러스엑스는 '처방전'이라는 브랜드 컨섭하에<br></br>
            로고타입과 모노그램, 컬러를 비롯하여 모티브,<br></br>
            서체, 아이콘 등 프로레나타 브랜드 전반의<br></br>
            디자인 톤앤매너를 정의내렸습니다.<br></br>
            이를 바탕으로 브랜드의 시작적인 측면 뿐만<br></br>
            아니라, 고객이 브랜드를 경험하는 곳곳에서<br></br>
            '처방전'이라는 브랜드 컨셉을 느낄 수 있도록<br></br>
            디자인하였습니다.
          </div>
        </div>
        <div className="Pdesign_image_Wrap">
          <img src={pdesignimg} alt="Pdesign_image" />
        </div>
      </div>
    </div>
  );
};

export default Pdesign;
