import React from 'react'
import img from "../../../../_asset/images/topzone/03-img@3x.png"
import "./styles.scss"
function Section4() {
  return (
      <div className='section4'>
          <div className='section4-container'>
              <div className='left'>
                  <div className='title'>수익 시스템</div>
                  <div className='item'>
                      <div className='item-title'>SEED LEVEL</div>
                      <div className='item-detail'>
                          월 300만원 구독기준 5%의 수익률을 고정으로 <br />
                          지급합니다. 마케팅 특성상 계약과 동시에 <br />
                          매달 1일 선지급되는 방식으로 빠르게 수익을 <br />
                          창출하실 수 있습니다.
                      </div>
                  </div>

                  <div className='item'>
                      <div className='item-title'>TREE LEVEL</div>
                      <div className='item-subtitle'>월 1회 교육 / 계약 5건 유지</div>
                      <div className='item-detail'>
                          월 300만원 구독기준 10%의 수익률을 고정으로 <br />
                          지급합니다.수익창출에 필요한 지속적인 교육과 <br />
                          Tree단계의 네트워크 모임에 초대합니다. <br />
                          년 1회 해외연수 대상자에 자동 등록됩니다. <br />
                      </div>
                  </div>

              </div>
              <div className='right'>
                  <img src={img} alt='' />
              </div>
          </div>

    </div>
  )
}

export default Section4