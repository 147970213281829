import React from "react";
import "./style/Pcover.scss";
import covertext01 from "../../../../_asset/images/Prorenata/txt_cover1.png";
import covertext02 from "../../../../_asset/images/Prorenata/txt_cover2.png";

const Pcover = () => {
  return (
    <div className="Pcover_container">
      <div className="cont">
        <div className="text1">
          <img src={covertext01} alt="cover_text" />
        </div>
        <div className="text2">
          <img src={covertext02} alt="cover_text" />
        </div>
      </div>
    </div>
  );
};

export default Pcover;
