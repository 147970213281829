import React, { Component } from 'react';
import './CloseBtn.scss';
import close from '../../_asset/images/common/close-icon.png';

class CloseBtn extends Component {

    render() {

        return (
            <div className='CloseBtnContainer'>
            <button className="CloseBtnBox"><em className="blind"></em>
                <div className='CloseBtnText'>
                    close
                </div>
                <img src={close} alt=''></img>
            </button>
            </div>
        );
    }
}

export default CloseBtn;

//Menu 팝업이 닫혀한다.