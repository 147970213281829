import React, { Component } from 'react';
import { Scroll } from 'react-scroll-top-btn'
import './FooterMain.scss';
import Top from '../../_asset/images/common/goup-icon.svg'
import FooterLogoImg from '../../../src/_asset/images/mainback/logo-white-icon.svg'
import footerbutton from '../../../src/_asset/images/mainback/footerbutton.png'
import FooterMobile from '../FooterMainWhite/FooterMainWhite';


class FooterMain extends Component {

    state = {
        display: '0',
        isMobileScreen: window.innerWidth < 768,
    }
    

    componentDidMount() {

        // 스크롤에 따른 Scroll To Top Btn 표시 or 미표시
        if (typeof window !== "undefined") {
            window.onresize = () => {
                if (!this.state.isMobileScreen && window.innerWidth < 768) {
                    this.setState({ isMobileScreen: true });
                }
                if (this.state.isMobileScreen && window.innerWidth >= 768) {
                    this.setState({ isMobileScreen: false })
                }
            }
            window.onscroll = () => {
                let currentScrollPos = window.pageYOffset;
                // console.log(maxScroll)
                if (currentScrollPos > 300) {
                    this.setState({ display: "block" })
                } else {
                    this.setState({ display: "none" })
                }
            }
        }

    }




    scrollToTop = () => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' })
    }

    handleClickBoard=()=>{
        navigator.clipboard.writeText("uix.hello@gmail.com");
        alert('이메일이 복사되었습니다.')
    }

    render() {

        // const email = useRef();

        // const copy = () => {
        //     const el = email.current
        //     el.select()
        //     document.execCommand("copy")
        //     return(
        //         {
                    
        //         }
        //     )
        //   }

        return (
            this.state.isMobileScreen ?
                <FooterMobile isFooterBlack={true} />
                : <div className='FooterMainContainer'>
                    <div className='FooterMain'>
                        <div className='FooterLeftBox'>
                            <img className="FooterLogoImg" src={FooterLogoImg} alt='FooterLogoImg' />
                            <div className='copyright'>
                                <div className='AddressBox'>
                                    4F-411, 125, Wangsimni-ro,<br /> Seongdong-gu, Seoul, Republic of Korea
                            </div>

                                <div className='ASapplicationBox'>
                                    <span>A/S APPLICATION</span>
                                </div>
                            </div>

                            <div className='Informationbox'>
                            uix.hello@gmail.com<br />
                            070-4272-8645
                        </div>
                        </div>
                        {/* <button className='footerbutton' style={{ display: `${this.state.display}`}} onClick={() => this.scrollToTop()}>
                        <img src={footerbutton} alt='위로 이동' />
                    </button> */}
                        <button className="footerbutton">
                            <div onClick={this.handleClickBoard}>REQUEST PROJECT</div>
                        </button>
                    </div>
                </div>

        );
    }
}

export default FooterMain;