import React from 'react'
import './ToastCanvas.scss'

import imgbanner21 from '../../_asset/images/toast/02-m-img-05.png'
import img1 from '../../_asset/images/toast/01-bg.jpg'
import img2 from '../../_asset/images/toast/02-graphic.jpg'
import img3 from '../../_asset/images/toast/03-bg.jpg'
import img4 from '../../_asset/images/toast/04-bg.png'
import img5 from '../../_asset/images/toast/05-a.png'
import img6 from '../../_asset/images/toast/05-b.png'
import img7 from '../../_asset/images/toast/a-checkbox.png'
import img8 from '../../_asset/images/toast/arrow-l.png'
import img9 from '../../_asset/images/toast/arrow-r-active.png'
import img10 from '../../_asset/images/toast/b-checkbox.png'
import img11 from '../../_asset/images/toast/btn-scrollon.png'
import img12 from '../../_asset/images/toast/ctv-graphic-01.png'
import img13 from '../../_asset/images/toast/ctv-graphic-02.png'
import img14 from '../../_asset/images/toast/ctv-logo.png'
import img15 from '../../_asset/images/toast/ctv-number.png'
import img16 from '../../_asset/images/toast/f-logo.png'
import img17 from '../../_asset/images/toast/hhb-graphic-01.png'
import img18 from '../../_asset/images/toast/hhb-graphic-02.png'
import img19 from '../../_asset/images/toast/hhb-logo.jpg'
import img20 from '../../_asset/images/toast/hhb-number.png'
import img21 from '../../_asset/images/toast/inst-icon.png'
import img22 from '../../_asset/images/toast/language-button.jpg'
import img23 from '../../_asset/images/toast/language-icon.jpg'
import img24 from '../../_asset/images/toast/logo.png'
import img25 from '../../_asset/images/toast/open-icon.png'
import img26 from '../../_asset/images/toast/youtube-icon.png'
import BackToTop from '../../Components/BackToTop/BackToTop.js'
function ToastCanvas() {
  return (
    <div className="tc">
      <div className="header">
        <img className="header__logoCenter" alt="" src={img24} />
        <div className="header__btn">
          <div className="header__language">
            {/* <img src={img23} alt="" /> */}
            Language
            <img src={img25} alt="" />
          </div>
          <div className="header__login">가이드 다운로드</div>
        </div>
      </div>
      <div className="banner1">
        <img src={img1} alt="" className="banner1__bg" />
        <div className='banner1__arrow'>

        <img src={img8} alt=''></img>
        <img src={img9} alt=''></img>
        </div>
        <div className="banner1__text">
          <p className="banner1__title">
            토스트캔버스의 <span style={{ color: '#fe5a60' }}>필요성</span>
          </p>
          <p className="banner1__text1" style={{ marginBottom: 25 }}>
            인★그램을 운영하는 우리나라 100대 기업 중 90%가
          </p>
          <p className="banner1__text1">
            모두 같은 템플릿을 사용하고 있다는 사실
          </p>
        </div>
      </div>

      <div className="info">
        <p>
          지금 우리 기업의 브랜드 가치는 잘 지켜지고 있을까요? 우리 기업의
          브랜드 가치를 가장 쉽고 빠르게 지키는 방법을 제안 합니다.
        </p>
      </div>

      <div className="banner2">
        <p className="banner2__title">
          마케팅 <span style={{ color: '#fe5a60' }}>대행</span>
        </p>

        <div className="banner2__body">
          <img src={imgbanner21} alt="" />
          <img src={img2} alt="" />
        </div>
      </div>

      <div className="banner3">
        <img src={img3} alt="banner3__bg" />
        <div className="banner3__content">
          <p className="banner3__title">자사 브랜딩 사례</p>
          <img src={img15} alt="" className="banner3__number" />
          <img src={img14} alt="" className="banner3__number2" />
          <p className="banner3__sub">Creative_Official</p>
          <img src={img12} alt="" className="img_body" />
          <p className="banner3__title">자사 플랫폼 적용 사례</p>
          <p className="banner3__sub">Creative_Official</p>
          <p className="banner3__sub1">
            토스트캔버스 플랫폼에 템플릿을 업로드하여 누구나 쉽게 브랜드 색과
            디자인을 유지한 상태로
          </p>
          <p className="banner3__sub2">
            디자인 수정이나 기획 수정을 할 수 있도록 서비스 제공.
          </p>
          <img src={img13} alt="" className="img_body" />
        </div>
      </div>

      <div className="banner4">
        <p className="banner4__title">자사 브랜딩 사례</p>
        <img src={img20} alt="" className="banner4__number" />
        <img src={img19} alt="" className="banner4__number2" />
        <p className="banner4__sub">hi hello blue</p>
        <img src={img17} alt="" className="img_body" />
        <p className="banner4__title">자사 플랫폼 적용 사례</p>
        <p className="banner4__sub">hi hello blue</p>
        <p className="banner4__sub1">
          토스트캔버스 플랫폼에 템플릿을 업로드하여 누구나 쉽게 브랜드 색과
          디자인을 유지한 상태로
        </p>
        <p className="banner4__sub2">
          디자인 수정이나 기획 수정을 할 수 있도록 서비스 제공.
        </p>
        <img src={img18} alt="" className="img_body" />
      </div>
      <div className="banner5">
        <img src={img4} alt="" className="banner5__bg img_body" />
        <div className="banner5__content">
          <p className="banner5__title1">기업 브랜드의</p>
          <p className="banner5__title2">
            <span style={{ color: '#fe5a60' }}>퍼스널컬러</span>를 다시 찾아
            드립니다
          </p>

          <p className="banner5__desc">
            기업의 브랜드가 지켜지는{' '}
            <span style={{ color: '#fe5a60' }}>디자인</span>, 쉽게 시작하고
            빠르게 완성할 수 있는 소통을 돕는 기술
          </p>
          <p className="banner5__desc">
            브랜드 이념과 가치를{' '}
            <span style={{ color: '#0165bd' }}>가장 우리 기업 답게</span> 만드는
            방법.
          </p>
          <p className="banner5__desc">
            <span style={{ color: '#0daa58' }}>SNS 운영 유지보수 서비스</span>를
            제공하는 토스트캔버스 입니다.
          </p>
          <div className="banner5__btn">문의하기</div>
        </div>
      </div>

      <div className="banner6">
        <p className="banner6__title">
          맞춤 서비스 <span style={{ color: '#fe5a60' }}>제공</span>
        </p>
        <div className="banner6__content">
          <img src={img5} alt="" />
          <img src={img6} alt="" />
        </div>
      </div>

      <div className="footer">
        <div className="header">
          <img className="footer__logo" src={img16} alt="" />
          <div className="footer__address">
            <p>
              4F-411, 125, Wangsimni-ro, Seongdong-gu, Seoul, Republic of Korea
              uix.hello@gmail.com
            </p>
            <p>Copyrightⓒ UIX All rights reserved.</p>
          </div>
          <div className="footer__link">
            <div className="footer__icon">
              <img src={img21} alt="" />
              <p>Instagram</p>
            </div>
            <div className="footer__icon">
              <img src={img26} alt="" />
              <p>Youtube</p>
            </div>
          </div>
        </div>
      </div>
      <BackToTop />
    </div>
  )
}

export default ToastCanvas
