import React from "react";
import "./style/session8.scss";
import img from "../../../../_asset/images/severkit/08-img.png";

export default function Session8() {
    return (
        <div className="serverkit_session8">
            <div className="sk_main">
                <div className="sk_left">
                    <div className="sk_title">
                        당신이 꿈꾸던 개발,<br></br>
                        사업 미루지 마세요.
                    </div>
                    <div className="sk_des">
                        it스타트업, 플랫폼 사업을 계획 중이신가요?<br></br>
                        서버키트는 1억이 넘는 대형 개발들을 약 2천만원 초기
                        <br></br> 비용으로 개발해 드리고 나머지 개발비는
                        서버키트 사용료+<br></br>개발운영 유지보수 비용으로 월
                        300만원 정도로 별도의 개발<br></br> 구인이나 개발팀
                        셋팅을 하지 않고도 사업을 서비스할수있게<br></br>{" "}
                        서포트하는 개발 운영 솔루션입니다
                    </div>
                    <div className="sk_button">
                        <div className="sk_item sk_black">문의하기</div>
                        <div className="sk_item sk_white">소식 받아보기</div>
                    </div>
                </div>

                <div className="sk_image">
                    <img src={img} alt="" />
                </div>
            </div>
        </div>
    );
}
