import React from "react";
import "./style/Ksection01.scss";
import ReactPlayer from "react-player";

const Ksection01 = () => {
  return (
    <div className="Ksection01_container">
      <div className="Ksection_cont">
        <div className="mv_bx">
          <ReactPlayer url="https://i.vimeocdn.com/video/694120231.webp?mw=2000&mh=1125" />
        </div>
      </div>
    </div>
  );
};

export default Ksection01;
