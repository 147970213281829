import React from "react";
import "./style/Pinstagram.scss";

const Pinstagram = () => {
  return (
    <div className="Pinstagram_container">
      <div className="Pinstagram_cont_1">
        <div className="instargrom_bx_1"></div>
      </div>
      <div className="Pinstagram_cont_2">
        <div className="instargrom_bx_2"></div>
      </div>
      <div className="Pinstagram_cont_3">
        <div className="instargrom_bx_3"></div>
      </div>
      <div className="Pinstagram_tit">
        <div className="Left_bx">
          프로레나타의 웹사이트는 고객이 자신의 피부 증상과 선호하는 제품라인,
          <br></br>
          제형 등을 선택하면 그에 해당하는 제품을 나열해주는 방식을 구축하여
          <br></br>
          프로레나타의 처방전 컨셉을 온라인 환경에서도 경험할 수 있도록 합니다.
        </div>
        <div className="Right_bx">
          Website <br></br>
          (Main Page / Product Page / Detail Page)
        </div>
      </div>
    </div>
  );
};

export default Pinstagram;
