import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "./style/overview.scss";
import phone from "../../../../_asset/images/29TV/phone.mp4";

// styled 선언 안에서 화살표 함수 (props) => ~~ 는
// props 안에 아래에서 넘겨준 trigger 가 들어가게 됨
// 저번에 배운 3항 연산자로 trigger가 true면 css 값을 바꿔줌
// 여기서 transition 은 transition: (애니메이션 주고싶은 속성 이름, 진행할 시간, 애니메이션 효과 스타일); 로 주면 됨
// 원하는 애니메이션 속성 (아래에서는 opacity, transform 을 trigger 조건으로 먼저 만들고 transtion 으로 애니메이션을 적용한다는 것)
const TopOne = styled.div`
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 1px;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 1s linear;
`;

const Text = styled.div`
  font-size: 50px;
  font-weight: 600;
  line-height: 1.5;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 2s linear;
  transition: transform 2s linear;
`;

const Desc = styled.div`
  margin-top: 40px;
  font-size: 16px;
  line-height: 36px;
  margin-bottom: 443px;
  text-align: center;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 2.3s linear;
  transition: transform 2.3s linear;
`;

const Overview = ({ pageY }) => {
  // Tv.js 에서 받은 pageY
  const [trigger, setTrigger] = useState(false);

  // useEffect 는 2번째 인수 배열 안의 값들이 변할 때마다 함수를 실행함
  // useEffect(함수, [인수 배열])

  useEffect(() => {
    // 스크롤 y 위치가 1850보다 크면 trigger on
    if (pageY >= 1000) {
      setTrigger(true);
    }
  }, [pageY]);

  // styled-components 로 만든 레이아웃에 prop으로 trigger를 넘겨줌

  return (
    <div className="overviewcontainer">
      <div className="overview_top">
        <TopOne trigger={trigger}>Project</TopOne>
        <TopOne trigger={trigger}>Overview</TopOne>
      </div>
      <div className="mv-wrap">
        <video id="overflow_video" loop autoPlay>
          <source src={phone} type="video/mp4" />
        </video>
      </div>
      <Text trigger={trigger}>
        "We at Plus X aim to provide customers
        <br /> with a new experience while maintaining the
        <br /> value pursued by 29CM"
      </Text>
      <Desc trigger={trigger}>
        29CM는 카테고리를 막론하고 더 나은 라이프스타일을 위한 상품을 추천 및
        큐레이팅해주는 커머스 플랫폼입니다.
        <br />
        트렌드를 리드하는 Z세대의 등장으로 시장은 급변하고 있으며 치열한 경쟁은
        이미 시작되었습니다.
        <br />
        변화가 필요한 현시점에, 29CM 2.0은 어떤 모습으로 고객과 커뮤니케이션해야
        할지 고민하였습니다.
      </Desc>
    </div>
  );
};

export default Overview;
