import React from 'react'
import img1 from "../../../../_asset/images/topzone/04-img-1@3x.png"
import img2 from "../../../../_asset/images/topzone/04-img-2@3x.png"
import img3 from "../../../../_asset/images/topzone/logo-icon-4@3x.png"
import img4 from "../../../../_asset/images/topzone/logo-icon-4.png"
import "./styles.scss"
function Section5() {
  return (
      <div className='section5'>
          <div className='section5-container'>
              <div className='title'>지원하기</div>
              <div className='detail'>고수익 영업자를 원하는 분들을 모집합니다!</div>

              <div className='list'>
                  <ListItem img={img1} content={'브랜드 콘텐츠 분야'}  />
                  <ListItem img={img2} content={'개발 운영 분야'}  />
                  <ListItem img={img3} content={'영상 콘텐츠 분야야'}  />
                  <ListItem img={img4} content={'월 300 유지보수계약 \n 10% 수당 지급'}  />
              </div>
              <div className='button'>
                  <div className='button-component'>
                      지원하기
                  </div>
              </div>
          </div>

    </div>
  )
}

const ListItem = ({ img, content }) => {
    return (<div className='list-item'>
        <img src={img} alt='' />
        <div className='item-content'>{ content}</div>
    </div>)
}
export default Section5