import React, { useState } from 'react'
import languageImg from '../../../../_asset/images/topzone/language-icon@3x.png'
import logo from '../../../../_asset/images/topzone/topzone-icon@3x.png'
import arrowDown from '../../../../_asset/images/topzone/open-icon@3x.png'
import { RiMenu2Fill } from "react-icons/ri";
import { IoMdClose } from "react-icons/io";
import "./styles.scss"
function Header() {
    const [open,setOpen] = useState(false)
    return (
      <>
      <div className="topzone__header">
                <div className='topzone__header__logo'>
                    <img className="topzone__header__logoCenter" alt="" src={logo} />
          </div>
          
                <div className="topzone__header__btn">
              <div className="topzone__header__language">
                  {/* <img src={languageImg} alt="" /> */}
                  Language
                  <img src={arrowDown} alt="" />
              </div>
              <div className="topzone__header__login">가이드 다운로드</div>
                </div>
                <div onClick={() => {setOpen(!open)}} className='topzone__header__mobile__button'>
                    <RiMenu2Fill size={24}  />
                </div>
            </div>
            <div className={`aside ${open ? 'aside__open' : ''}`}>
                <div onClick={() => { setOpen(!open) }} className='close__button'>
                    <IoMdClose color='#fff' size={24} />
                </div>
                <div className="topzone__aside__btn">
                    <div className="topzone__header__language">
                        <img src={languageImg} alt="" />
                        Language
                        <img src={arrowDown} alt="" />
                    </div>
                    <div className="topzone__header__login">가이드 다운로드</div>
                </div>
            </div>
            </>
  )
}

export default Header