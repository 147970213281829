import React, { useState, useEffect } from "react";
import "./style/Gconstruction.scss";
import styled from "styled-components";

import img_bg from "../../../../_asset/images/g9/img_section03_9.png";
import contit from "../../../../_asset/images/g9/img_txt_section03_5.png";
import device from "../../../../_asset/images/g9/img_cover02.png";
import section from "../../../../_asset/images/g9/img_txt_section03_6.png";
import titsection from "../../../../_asset/images/g9/img_txt_section03_1.png";
import devicePad from "../../../../_asset/images/g9/img_pc_home_device.png";
import lpdevice01 from "../../../../_asset/images/g9/img_mobile_lp_device.png";
import lpdevice02 from "../../../../_asset/images/g9/img_mobile_srp_device.png";

import finding01 from "../../../../_asset/images/g9/img_section03_finding01.png";
import finding02 from "../../../../_asset/images/g9/img_section03_finding02.png";
import finding03 from "../../../../_asset/images/g9/img_section03_finding03.png";
import finding04 from "../../../../_asset/images/g9/img_section03_finding04.png";

import pclistvd from "../../../../_asset/images/g9/06_overseas_pc.mp4";
import overseas from "../../../../_asset/images/g9/img_overseas_mobile.png";

import overlist01 from "../../../../_asset/images/g9/img_mobile_overseas_01.png";
import overlist02 from "../../../../_asset/images/g9/img_mobile_overseas_02.png";
import overlistvd from "../../../../_asset/images/g9/05_overseas_mobile.mp4";
import overlist03 from "../../../../_asset/images/g9/img_mobile_overseas_03.png";

import overseasvd from "../../../../_asset/images/g9/06_overseas_pc.mp4";

import brandadevice from "../../../../_asset/images/g9/img_mobile_brand_a_device.png";
import brandbg from "../../../../_asset/images/g9/img_mobile_brand_a_bg.png";
import brandadevice02 from "../../../../_asset/images/g9/img_mobile_brand_b_device.png";
import brandbg02 from "../../../../_asset/images/g9/img_mobile_brand_b_bg.png";
const Title = styled.div`
  position: absolute;
  top: -106px;
  width: 615px;
  height: 291px;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 1s linear;
  > img {
    width: 100%;
  }
`;

const SubTitle = styled.div`
  color: #555;
  font-size: 14px;
  line-height: 28px;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1.2s linear;
  transition: transform 1.2s linear;
`;

const ImgDevice = styled.div`
  overflow: hidden;
  position: absolute;
  top: 468px;
  left: 0;
  width: 314px;
  box-shadow: 60px 60px 80px rgba(0, 0, 0, 0.3);
  border-radius: 40px;
  z-index: 20;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1.4s linear;
  transition: transform 1.4s linear;
  > img {
    width: 100%;
  }
`;

const DescTitle = styled.div`
  font-size: 25px;
  font-weight: 900;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1.6s linear;
  transition: transform 1.6s linear;
`;
const DescText = styled.div`
  margin-top: 36px;
  color: #555;
  font-size: 14px;
  line-height: 28px;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1.6s linear;
  transition: transform 1.6s linear;
`;

const DeviceBox = styled.div`
  position: relative;
  width: 1200px;
  height: 688px;
  margin: 100px auto 450px;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 2s linear;
  transition: transform 2s linear;
  > img {
    width: 100%;
  }
`;

const Lptext = styled.div`
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 2.2s linear;
  transition: transform 2.2s linear;
  @media screen and (max-width: 800px) {
    text-align: center;
  }
`;

const Lpimage = styled.div`
  font-size: 0;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 2.2s linear;
  transition: transform 2.2s linear;
  display: flex;
  align-items: center;
  gap: 20px;
`;

const Lpdesctit = styled.div`
  font-size: 30px;
  font-weight: 900;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 2.5s linear;
  transition: transform 2.5s linear;
`;
const Lpdesctxt = styled.div`
  margin-top: 36px;
  color: #555;
  font-size: 14px;
  line-height: 28px;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 2.5s linear;
  transition: transform 2.5s linear;
`;
const FindingList = styled.div`
  margin-top: 60px;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 2.7s linear;
  transition: transform 2.7s linear;
`;

const PcList = styled.div`
  margin-top: 180px;
  > video {
    width: 100%;
    height: 100%;
    opacity: ${(props) => (props.trigger ? "1" : "0")};
    transform: ${(props) =>
      props.trigger
        ? "translate3d(0px, 0px, 0px)"
        : "translate3d(0px, 50px, 0px)"};
    transition: opacity 2.9s linear;
    transition: transform 2.9s linear;
  }
`;

const ImageBox = styled.div`
  margin: -185px 0 0 22%;
  width: 1901px;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 3s linear;
  transition: transform 3s linear;
  > img {
    width: 100%;
    height: auto;
    vertical-align: top;
  }
`;

const DescBox = styled.div`
  position: absolute;
  bottom: 308px;
  left: 50%;
  margin-left: 40px;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 3.2s linear;
  transition: transform 3.2s linear;
`;

const ListMobileInner = styled.div`
  margin-top: 41px;
  padding-left: 2px;
  position: relative;
  width: 1200px;
  margin: 0 auto;
  display: flex;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 3.5s linear;
  transition: transform 3.5s linear;
`;

const PcWrap = styled.div`
  position: relative;
  width: 1200px;
  height: 688px;
  margin: 150px auto 450px;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 3.7s linear;
  transition: transform 3.7s linear;
`;

const TxTBox = styled.div`
  position: relative;
  padding: 5px 0 0 0;
  text-align: right;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 3.5s linear;
  transition: transform 3.5s linear;
`;

const ConBrandOne = styled.div`
  display: inline-block;
  position: relative;
  width: 314px;
  vertical-align: top;
`;

const ConBrandTwo = styled.div`
  display: inline-block;
  position: relative;
  width: 314px;
  vertical-align: top;
  margin-left: 50px;
`;

const Gconstruction = ({ pageY }) => {
  const [trigger, setTrigger] = useState(false);
  useEffect(() => {
    if (pageY >= 8000) {
      setTrigger(true);
    }
  });
  return (
    <div className="Gconstructioncontainer">
      <div className="visual_box">
        <div className="inner container">
          <div className="desc_box">
            <Title trigger={trigger}>
              <img src={contit} alt="construction_tit" />
            </Title>
            <SubTitle trigger={trigger}>
              처음 우리가 디자인을 제안할때는 G9측이 보다 새로움을 보여 줄수
              있는 쇼핑커머스를 제안 받기를 <br></br>
              원하셔서 보다 과감하고 도전적인 방향의 시안을 제안하였고, 반응도
              상당히 긍정적이였습니다. <br></br>
              허나 실제 구축이 진행되면서 우리는 좀더 현실적이고 효율적인
              방향으로 발전시키게 되었습니다.
            </SubTitle>
          </div>
          <ImgDevice trigger={trigger}>
            <img src={device} alt="device_image" />
          </ImgDevice>
          <div className="Photo_by">
            <img src={section} alt="section_tit" />
          </div>
        </div>
      </div>
      <div className="g9_home">
        <div className="container">
          <div className="tit_box_inner">
            <div className="tit">
              <img src={titsection} alt="tit_section" />
            </div>
          </div>
          <div className="slide_area"></div>
          <div className="desc_box_inner">
            <div className="inner_Textbx">
              <DescTitle trigger={trigger}>G9 HOME</DescTitle>
              <DescText trigger={trigger}>
                새로운 G9의 홈은 다양한 상품과 콘텐츠를 담을 수 있는 공간으로
                이러한 다양한 형태를<br></br>
                담기 위한 모듈화 시스템을 통해 통일성 있는 디자인을 보여줄 수
                있도록 제작되었습니다. <br></br>
                운영자는 원하는 콘텐츠에 맞는 모듈을 선택하고, 사용자는 정돈된
                UI를 경험할수 있습니다.
              </DescText>
            </div>
            <DeviceBox trigger={trigger}>
              <img src={devicePad} alt="pad" />
            </DeviceBox>
          </div>
        </div>
      </div>
      <div className="lp_srp">
        <div className="lp_inner container">
          <div className="lp_inner-top row">
            <div className="lp_inner-top-item col col-6">
              <div className="lp_inner-top-wrapper">
                <Lptext trigger={trigger}>
                  <div className="lp_tit">LP & SRP</div>
                  <div className="lp_txt">EASY PRODUCT FINDING</div>
                </Lptext>
              </div>
            </div>
            <div className="lp_inner-top-item col col-6">
              <div className="lp_inner-top-wrapper">
                <Lpimage trigger={trigger}>
                  <div className="lp_left">
                    <img src={lpdevice01} alt="lp_device" />
                  </div>
                  <div className="lp_right">
                    <img src={lpdevice02} alt="lp_device" />
                  </div>
                </Lpimage>
              </div>
            </div>
          </div>
          <div className="lp_inner-bottom">
            <div className="lp_desc">
              <Lpdesctit trigger={trigger}>
                IMPROVE THE <br></br>
                FINDING EXPERIENCE
              </Lpdesctit>
              <Lpdesctxt trigger={trigger}>
                G9는 각 화면에 최적화된 상품 파인딩 네비게이션을 제공합니다.
                이를 통해 우리는 <br></br>
                어떤 화면에서나 편리한 파인딩 경험을 제공받을 수 있습니다.
              </Lpdesctxt>
              <FindingList trigger={trigger}>
                <div className="FindingList-content row">
                  <div className="FindingList-item col col-3">
                    <div className="FindingList-wrapper">
                      <div className="list01">
                        <img src={finding01} alt="finding_image01" />
                      </div>
                    </div>
                  </div>
                  <div className="FindingList-item col col-3">
                    <div className="FindingList-wrapper">
                      <div className="list02">
                        <img src={finding02} alt="finding_image02" />
                      </div>
                    </div>
                  </div>
                  <div className="FindingList-item col col-3">
                    <div className="FindingList-wrapper">
                      <div className="list03">
                        <img src={finding03} alt="finding_image03" />
                      </div>
                    </div>
                  </div>
                  <div className="FindingList-item col col-3">
                    <div className="FindingList-wrapper">
                      <div className="list04">
                        <img src={finding04} alt="finding_image04" />
                      </div>
                    </div>
                  </div>
                </div>
              </FindingList>
              <PcList trigger={trigger}>
                <div className="Pc_tit">PC LIST PAGE</div>
                <video id="Pc_list_video" loop autoPlay>
                  <source src={pclistvd}></source>
                </video>
              </PcList>
            </div>
          </div>
        </div>
      </div>
      <div className="overseas">
        <div className="top_con_inner">
          <div className="over_tit_box">
            <div className="over_titbox">
              <div className="over_title">OVERSEAS</div>
              <div className="over_text">PROPOSE & DESIGN</div>
            </div>
          </div>
          <ImageBox trigger={trigger}>
            <img src={overseas} alt="overseas" />
          </ImageBox>
          <DescBox trigger={trigger}>
            <div className="Desc_title">
              A SPECIAL<br></br>
              OVERSEAS ORDER
            </div>
            <div className="Desc_text">
              G9 해외직구는 단지 물건을 찾고 사는 것 뿐만 아니라 보다 새롭고
              다양한 <br></br>
              해외직구 경험을 느낄 수 있도록 하고자 하였습니다.
            </div>
          </DescBox>
          <span className="bg_circle"></span>
        </div>
        <ListMobileInner trigger={trigger}>
          <ul style={{ paddingLeft: "0px" }}>
            <li className="over_list01">
              <img src={overlist01} alt="over_list" />
            </li>
            <li className="over_list02">
              <img src={overlist02} alt="over_list" />
            </li>
            <li className="over_list03">
              <video id="over_video" loop autoPlay>
                <source src={overlistvd} alt="overseas_video" />
              </video>
            </li>
            <li className="over_list04">
              <img src={overlist03} alt="over_list" />
            </li>
          </ul>
        </ListMobileInner>
        <PcWrap trigger={trigger}>
          <div className="img_Wrap">
            <video id="overseas_vd" loop autoPlay>
              <source src={overseasvd} alt="overseas_video02" />
            </video>
          </div>
        </PcWrap>
        <div className="Brand">
          <div className="Brand_inner">
            <TxTBox trigger={trigger}>
              <div className="Brand_txtbox">
                <div className="Brandtxt">BRAND</div>
                <div className="Brandsubtxt">BRAND-FOCUSED COMMERCE</div>
              </div>
            </TxTBox>
            <div className="con_brand">
              <ConBrandOne>
                <div className="brand_image_Wrap">
                  <img src={brandadevice} alt="Brand_image" />
                </div>
                <div className="brand_image_bg">
                  <img src={brandbg} alt="Brand_image_bg" />
                </div>
              </ConBrandOne>
              <ConBrandTwo>
                <div className="brand_image_Wrap02">
                  <img src={brandadevice02} alt="Brand_image" />
                </div>
                <div className="brand_image_bg02">
                  <img src={brandbg02} alt="Brand_image_bg" />
                </div>
              </ConBrandTwo>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gconstruction;
