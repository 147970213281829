import React from 'react'
import imgLeft from "../../../../_asset/images/topzone/02-img@3x.png"
import iconPlus from "../../../../_asset/images/topzone/02@3x.png"
import "./styles.scss"
function Section3() {
  return (
      <div className='section3'>
          <div className='section3-container'>
              <div className='left'>
                  <img src={imgLeft} alt='' />
              </div>
              <div className='right'>
                  <div className='right-title'>영업의 분야</div>

                  <div className='right-content'>
                      
                      <div className='right-content-item light-color'>
                          <div className='text'>브랜드 콘텐츠 분야</div>
                          <div className='icon'>
                              <img src={iconPlus} alt='' />
                          </div>
                      </div>

                      <div className='right-content-item'>
                          <div className='text'>개발 운영 분야</div>
                          <div className='icon'>
                              <img src={iconPlus} alt='' />
                          </div>
                      </div>

                      <div className='right-content-item blood-color'>
                          <div className='text'>영상 콘텐츠 분야</div>
                      </div>
                  </div>
              </div>
          </div>

          <div className='section3-content'>
              <span className='blue-color'>월 300</span>
              <span>구독 지출로</span>
              <span className='green-color'>전담팀원 4명</span>
              <span>이상을 제공합니다.</span>
          </div>
    </div>
  )
}

export default Section3