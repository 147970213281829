import React, { Component } from 'react';
import './Header.scss';
import HeaderLogo from '../HeaderLogo/HeaderLogo';
import CloseBtn from '../HamburgerMenu/CloseBtn';


class HeaderPop extends Component {
    render() {
        return (
            <header className="header">
                <HeaderLogo></HeaderLogo>
               <CloseBtn onClose={this.props.onClose}></CloseBtn>
          </header>
        );
    }
}

export default HeaderPop;