import React, { useState, useEffect } from "react";
import "./style/Edata.scss";
import styled from "styled-components";

const Title = styled.div`
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 1s linear;
`;

const Edata = ({ pageY }) => {
  const [trigger, setTrigger] = useState(false);
  useEffect(() => {
    if (pageY >= 4700) {
      setTrigger(true);
    }
  });
  return (
    <div className="Edatacontainer">
      <div className="inner">
        <Title trigger={trigger}>
          <div className="tx_title">USER DATA ANALYSIS</div>
          <div className="tx_bigtitle">Journey map</div>
          <div className="tx_text">
            장보기와 관련된 사용자의 행동 패턴을 키워드로 묶어 <br />
            Behavior Model로 정리하고, AS-IS 경험을 고객의 만족도에 <br />
            따라 Good/Bad로 평가하였습니다.
          </div>
        </Title>
        <div className="graph">
          <div className="category">
            <div className="good">● GOOD</div>
            <div className="bad">● BAD</div>
          </div>
          <div className="graph_bx">
            <div className="keyword01">
              <div className="bad_image"></div>
            </div>
            <div className="keyword02"></div>
            <div className="keyword03"></div>
            <div className="keyword04"></div>
            <div className="keyword05"></div>
            <div className="keyword06"></div>
            <div className="keyword07"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edata;
