import React, { Component } from "react";
import Tv from "../../Experience/Contents/29TV/Tv";

class TvRouter extends Component {
  render() {
    return (
      <div>
        <Tv></Tv>
      </div>
    );
  }
}

export default TvRouter;
