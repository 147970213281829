import React, { useState, useEffect } from "react";
import "./style/Gimage.scss";
import styled from "styled-components";

import topimage from "../../../../_asset/images/g9/img_pc_list_page_top.png";
import brandvd from "../../../../_asset/images/g9/07_brand_pc.mp4";

import premiumimg from "../../../../_asset/images/g9/img_mobile_premiem_device.png";
import premiumibg from "../../../../_asset/images/g9/img_mobile_premiem_bg.png";

const ImageWrap = styled.div`
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 1s linear;
`;
const TxTBox = styled.div`
  margin: 0 auto;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 1s linear;
`;

const PremiumDevice = styled.div`
  overflow: hidden;
  position: absolute;
  top: 82px;
  left: 50%;
  z-index: 30;
  width: 314px;
  margin-left: -157px;
  border-radius: 40px;
  box-shadow: 60px 60px 80px rgba(0, 0, 0, 0.3);
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 1s linear;
  > img {
    width: 100%;
  }
`;

const ImageBg = styled.div`
  width: 100%;
  height: auto;
  vertical-align: top;
  background-color: white;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1.5s linear;
  transition: transform 1.5s linear;
  > img {
    width: 100%;
  }
`;

const Gimage = ({ pageY }) => {
  const [trigger, setTrigger] = useState(false);
  useEffect(() => {
    if (pageY >= 20000) {
      setTrigger(true);
    }
  });
  return (
    <div className="Gimagecontainer">
      <div className="container">
        <ImageWrap trigger={trigger}>
          <div className="device_top">
            <img src={topimage} alt="topline" />
            <video id="Brand_video" loop autoPlay>
              <source src={brandvd} alt="brand_mp4" />
            </video>
          </div>
        </ImageWrap>
        <div className="premium_style">
          <TxTBox trigger={trigger}>
            <div className="pre_tit">PREMIUM & STYLE</div>
            <div className="pre_txt">MORE VALUABLE</div>
          </TxTBox>
          <div className="con_top">
            <PremiumDevice trigger={trigger}>
              <img src={premiumimg} alt="premium_Image" />
            </PremiumDevice>
            <ImageBg trigger={trigger}>
              <img src={premiumibg} alt="premium_bg" />
            </ImageBg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gimage;
