import React from "react";
import "./style/pictures.scss";

const Ppictures = () => {
  return (
    <div className="Ppictures_container">
      <div className="p_cont_1">
        <div className="Ppictures_bx"></div>
      </div>
      <div className="Ppictures_cont_2"></div>
      <div className="Ppictures_cont_3"></div>
      <div className="Ppictures_tit">
        <div className="Left_bx">
          각 상품 패키지 내에는 약봉투 형태의 상품 설명서를<br></br>
          동봉하여 타 브랜드 대비 차별화된 브랜드 경험을 제공합니다.
        </div>
        <div className="Right_bx">Product Instruction</div>
      </div>
    </div>
  );
};

export default Ppictures;
