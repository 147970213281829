import React from "react";
import "./style/session6.scss";
import img from "../../../../_asset/images/severkit/05-img-all.png";

export default function Session6() {
    return (
        <div className="serverkit_session6">
            <div className="sk_main">
                <div className="sk_title">
                    No.1 백엔드 엔진<br></br>
                    <span>서버키트만의 차별점</span>
                </div>
                <div className="sk_des">
                    프로젝트 중심의 통합 개발 환경을 제공하여 개발 / 테스트 /
                    운영이 가능하며,<br></br>팀 기능을 통하여 모든 구성원이
                    참여할 수 있는 애자일 환경을 제공합니다.
                </div>
                <div className="sk_des_mobile">
                    프로젝트 중심의 통합 개발 환경을 제공하여<br /> 개발 / 테스트 /
                    운영이 가능하며,<br></br>팀 기능을 통하여 모든 구성원이
                    참여할 수 있는 <br /> 애자일 환경을 제공합니다.
                </div>
                <div className="sk_image">
                    <img src={img} alt="" />
                </div>
            </div>
        </div>
    );
}
